import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/Role.scss";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import editicon from "../../assets/images/bx_edit.svg";
import eye_icon from "../../assets/images/eye_icon.svg";
import close from "../../assets/images/close.svg";
import bankIcon from "../../assets/images/banklogo.svg";
import deleteicon from "../../assets/images/delete.svg";
import Modal from "react-bootstrap/Modal";
import { AppContext } from "../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import CommonButton from "../ChatAdmin/button";

const BankList = () => {
  const { sidebar } = useContext(AppContext);
  const { branchId } = useParams()

  const bankData = {
    "Id": 0,
    "BranchId": '',
    "BankBranchName": "",
    "BankName": "",
    "AccountName": "",
    "AccountNumber": "",
    "CustomerId": "",
    //"IBankPassword": "",
    "CorporateId": "",
    "CorporateUserId": "",
    // "ClosureDate": "",
    //"IfscCode": ""
  }

  const [state, setState] = useState(bankData)

  state.BranchId = Number(branchId)

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [priviwe, setpriviwe] = useState(false);
  const [conomation, setConomation] = useState(false);
  const [isValided, setIsValided] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [bankList, setBankList] = useState([])
  const [isApiLoad, setIsApiLoad] = useState(false)

  const getBankList = () => {
    setIsLoading(true)

    simpleGetCallWithErrorResponse(ApiConfig.GET_BANKS_LIST + branchId).then((res) => {

      if (res?.json?.success) {

        setBankList(res?.json?.data)

      }

      setIsLoading(false)

    })

  }


  useEffect(() => {

    getBankList()

  }, [])

  const handleClose = () => {

    setShow(false)
    setState(bankData)
    setIsValided(false)

  }
  const handleClosed = () => setEdit(false);

  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const handleChange = (key, val) => {

    setState(prev => ({
      ...prev,
      [key]: val
    }))

  }

  const handleSubmit = () => {

    let isValid = true


    const keys = Object.keys(state)
    console.log(keys);

    keys.forEach((key) => {


      if (key != "Id" && !state[key]) {


        isValid = false;

        setIsValided(true)

      }

    })


    if (isValid) {

      setIsApiLoad(true)

      const url = state.Id == 0 ? ApiConfig.CREATE_BANK : ApiConfig.UPDATE_BANK


      PostCallWithErrorResponse(url, JSON.stringify(state)).then((res) => {

        if (res?.json?.success) {

          ToastMsg('success', res?.json?.message)
          handleClose()
          getBankList()

        } else {

          ToastMsg('error', res?.json?.message)
        }

        setIsApiLoad(false)

      })

    }

  }

  const getBank = (bankId) => {

    setShow(true)

    simpleGetCallWithErrorResponse(ApiConfig.GET_BANK_DETAILS + bankId).then((res) => {

      if (res?.json?.success) {

        const data = res?.json?.data

        setState({
          "Id": data?.id,
          "BranchId": data?.branchId,
          "BankBranchName": data?.bankBranchName,
          "BankName": data?.bankName,
          "AccountName": data?.accountName,
          "AccountNumber": data?.accountNumber,
          "CustomerId": data?.customerId,
          //"IBankPassword": "",
          "CorporateId": data?.corporateId,
          "CorporateUserId": data?.corporateUserId,
          // "ClosureDate": "",
          //"IfscCode": ""
        })

      }

    })


  }

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div

      >
        <div className="role-content">
          <div className="sub-header-role">
            <h6 className="mt-2 role-text">
              <span className="me-1 bread-cumb-color" onClick={() => navigate("/clientlist")}>Client </span>
              <span className="me-1 back-slash"> /</span>

              <span className="me-1 bread-cumb-color" onClick={() => navigate("/entitieslist")}>Entities</span>
              <span className="me-1 back-slash"> /</span>

              <span className="me-1 bread-cumb-color" onClick={() => navigate("/branch_list")}>Branch</span>
              <span className="me-1 back-slash"> /</span>


              Bank</h6>
            <button className="create-btn-sub-header" onClick={handleShow}>
              Create
            </button>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder">
            <div className="role-table-header reponsivesrc">
              <div className="search-input-wrapper">
                <input type="text" placeholder="Search" />
                <img src={searchicon} alt="" />
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  class="form-select select-drop-icon remove-border"
                  aria-label="Default select example"
                >
                  <option selected>10</option>
                  <option value="1">20</option>
                  <option value="2">10</option>
                  <option value="3">5</option>
                </select>
              </div>
            </div>
            <div className="role-btns-wrapper">
              <button>
                <img src={importicon} alt="" />
              </button>
              <button>
                {" "}
                <img src={exporticon} alt="" />
              </button>
            </div>
          </div>
          <div className="table-wrapper">
            <table>
              <tr>
                <th>Sr. no</th>
                <th>Acc. Name</th>
                <th>Bank Name</th>
                <th>Branch Name</th>
                <th>Acc No.</th>
                <th>CUstomer ID</th>
                <th>Corporate ID</th>
                <th>Corp User ID</th>
                <th>Action</th>
              </tr>
              {
                isLoading ? <Loader /> : bankList.length === 0 ? <NoData /> :
                  bankList.map((bank, index) => {

                    return <tr className="table-row-custom">
                      <td>{index + 1}</td>
                      <td>{bank?.accountName}</td>
                      <td>{bank?.bankName}</td>
                      <td>{bank?.bankBranchName}</td>
                      <td>{bank?.accountNumber}</td>
                      <td>{bank?.customerId}</td>
                      <td>{bank?.corporateId}</td>
                      <td>{bank?.corporateUserId}</td>
                      <td>
                        <img
                          src={editicon}
                          alt=""
                          className="me-2"
                          onClick={() => getBank(bank?.id)}
                        />
                      </td>
                    </tr>

                  })
              }

            </table>
          </div>
        </div>
      </div>

      {/* <<<<<<...................modal create starts here..........................>>>> */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        size="xl"
        className="create-workflow-main modal-lg"
      >
        <Modal.Header>

          <Modal.Title>
            <label htmlFor="" className="create-role-text">{state.Id == 0 ? "Create" : "Update"} Bank Details</label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClose} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-3 ">
              <label htmlFor="" className="text-grey">
                Account Name
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.AccountName}
                onChange={(e) => handleChange('AccountName', e.target.value)}
              />
              <p className="text-danger">{isValided && !state.AccountName.length && 'Please Enter branch Name'}</p>
            </div>
            <div className="col-lg-3 ">
              <label htmlFor="" className="text-grey">
                Bank Name
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.BankName}
                onChange={(e) => handleChange('BankName', e.target.value)} />
              <p className="text-danger">{isValided && !state.BankName.length && 'Please Enter branch Name'}</p>
            </div>
            <div className="col-lg-3 ">
              <label htmlFor="" className="text-grey">
                Branch Name
              </label>
              <input type="text" className="form-control tasKCategory1 "
                value={state.BankBranchName}
                onChange={(e) => handleChange('BankBranchName', e.target.value)} />
              <p className="text-danger">{isValided && !state.BankBranchName.length && 'Please Enter branch Name'}</p>
            </div>
            <div className="col-lg-3 ">
              <label htmlFor="" className="text-grey">
                Account Number
              </label>
              <input type="number" className="form-control tasKCategory1"
                value={state.AccountNumber}
                onChange={(e) => handleChange('AccountNumber', e.target.value)} />
              <p className="text-danger">{isValided && !state.AccountNumber && 'Please Enter branch Name'}</p>
            </div>
            <div className="col-lg-3 mt-4">
              <label htmlFor="" className="text-grey">
                Customer ID
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.CustomerId}
                onChange={(e) => handleChange('CustomerId', e.target.value)} />
              <p className="text-danger">{isValided && !state.CustomerId.length && 'Please Enter branch Name'}</p>
            </div>
            <div className="col-lg-3 mt-4">
              <label htmlFor="" className="text-grey">
                Corporate ID
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.CorporateId}
                onChange={(e) => handleChange('CorporateId', e.target.value)} />
              <p className="text-danger">{isValided && !state.CorporateId.length && 'Please Enter branch Name'}</p>
            </div>
            <div className="col-lg-3 mt-4">
              <label htmlFor="" className="text-grey">
                User ID
              </label>
              <input type="text" className="form-control tasKCategory1"
                value={state.CorporateUserId}
                onChange={(e) => handleChange('CorporateUserId', e.target.value)} />
              <p className="text-danger">{isValided && !state.CorporateUserId.length && 'Please Enter branch Name'}</p>
            </div>
            {/* <div className="col-lg-3 mt-4">
              <label htmlFor="" className="text-grey">
                Branch ID
              </label>
              <input type="number" className="form-control tasKCategory1" 
              value={state.BranchId}
              onChange={(e)=>handleChange('BranchId',Number(e.target.value))}
              />
              <p className="text-danger">{isValided&&!state.BranchId.length&&'Please Enter branch Name'}</p>
            </div> */}
          </div>
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div>
            <button className="modal-cancel-btn" onClick={handleClose}>Cancel</button>

            <CommonButton
              title={state.Id == 0 ? "Save" : "Update"}
              isLoading={isApiLoad}
              handlerFunction={handleSubmit}
            />
          </div>
        </Modal.Footer>
      </Modal>


    </div>
  );
};

export default BankList;
