import React, { useContext } from 'react'
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import { AppContext } from '../../context/AppContext';
import prev from "../../assets/images/drop_icon.svg";
import { useState } from 'react';

function StatisticsReports() {
  const { sidebar } = useContext(AppContext);
  const[nextMonth,setNextMonth]=useState(0)
  console.log(nextMonth)
  const handleNext = () =>{
    setNextMonth(nextMonth + 1)
  }
  const handlePrevious = () =>{
    setNextMonth(nextMonth - 1)
  }
  
  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"}  id="cx-main">
        <div
       
      >
        <div className="role-content">
          <div className="sub-header-role sub-header-role-responsive">
            <h6 className="mt-2 role-text">Statistics and Reports </h6>
            {/* <div>
            <button
              className="create-btn-sub-header"
              style={{ marginRight: "0" }}
            >
              Create
            </button>
          </div> */}
          </div>

          <div className="d-flex justify-content-between role-searchbar-and-btns role-searchbar-and-btns-responsive">
            <div className="role-table-header role-table-header-responsive">
              <div className="search-input-wrapper " >
                <input type="text" placeholder="Search" />
                <img src={searchicon} alt=""  className='search-icon-responsive'/>
              </div>
              {/* <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  class="form-select select-drop-icon remove-border"
                  aria-label="Default select example"
                >
                  <option selected>10</option>
                  <option value="1">20</option>
                  <option value="2">10</option>
                  <option value="3">5</option>
                </select>
              </div> */}
            </div>
            <div className="role-btns-wrapper">
              <button>
                <img src={importicon} alt="" />
              </button>
              <button>
                {" "}
                <img src={exporticon} alt="" />
              </button>
            </div>
          </div>
          {nextMonth === 0 
           ? (<div className="bg-white rounded-3 p-3 mt-4">
            <h6 className='role-text'>
                Month Wise Quotation
            </h6>
          <div className='d-flex justify-content-between months-crads-quotation'>

                <div className="col-lg-3  mt-4 monthly-card text-center">
                    <p>
                        August - 2022
                    </p>
                    <span>52</span>
                </div>
                <div className="col-lg-3  mt-4 monthly-card text-center">
                    <p>
                        September - 2022
                    </p>
                    <span>20</span>
                </div>
                <div className="col-lg-3  mt-4 monthly-card text-center">
                    <p>
                        October - 2022
                    </p>
                    <span>18</span>
                </div>
          </div>
          <div className='prev-next-btns mt-4 d-flex justify-content-between'>
            <div>
                <button className='nxt-prev-btns-month'>
                    <img src={prev} alt="" className='previous-btn' />
                </button>
            </div>
            <div>
                {/* <button className='nxt-prev-btns-month'>
                    <img src={prev} alt="" className='next-btn' onClick={handleNext}/>
                </button> */}
            </div>
          </div>
                

      
            
          </div>):(<></>)}



          {nextMonth === 1 ? 
           ( <div className="quotation-report-card mt-4">
            <h6 className='role-text'>
               Test
            </h6>
          <div className='d-flex justify-content-between months-crads-quotation'>

                <div className="col-lg-3  mt-4 monthly-card text-center">
                    <p>
                        May - 2022
                    </p>
                    <span>52</span>
                </div>
                <div className="col-lg-3  mt-4 monthly-card text-center">
                    <p>
                        June - 2022
                    </p>
                    <span>20</span>
                </div>
                <div className="col-lg-3  mt-4 monthly-card text-center">
                    <p>
                        July - 2022
                    </p>
                    <span>18</span>
                </div>
          </div>
          <div className='prev-next-btns mt-4 d-flex justify-content-between'>
            <div>
                <button className='nxt-prev-btns-month'>
                    <img src={prev} alt="" className='previous-btn' onClick={handlePrevious}/>
                </button>
            </div>
            <div>
                <button className='nxt-prev-btns-month'>
                    <img src={prev} alt="" className='next-btn' />
                </button>
            </div>
          </div>
                

      
            
          </div>):(<></>)}


          
        </div>
       
      </div>
    </div>
  )
}

export default StatisticsReports