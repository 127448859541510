import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import BookIcon from "../../assets/images/ic_excel.svg";
import { Link } from "react-router-dom";
import deleteicon from "../../assets/images/DelectAc.svg";
import edit from "../../assets/images/Editac.svg";
import Pagination from "../../sharedComponent/Pagination";
import Modal from "react-bootstrap/Modal";
import { DeleteCallWithErrorResponseWithTokenQuery, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { useDebounce } from "../../common";
import { notifySuccess } from "../../toaster/notify";

const IVRSetting = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [iverList, setiverList] = useState([])
console.log("iverList====>",iverList);

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);
  const debouncedValue = useDebounce(searchValue, 500);
  const [deletecnfm, setdeletecnfm] = useState("");
  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);
console.log("deletecnfm",deletecnfm);

  const splitToken = (token, chunkSize) => {
    const chunks = [];
    for (let i = 0, len = token.length; i < len; i += chunkSize) {
      chunks.push(token.substring(i, i + chunkSize));
    }
    return chunks.join("<br/>");
  };

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };


  const searchedDataPageCounts =
  iverList &&
  iverList.length &&
  iverList.filter((item) =>
      item.user_name?.toLowerCase().includes(searchValue)
    );


  useEffect(() => {
    IVRUser()
  }, [currentPage,searchValue,usersPerPage])


  const IVRUser = () => {
    simpleGetCallWithErrorResponse(userData?.CRMUrlNode +ApiConfig.GETIVRLISTSHOW + "?page=" + currentPage + "&page_limit=" + usersPerPage +"&search=" + searchValue)
      .then((res) => {
        console.log(res.json.success);
        
        if (res.json.success) {
          setiverList(res.json.data)
    

        } else {
       
   

        }
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const deleteIVER = (deletecnfm) => {
    DeleteCallWithErrorResponseWithTokenQuery(
      userData?.CRMUrlNode +ApiConfig.DELETERIVER + deletecnfm
    )
      .then((res) => {
     console.log("json",res.json.success);
     
        if (res.json.success) {
          IVRUser();
          notifySuccess("Record deleted Successfully");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">IVR Setting</h6>

          <Link
            style={{ color: "var(--blue_53B7E8_LightMode" }}
            to="/CreateIVR"
          >
            <button className="create-btn-sub-header">Add IVR</button>
          </Link>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb mt-4">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text" placeholder="Search here"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setCurrentPage(0);
                }}
              
              />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                value={usersPerPage}
                onChange={(e) => {
                  setUsersPerPage(e.target.value);
                  setCurrentPage(0);
                }}
              >
                <option value={2}>2</option>
                <option value={5}>5</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <div className="booksIcon" style={{ textAlign: "center" }}>
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table className="">
            <thead className="firstTabelRow">
              <tr classname="row-hover modal-footer-table-body-tr">
                <th>USER LIST</th>
                <th>API</th>
                <th>USER NAME</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {
              
              iverList 
              .slice(startIndex, endIndex).map((item) => (
                <tr key={item.id} className="table-row-custom">
                  <td>{item.c_user_name}</td>
                  <td
                    className="token-column"
                    // dangerouslySetInnerHTML={{
                    //   __html: splitToken(item.c_api_key, 40),
                    // }}
                  >{item.c_api_key}</td>
                  <td className="Source">{item.user_name}</td>
                  <td>
                    <div className="d-flex">
                      <div onClick={handleShow4}>
                        <img
                          src={deleteicon}
                          onClick={() => {
                           
                            setdeletecnfm(item.id);
                            //deleteHoliday(data.Id);
                          }}
                          alt="Image 2"
                          className="cell-image "
                        />
                      </div>

                      <Link 
                      to={/editIVR/ + item.id}>
                        <img
                          src={edit}
                       
                          alt="Image 1"
                          className="cell-image ml-2"
                        />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
          // data={iverList}
          data={iverList && iverList.length > 0 && iverList}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
        </div>
      </div>
      <Modal
        className="delete-news-modal"
        show={show4}
        onHide={handleClose4}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete this WhatsApp ?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose4}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              deleteIVER(deletecnfm);
              handleClose4();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};
export default IVRSetting;
