import BookIcon from "../../assets/images/ic_excel.svg";
import Pagination from "../../sharedComponent/Pagination";
import React, { useContext, useEffect, useState } from "react";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Form, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";

const tableaudio = {
  width: "120%",
  height: "50px",
  borderRadius: "5px",
  // backgroundColor: "#f0f0f0", 
  padding: "5px",
};

const tabletd = {
  padding: "8px",
  textAlign: "center",
}

function CRMCalls() {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  const [key, setKey] = useState("all");
  console.log("Key data >>>>>>>>>>", key);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);
  const [callData, setCallData] = useState([])
  const [filterSerchName, setFilterSerchName] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [previousPageUrl, setPreviousPageUrl] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [loading, setLoading] = useState(false)



  const startIndex = (currentPage - 1) * usersPerPage; // Adjust to zero-indexed pages
  const endIndex = startIndex + parseInt(usersPerPage);

  const filteredDataForCurrentPage = filterSerchName?.slice(0, usersPerPage);

  console.log("page count2>>", filteredDataForCurrentPage)


  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
  };


  // const handleSelectChange = (event) => {
  //   setSelectedRows(event.target.value);
  //   console.log(`Selected number of rows: ${event.target.value}`);
  //   // You can add additional logic to handle the row change
  // };
  // show button end



  const crmCallData = [
    {
      mobileNumber: "+912653103942",
      callTime: "9:57 a.m.",
      status: "Not Answered",
    },
    {
      mobileNumber: "+918261986730",
      callTime: "9:34 a.m.",
      status: "Not Answered",
    },
    {
      mobileNumber: "+919527754660",
      callTime: "9:23 a.m.",
      status: "Answered",
    },
    {
      mobileNumber: "+919876543210",
      callTime: "10:15 a.m.",
      status: "Answered",
    },
    {
      mobileNumber: "+918765432109",
      callTime: "10:30 a.m.",
      status: "Not Answered",
    },
    {
      mobileNumber: "+917654321098",
      callTime: "10:45 a.m.",
      status: "Answered",
    },
    {
      mobileNumber: "+918261986730",
      callTime: "9:34 a.m.",
      status: "Not Answered",
    },
    {
      mobileNumber: "+919527754660",
      callTime: "9:23 a.m.",
      status: "Answered",
    },
    {
      mobileNumber: "+919876543210",
      callTime: "10:15 a.m.",
      status: "Answered",
    },
    {
      mobileNumber: "+918765432109",
      callTime: "10:30 a.m.",
      status: "Not Answered",
    },
    {
      mobileNumber: "+917654321098",
      callTime: "10:45 a.m.",
      status: "Answered",
    },
  ];

  const crmInboundCallsData = [
    {
      name: "+919309482204",
      type: "Spam",
      callDate: "05-06-2024",
      callStartTime: "4:40 p.m.",
      callEndTime: "4:40 p.m.",
      agentName: "---",
      callStatus: "Not Answered",
      callRecording: "",
    },
    {
      name: "+919496363014",
      type: "Inquiry",
      callDate: "05-06-2024",
      callStartTime: "4:11 p.m.",
      callEndTime: "4:12 p.m.",
      agentName: "---",
      callStatus: "Not Answered",
      callRecording: "path/to/another-call-recording.mp3",
    },
    {
      name: "+919876543210",
      type: "Customer Support",
      callDate: "04-06-2024",
      callStartTime: "2:15 p.m.",
      callEndTime: "2:20 p.m.",
      agentName: "Agent 1",
      callStatus: "Answered",
      callRecording: "path/to/call-recording1.mp3",
    },
    {
      name: "+918765432109",
      type: "Sales",
      callDate: "04-06-2024",
      callStartTime: "3:00 p.m.",
      callEndTime: "3:05 p.m.",
      agentName: "Agent 2",
      callStatus: "Answered",
      callRecording: "",
    },
    {
      name: "+917654321098",
      type: "Technical Support",
      callDate: "03-06-2024",
      callStartTime: "1:30 p.m.",
      callEndTime: "1:40 p.m.",
      agentName: "Agent 3",
      callStatus: "Answered",
      callRecording: "path/to/call-recording3.mp3",
    },
    {
      name: "+916543210987",
      type: "Inquiry",
      callDate: "03-06-2024",
      callStartTime: "11:00 a.m.",
      callEndTime: "11:10 a.m.",
      agentName: "Agent 4",
      callStatus: "Missed",
      callRecording: "",
    },
    {
      name: "+915432109876",
      type: "Complaint",
      callDate: "02-06-2024",
      callStartTime: "10:00 a.m.",
      callEndTime: "10:05 a.m.",
      agentName: "Agent 5",
      callStatus: "Answered",
      callRecording: "path/to/call-recording5.mp3",
    },
    {
      name: "+914321098765",
      type: "Feedback",
      callDate: "02-06-2024",
      callStartTime: "9:45 a.m.",
      callEndTime: "9:50 a.m.",
      agentName: "Agent 6",
      callStatus: "Answered",
      callRecording: "",
    },
    {
      name: "+913210987654",
      type: "Spam",
      callDate: "01-06-2024",
      callStartTime: "5:00 p.m.",
      callEndTime: "5:00 p.m.",
      agentName: "---",
      callStatus: "Not Answered",
      callRecording: "",
    },
    {
      name: "+912109876543",
      type: "Inquiry",
      callDate: "01-06-2024",
      callStartTime: "4:30 p.m.",
      callEndTime: "4:32 p.m.",
      agentName: "---",
      callStatus: "Not Answered",
      callRecording: "path/to/call-recording10.mp3",
    },
  ];

  const crmOutboundCallsData = [
    {
      name: "+919309482204",
      type: "Spam",
      callDate: "05-06-2024",
      callStartTime: "4:40 p.m.",
      callEndTime: "4:40 p.m.",
      agentName: "---",
      callStatus: "Not Answered",
      callRecording: "",
    },
    {
      name: "+919496363014",
      type: "Inquiry",
      callDate: "05-06-2024",
      callStartTime: "4:11 p.m.",
      callEndTime: "4:12 p.m.",
      agentName: "---",
      callStatus: "Not Answered",
      callRecording: "path/to/another-call-recording.mp3",
    },
    {
      name: "+919876543210",
      type: "Customer Support",
      callDate: "04-06-2024",
      callStartTime: "2:15 p.m.",
      callEndTime: "2:20 p.m.",
      agentName: "Agent 1",
      callStatus: "Answered",
      callRecording: "path/to/call-recording1.mp3",
    },
    {
      name: "+918765432109",
      type: "Sales",
      callDate: "04-06-2024",
      callStartTime: "3:00 p.m.",
      callEndTime: "3:05 p.m.",
      agentName: "Agent 2",
      callStatus: "Answered",
      callRecording: "",
    },
    {
      name: "+917654321098",
      type: "Technical Support",
      callDate: "03-06-2024",
      callStartTime: "1:30 p.m.",
      callEndTime: "1:40 p.m.",
      agentName: "Agent 3",
      callStatus: "Answered",
      callRecording: "path/to/call-recording3.mp3",
    },
  ];

  const getcrmCallData = () => {
    setLoading(true)
    simpleGetCallWithToken(ApiConfig.GET_CALL_LIST + `?page=${currentPage}`)
      .then((res) => {
        console.log("Call List Data>>>", res)
        setCallData(res)
        setNextPageUrl(res?.next); // Set next page URL
        setPreviousPageUrl(res?.previous); // Set previous page URL
        setTotalPages(Math.ceil(res?.count / usersPerPage));
        setLoading(false)

      })
      .catch((err) => {
        setLoading(false)

        console.log("call List Data>>>", err)
      })
  }
  //   simpleGetCallWithToken(ApiConfig.GET_CALL_LIST+`?page=${currentPage}`)
  //     .then((res) => {
  //       console.log("Call List Data>>>", res)
  //       setCallData(res)
  //       setNextPageUrl(res?.next); // Set next page URL
  //       setPreviousPageUrl(res?.previous); // Set previous page URL
  //       setTotalPages(Math.ceil(res?.count / usersPerPage));
  //     })
  //     .catch((err) => {
  //       console.log("call List Data>>>", err)
  //     })

  useEffect(() => {
    getcrmCallData()
    console.log("CallData>>>>", callData)
  }, [currentPage])// }

  // useState(() => {
  //   getcrmCallData()

  //   console.log("current Pages>>>>>>",currentPage)
  // }, [currentPage])



  useEffect(() => {
    // Otherwise, use location_list

    const filteredData = callData?.results?.filter((item) =>
      item?.phone_no?.toLowerCase().includes(searchValue.toLowerCase())
    );

    console.log("Filtered Data>>", filteredData);
    setFilterSerchName(filteredData); 
    // Set the filtered data in the state
    // setTotalPages(Math.ceil(filteredData.length / usersPerPage))

  }, [callData?.results, searchValue,]);
  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Calls</h6>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text"
                placeholder="Search here"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setCurrentPage(currentPage);
                }}
              />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setUsersPerPage(parseInt(e.target.value, 10)); // Update users per page
                  setCurrentPage(currentPage);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25" selected>
                  25
                </option>
              </select>
            </div>
          </div>
          {
            permissions && permissions['View Calls Export'] &&

            <div className="d-flex justify-content-between mainIcons">

              <div className="booksIcon" style={{ textAlign: "center" }}>
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>

            </div>
          }
        </div>
        <Form noValidate id="crm-call-form">
          <div id="tabsBelow" className="mt-4">
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              // className="mb-0"
              activeKey={key}
              onSelect={(k) => {
                setKey(k);
                if (k === "inboundCalls") {
                  setCurrentPage(1);  
                } else if (k === "outboundCalls") {
                  setCurrentPage(1);  
                }
              }}
            >
              <Tab eventKey="all" title="All">
                <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                  <table>
                    <thead>
                      <tr className="firstTabelRow">
                        <th scope="col">Mobile Number</th>
                        <th scope="col">Call Time</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (<>
                        <tr>
                          <td colSpan="9" className="text-center">
                            <Loader />
                          </td>
                        </tr>
                      </>) : (
                        filteredDataForCurrentPage?.length > 0 ? (
                          filteredDataForCurrentPage?.map((data, index) => (
                            <tr key={index} className="table-row-custom">
                              <td>{data?.phone_no}</td>
                              <td>{data?.call_start_time}</td>
                              <td>{data?.call_status}</td>
                            </tr>
                          ))
                        ) : (
                          <tr className="ErrorDataNotFound">
                            <td colSpan={9}>
                              <NoData />
                            </td>
                          </tr>
                        )
                      )
                      }
                    </tbody>
                  </table>
                  <div
                    className="row mt-7"
                    style={{
                      position: "absolute",
                      bottom: "5px",
                      right: "25px",
                    }}
                  >
                    <div className="col-lg-12">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={totalPages} // Use totalPages for correct page count
                        onPageChange={pageChangeHandler}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                        forcePage={currentPage - 1} // Adjusted for zero-indexed pages
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="inboundCalls" title="Inbound Calls">
                <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                  <table>
                    <thead>
                      <tr className="firstTabelRow">
                        <th scope="col">Name / Phone Number</th>
                        <th scope="col">Type</th>
                        <th scope="col">Call Date</th>
                        <th scope="col">Call Start Time</th>
                        <th scope="col">Call End Time</th>
                        <th scope="col">Agent Name</th>
                        <th scope="col">Call Status</th>
                        <th scope="col">Call Recording</th>
                        <th scope="col">MAKE CALL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (<>
                        <tr>
                          <td colSpan="9" className="text-center">
                            <Loader />
                          </td>
                        </tr>
                      </>) : (
                        filteredDataForCurrentPage?.filter(item => item.call_type === "Inbound").length > 0 ? (
                          filteredDataForCurrentPage?.filter(item => item.call_type === "Inbound")?.map((data, index) => (
                            <tr key={index} className="table-row-custom">
                              <td>{data.phone_no}</td>
                              <td>{data.call_type}</td>
                              <td>{data.call_date}</td>
                              <td>{data.call_start_time}</td>
                              <td>{data.call_end_time}</td>
                              <td>{data.agent_name ? data.agent_name : "---"}</td>
                              <td>{data.call_status}</td>
                              <td style={tableaudio}>
                                {data.call_recording_url ? (
                                  <audio controls style={tableaudio}>
                                    <source src={data.call_recording_url} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                  </audio>
                                ) : (
                                  "Not Found"
                                )}
                              </td>
                              <td>
                                <button className="custoBtnForCrmCall">Make Call</button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="ErrorDataNotFound">
                            <td colSpan={9}>
                              <NoData />
                            </td>
                          </tr>
                        )
                      )
                      }
                    </tbody>
                  </table>
                  <div
                    className="row mt-7"
                    style={{
                      position: "absolute",
                      bottom: "5px",
                      right: "25px",
                    }}
                  >
                    <div className="col-lg-12">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={totalPages} // Use totalPages for correct page count
                        onPageChange={pageChangeHandler}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                        forcePage={currentPage - 1} // Adjusted for zero-indexed pages
                      />
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="outboundCalls" title="Outbound Calls">
                <Tab.Content>
                  <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                    <table>
                      <thead>
                        <tr className="firstTabelRow">
                          <th scope="col">Name / Phone Number</th>
                          <th scope="col">Type</th>
                          <th scope="col">Call Date</th>
                          <th scope="col">Call Start Time</th>
                          <th scope="col">Call End Time</th>
                          <th scope="col">Agent Name</th>
                          <th scope="col">Call Status</th>
                          <th scope="col">Call Recording</th>
                          <th scope="col">MAKE CALL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {

                          loading ? (<>
                            <tr>
                              <td colSpan="9" className="text-center">
                                <Loader />
                              </td>
                            </tr>
                          </>) : (filteredDataForCurrentPage?.filter(item => item.call_type === "Outbound").length > 0 ? (
                            filteredDataForCurrentPage?.filter(item => item.call_type === "Outbound")?.map((data, index) => (
                              <tr key={index} className="table-row-custom">
                                <td>{data.phone_no}</td>
                                <td>{data.call_type}</td>
                                <td>{data.call_date}</td>
                                <td>{data.call_start_time}</td>
                                <td>{data.call_end_time}</td>
                                <td>{data.agent_name ? data.agent_name : "---"}</td>
                                <td>{data.call_status}</td>
                                <td style={tableaudio}>
                                  {data.call_recording_url ? (
                                    <audio controls style={tableaudio}>
                                      <source src={data.call_recording_url} type="audio/mpeg" />
                                      Your browser does not support the audio element.
                                    </audio>
                                  ) : (
                                    "Not Found"
                                  )}
                                </td>
                                <td>
                                  <button className="custoBtnForCrmCall">Make Call</button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="ErrorDataNotFound">
                              <td colSpan={9}>
                                <NoData />
                              </td>
                            </tr>
                          )
                          )
                        }
                      </tbody>
                    </table>
                    <div
                      className="row mt-7"
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        right: "25px",
                      }}
                    >
                      <div className="col-lg-12">
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={totalPages} // Use totalPages for correct page count
                          onPageChange={pageChangeHandler}
                          containerClassName={"paginationBttns"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                          forcePage={currentPage - 1} // Adjusted for zero-indexed pages
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Content>
              </Tab>

            </Tabs>
          </div>
        </Form>
      </div>
    </div >
  );
}

export default CRMCalls;
