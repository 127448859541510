import { AppContext } from "../../../context/AppContext";
import React, { useState, useRef, useEffect } from "react";
import { useContext } from "react";
import Loader from "../loader";
import NoDataFound from "../NodataFound";
import { Link, useParams } from "react-router-dom";
import addedit from "../../../assets/images/Editac.svg";
import { Form, Modal } from "react-bootstrap";
import add from "../../../assets/images/check.svg";
import close from "../../../assets/images/close.svg";
import uploadicon from "../../../assets/images/plus_officecaller.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ApiConfig, { BASE_URL_CHATADMIN } from "../../../api/ApiConfig";
import { ToastMsg } from "../../TostMsg";
import { simpleGetCallWithErrorResponse, simplePostCall, multipartPostCallWithErrorResponse, PostCallWithErrorResponse, postMultipartRequestAdmin } from "../../../api/ApiServices";



const Topics = () => {
    const { sidebar, selectedDomainId } = useContext(AppContext);
    const { submodule } = useParams()



    const [isLoading, setIsLoading] = useState(true);
    const [topics, setTopics] = useState([])


    const getTopics = () => {

        setIsLoading(true)

        simpleGetCallWithErrorResponse(ApiConfig.GET_TOPICS + `sub_module=${submodule}&domain_id=${selectedDomainId}`).then((res) => {


            if (res?.json?.success) {

                setTopics(res?.json?.data)

            }

            setIsLoading(false)


        }).catch((err) => {

            console.log(err);

        })

    }

    useEffect(() => {

        getTopics()

    }, [selectedDomainId])



    return (
        <div className={sidebar ? "taskMain" : "cx-active taskMain"} id="cx-main">
            <div>
                <div className="role-content">
                    <div className="sub-header-role d-flex justify-content-between">
                        <h6 className="mt-2">{submodule}  </h6>

                    </div>
                    <div
                        className="table-wrapper table-wrapper-2"
                        style={{ height: "600px" }}
                    >
                        {
                            isLoading ? <Loader /> :

                                topics?.length ?
                                    <table style={{ marginBottom: "20px" }}>
                                        <thead className="">
                                            <tr className="firstTabelRow">
                                                <th>Sr. No</th>
                                                <th>Topic</th>
                                                <th>Action </th>
                                                <th>Route</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                topics?.map((topic, index) => {
                                                    return <tr className="table-row-custom">
                                                        <td>{index + 1}</td>
                                                        <td>{topic?.topic}</td>
                                                        <td>{topic?.pr_action}</td>
                                                        <td><a href={topic.route} target="_blank">{topic.route}</a></td>
                                                    </tr>
                                                })
                                            }

                                        </tbody>

                                    </table> : <NoDataFound />


                        }
                    </div>
                </div>
            </div>

        </div >
    );
};
export default Topics;
