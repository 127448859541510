import React, { useContext } from "react";
import { useState } from "react";
import { AppContext } from "../../context/AppContext";
import close from "../../assets/images/close.svg"

function UpdateQuote() {
  const { sidebar } = useContext(AppContext);
  const [subscription,setSubscription]=useState(false)

  return (
  <div className={sidebar ? "taskMain " : "cx-active taskMain"}  id="cx-main">

    <div
     
    >
      <div className="bg-white p-3 rounded-3">
        <label htmlFor="" className="create-role-text">
          Edit Quotation
        </label>

        <div className="row pb-4">
          <div className="col-lg-6 mt-4">
            <label htmlFor="" className="text-grey">
              Name
            </label>
            <input
              type="text"
              className="form-control tasKCategory1"
              placeholder="Name"
            />
          </div>
          <div className="col-lg-6 mt-4">
            <label htmlFor="" className="text-grey">
              Consultant Name
            </label>
            <select
              class="form-select tasKCategory1"
              aria-label="Default select example"
            >
              <option selected></option>
              <option value="1">Consultant Name-1</option>
              <option value="2">Consultant Name-2</option>
              <option value="2">Consultant Name-3</option>
            </select>
          </div>
          <div className="col-lg-4  mt-4 d-flex align-items-center">
            <label htmlFor="" className="text-grey me-5">
              Is GST Applicable ?
            </label>
            <input type="checkbox" />
          </div>
          <div className="col-lg-4  mt-4 d-flex align-items-center">
            <label htmlFor="" className="text-grey me-5">
              Is TDS Applicable ?
            </label>
            <input type="checkbox" />
          </div>
          <div className="col-lg-4 position-relative mt-4">
            <label htmlFor="" className="text-grey">
              GST Rate
            </label>
            <input type="text" className="form-control tasKCategory1" />
          </div>
        </div>
       
        <div className="row   ">
          <div className="col-lg-4">
            <label htmlFor="" className="text-grey">
              TDS Section
            </label>
            <select
              class="form-select tasKCategory1"
              aria-label="Default select example"
            >
              <option selected></option>
              <option value="1">TDS Section-1</option>
              <option value="2">TDS Section-2</option>
              <option value="2">TDS Section-3</option>
            </select>
          </div>
          <div className="col-lg-4 ">
            <label htmlFor="" className="text-grey">
              TDS Amount
            </label>
            <input
              type="text"
              className="form-control tasKCategory1"
              placeholder="07:00 pm"
            />
          </div>
          <div className="col-lg-4 ">
            <label htmlFor="" className="text-grey">
              TDS Percentage
            </label>
            <input
              type="text"
              className="form-control tasKCategory1"
              placeholder="07:00 pm"
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12 text-end">
            <button className="modal-create-btn" onClick={()=> setSubscription(!subscription)}>
              Add Subscription
            </button>
          </div>
        </div>
<div>
<label htmlFor="" className="create-role-text mt-4">
          Subscription 1
        </label>
        <div className="row  pb-4 mt-3 ">
          <div className="col-lg-4 mb-3">
            <label htmlFor="" className="text-grey">
              Service ID
            </label>
            <select
              class="form-select tasKCategory1"
              aria-label="Default select example"
            >
              <option selected></option>
              <option value="1">Service ID-1</option>
              <option value="2">Service ID-2</option>
              <option value="2">Service ID-3</option>
            </select>
          </div>
          <div className="col-lg-4 mb-3">
            <label htmlFor="" className="text-grey">
              Financial Year
            </label>
            <select
              class="form-select tasKCategory1"
              aria-label="Default select example"
            >
              <option selected></option>
              <option value="1">Financial Year-1</option>
              <option value="2">Financial Year-2</option>
              <option value="2">Financial Year-3</option>
            </select>
          </div>
          <div className="col-lg-4 mb-3">
            <label htmlFor="" className="text-grey">
              Subscription Name
            </label>
            <input type="text" className="form-control tasKCategory1" />
          </div>
          <div className="col-lg-4 mb-3">
            <label htmlFor="" className="text-grey">
              Consultant ID
            </label>
            <select
              class="form-select tasKCategory1"
              aria-label="Default select example"
            >
              <option selected></option>
              <option value="1">Consultant ID-1</option>
              <option value="2">Consultant ID-2</option>
              <option value="2">Consultant ID-3</option>
            </select>
          </div>
          <div className="col-lg-4 mb-3">
            <label htmlFor="" className="text-grey">
              Branch ID
            </label>
            <select
              class="form-select tasKCategory1"
              aria-label="Default select example"
            >
              <option selected></option>
              <option value="1">Branch ID-1</option>
              <option value="2">Branch ID-2</option>
              <option value="2">Branch ID-3</option>
            </select>
          </div>
          <div className="col-lg-4 mb-3">
            <label htmlFor="" className="text-grey">
              Period's
            </label>
            {/* <input type="text" className="form-control tasKCategory1" placeholder="07:00 pm" /> */}
            <select
              class="form-select tasKCategory1"
              aria-label="Default select example"
            >
              <option selected></option>
              <option value="1">Period's-1</option>
              <option value="2">Period's-2</option>
              <option value="2">Period's-3</option>
            </select>
          </div>
          <div className="col-lg-4 mb-3">
            <label htmlFor="" className="text-grey">
              Chart of Fees ₹
            </label>
            <input type="text" className="form-control tasKCategory1" />
          </div>
          <div className="col-lg-4 mb-3">
            <label htmlFor="" className="text-grey">
              Latest Avialiable Invoice ₹
            </label>
            <input type="text" className="form-control tasKCategory1" />
          </div>
          <div className="col-lg-4 mb-3">
            <label htmlFor="" className="text-grey">
              Latest Avialiable Quotation ₹
            </label>
            <input type="text" className="form-control tasKCategory1" />
          </div>
          <div className="col-lg-4 mb-3">
            <label htmlFor="" className="text-grey">
              Negotiated Amount ₹
            </label>
            <input type="text" className="form-control tasKCategory1" />
          </div>
          <div className="col-lg-4 mb-3">
            <label htmlFor="" className="text-grey">
              Advance Amount
            </label>
            <input type="text" className="form-control tasKCategory1" />
          </div>
          <div className="d-flex justify-content-end">
            <button className="modal-cancel-btn">Close</button>
            <button className="modal-create-btn ">Submit</button>
          </div>
        </div>
</div>
        

        {subscription && (
            <div>
                <div className="d-flex justify-content-between">

            <p htmlFor="" className="create-role-text mt-4">
                      Subscription 2
                    </p>
                    <img src={close} alt="" onClick={()=> setSubscription(false)}/>
                </div>

                    <div className="row  pb-4 ">
                      <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                          Service ID
                        </label>
                        <select
                          class="form-select tasKCategory1"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1">Service ID-1</option>
                          <option value="2">Service ID-2</option>
                          <option value="2">Service ID-3</option>
                        </select>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                          Financial Year
                        </label>
                        <select
                          class="form-select tasKCategory1"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1">Financial Year-1</option>
                          <option value="2">Financial Year-2</option>
                          <option value="2">Financial Year-3</option>
                        </select>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                          Subscription Name
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                          Consultant ID
                        </label>
                        <select
                          class="form-select tasKCategory1"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1">Consultant ID-1</option>
                          <option value="2">Consultant ID-2</option>
                          <option value="2">Consultant ID-3</option>
                        </select>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                          Branch ID
                        </label>
                        <select
                          class="form-select tasKCategory1"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1">Branch ID-1</option>
                          <option value="2">Branch ID-2</option>
                          <option value="2">Branch ID-3</option>
                        </select>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                          Period's
                        </label>
                        {/* <input type="text" className="form-control tasKCategory1" placeholder="07:00 pm" /> */}
                        <select
                          class="form-select tasKCategory1"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1">Period's-1</option>
                          <option value="2">Period's-2</option>
                          <option value="2">Period's-3</option>
                        </select>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                          Chart of Fees ₹
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                          Latest Avialiable Invoice ₹
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                          Latest Avialiable Quotation ₹
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                          Negotiated Amount ₹
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                          Advance Amount
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                      </div>
                      <div>
                        <button className="modal-cancel-btn">Close</button>
                        <button className="modal-create-btn ">Submit</button>
                      </div>
                    </div>
            </div>
        )}
      </div>
    </div>
  </div>

  );
}

export default UpdateQuote;
