import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import { Link } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import NoData from "../../sharedComponent/NoData";
import Loader from "../Loader";

const TechCount = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);
  const [dataList, setDataList] = useState([])
  const [loading, setLoading] = useState(false)

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const getAgentData = () => {
    setLoading(true)

    simpleGetCallWithToken(ApiConfig.TECHNOLOGY_COUNT)
      .then((response) => {
        console.log(response)
        setDataList(response)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getAgentData()
  }, [])

  const CountData = [
    {
      srno: "1",
      technology: "Ashu",
      count: "1",
    },
    {
      srno: "2",
      technology: "Android",
      count: "91",
    },
    {
      srno: "3",
      technology: "Android developer",
      count: "0",
    },
    {
      srno: "4",
      technology: "Android developer",
      count: "0",
    },
    {
      srno: "5",
      technology: "Android Java , kotlin",
      count: "0",
    },
    {
      srno: "6",
      technology: "Automation QA",
      count: "23",
    },
    {
      srno: "7",
      technology: "Content Writer",
      count: "8",
    },
    {
      srno: "8",
      technology: "flutter",
      count: "1",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role">
          <h6 className="mt-2">Technology Count</h6>
        </div>

        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">Sr.No</th>
                <th scope="col">Technology</th>
                <th scope="col">Count</th>
              </tr>
            </thead>
            <tbody>
              {
                loading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  dataList?.tech_stack?.length > 0 ? (
                    dataList?.tech_stack?.map((data, index) => (
                      <tr key={index} className="table-row-custom">
                        <td>{index + 1}</td>
                        <td>{data.name}</td>
                        <td>{data.count}</td>
                      </tr>
                    ))


                  ) : (
                    <tr className="ErrorDataNotFound">
                      <td colSpan={9}>
                        <NoData />
                      </td>
                    </tr>
                  )

                )}


              {
                dataList?.total_count ? (
                  <tr className="table-row-custom">
                    <td></td>
                    <td></td>
                    <td>Total Count:- {dataList?.total_count}</td>
                  </tr>
                ) : (
                  ""
                )
              }

            </tbody>
          </table>
          {/* <Pagination /> */}
        </div>
      </div>
    </div>
  );
};

export default TechCount;
