import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";
import { Form, Modal, } from "react-bootstrap";
import deleteicon from "../../../assets/images/DelectAcBlc.svg";
import editicon from "../../../assets/images/EditacBlc.svg";
import { DeleteCallWithErrorResponseWithToken, PostCallWithErrorResponse, postMultipartWithAuthCall, putWithAuthCall, simpleGetCallWithToken } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { ToastMsg } from "../../TostMsg";
import Loader from "../../Loader";
import searchicon from '../../../assets/images/ic_search_dark.svg'
import ic_search_dark from "../../../assets/images/ic_search_dark.svg";
import NoData from "../../../sharedComponent/NoData";
import Pagination from "../../../sharedComponent/Pagination";


const ManageDept = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [depatMentList, setDepartMentList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState("")
  const [show, setShow] = useState(false);
  const [selectList, setSelectList] = useState([])
  const [addDepartMent, setAddDepartMent] = useState("")
  const [selectHead, setSelectHead] = useState("")
  const DepartMentId = useRef()
  const userName = useRef();
  const [currentPage, setCurrentPage] = useState(0)
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  console.log("Add departMent Data-->", selectHead, addDepartMent)
  const handleClose = (e) => {
    e?.preventDefault()
    setShow(false);
  };
  const handleShow = (item) => {
    setShow(true);
    if (item) {
      setAddDepartMent(item?.DepartmentName)
      setSelectHead(item?.DepartmentHeadId)
      DepartMentId.current = item?.DepartmentId
    }
    console.log("get department details--->", item)
    console.log("DepartMentId.current--->", DepartMentId.current)

  }

  const [showed, setShowed] = useState(false);
  const handleClosed = () => {
    setShowed(false);
  };
  const handleShowed = () => setShowed(true);

  const [showdel, setShowdel] = useState(false);
  const handleClosedel = () => {
    // e.preventDefault()
    setShowdel(false);
  };
  const handleShowdel = (item) => {
    console.log("Delete DepartMent Id--->", item)
    setShowdel(true)
    DepartMentId.current = item?.DepartmentId
    userName.current = item?.DepartmentName
  };

  useEffect(() => {
    getDepartmentList()
    getDepartmentListData()
  }, [])

  const getDepartmentList = () => {
    setLoading(true)
    simpleGetCallWithToken(ApiConfig.GET_DEPARMENT_LIST)
      .then((res) => {
        if (res.Success === true) {
          setDepartMentList(res?.Data)
          setSearchResult(res?.Data)
          console.log("Get DepartMentList---->", res.Data)

          setLoading(false)
        } else {
          console.log("Error in Get DepartMentList---->", res.Data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getDepartmentListData = () => {
    // setLoading(true)
    simpleGetCallWithToken(ApiConfig.GET_DEPARMENT_LIST)
      .then((res) => {
        if (res.Success === true) {
          setDepartMentList(res?.Data)
          setSearchResult(res?.Data)
          console.log("Get DepartMentList---->", res.Data)

          // setLoading(false)
        } else {
          console.log("Error in Get DepartMentList---->", res.Data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }


  useState(() => {
    PostCallWithErrorResponse(ApiConfig.EMPLOYEESlIST)
      .then((res) => {
        if (res.json.Success === true) {
          setSelectList(res?.json?.Data)
          console.log("Get DepartMentList---->", res.json.Data)

        } else {
          console.log("Error in Get DepartMentList---->", res.json.Data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])


  // useEffect(() => {
  //   const filteredDepartmentList = depatMentList.filter((item) =>
  //     item?.DepartmentName.toLowerCase().includes(searchValue.toLowerCase())
  //   );
  //   setSearchResult(filteredDepartmentList)
  // }, [searchValue, depatMentList])

  const onAddDepartMent = (e) => {
    e.preventDefault()
    setLoading(true)

    let body;
    console.log("Add DepartMent--->", body)

    if (DepartMentId.current) {
      body = {
        DepartmentId: parseInt(DepartMentId.current),
        DepartmentName: addDepartMent,
        DepartmentHead: parseInt(selectHead)
      }
      putWithAuthCall(ApiConfig.UPDATE_DEPARMENT, JSON.stringify(body))
        .then((res) => {
          if (res.Success === true) {
            console.log("DepartMent Update successFuly--->", res)
            ToastMsg("success", res.Message)
            setShow(false)
            setLoading(false)
            getDepartmentList()

          } else {

            console.log("DepartMent Created Error--->", res)
            ToastMsg("error", res.json.Message)
            setShow(false)
            setLoading(false)

          }
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setShow(false)

        })
    } else {


      body = {
        DepartmentName: addDepartMent,
        DepartmentHeadId: parseInt(selectHead)
      }
      PostCallWithErrorResponse(ApiConfig.ADD_DEPARTMENT, JSON.stringify(body))
        .then((res) => {
          if (res.json.Success === true) {
            console.log("DepartMent Created successFuly--->", res)
            ToastMsg("success", res.json.Message)
            setShow(false)
            setLoading(false)
            getDepartmentList()
          } else {
            console.log("DepartMent Created Error--->", res)
            ToastMsg("error", res.json.Message)
            setShow(false)

            setLoading(false)

          }
        })
        .catch((err) => {
          console.log(err)
        })
    }


  }

  const OnDeleteDepartMent = (e) => {
    e.preventDefault()
    // setLoading(true)
    // const body = {
    //   DepartmentId:parseInt(DepartMentId.current)
    // }
    DeleteCallWithErrorResponseWithToken(ApiConfig.DELETE_DEPARMENT + parseInt(DepartMentId.current))
      .then((res) => {
        if (res.json.Success === true) {
          ToastMsg("success", res.json.Message)
          setShowdel(false)
          // setLoading(false)

          getDepartmentListData()

        } else {
          ToastMsg("error", res.json.Message)
          setShowdel(false)

        }
      })
      .catch((err) => {
        console.log(err)
        setShowdel(false)

      })
  }

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    depatMentList.length &&
    depatMentList.filter((item) =>
      item.DepartmentName?.toLowerCase().includes(searchValue)
    );

  const departmentData =
    depatMentList && depatMentList.slice(startIndex, endIndex).map((item, index) => {
      return (<tr className="table-row-custom">
        <td>{index + 1}</td>
        <td>{item?.DepartmentName}</td>
        <td>{item?.DepartmentHead}</td>
        {permissions && (permissions['Edit Department'] || permissions['Delete Department']) &&
          <td>
            {
              permissions && permissions['Edit Department'] &&

              <img onClick={() => handleShow(item)} src={editicon}></img>
            }
            {
              permissions && permissions['Delete Department'] &&

              <img onClick={() => handleShowdel(item)} src={deleteicon}></img>
            }
          </td>
        }
      </tr>
      )
    })

  const departmentDataSearch =
    depatMentList && depatMentList
      ?.filter((team) =>
        team.DepartmentName?.toLowerCase().includes(searchValue.toLowerCase())
      ).slice(startIndex, endIndex).map((item, index) => {
        return (<tr className="table-row-custom">
          <td>{startIndex + index + 1}</td>
          <td>{item?.DepartmentName}</td>
          <td>{item?.DepartmentHead}</td>
          {permissions && (permissions['Edit Department'] || permissions['Delete Department']) &&
            <td>
              {permissions && permissions['Edit Department'] &&
                <img onClick={() => handleShow(item)} src={editicon}></img>
              }

              {permissions && permissions['Delete Department'] &&
                <img onClick={() => handleShowdel(item)} src={deleteicon}></img>
              }
            </td>
          }
        </tr>
        )
      })

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">Organization Info</h6>
        </div>
      </div>
      <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
        <div className="role-table-header accoridance">
          <div className="search-input-wrapper allAcordiance">
            {permissions && permissions["View Organization Details"] && (
              <Link to="/OrgDetails">
                <button className="all-anc-btn-sub-header ">
                  Organization Details
                </button>
              </Link>
            )}
          </div>
          <div className="entries-wrapper myAccordiance">
            {permissions && permissions["View Manage Departments"] && (
              <Link to="/ManageDept">
                <button className="my-anc-btn-sub-header active">
                  Manage Department
                </button>
              </Link>
            )}
          </div>
          <div className="entries-wrapper myAccordiance">
            {permissions && permissions["View Manage Designation"] && (
              <Link to="/ManageDesig">
                <button className="my-anc-btn-sub-header">
                  Manage Designation
                </button>
              </Link>
            )}
          </div>
          <div className="entries-wrapper myAccordiance">
            {permissions && permissions["View Manage Locations"] && (
              <Link to="/ManageLoc">
                <button className="my-anc-btn-sub-header">
                  Manage Locations
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex  justify-content-between ">

          <div className="search-input-wrapper">
            <input
              type="text"
              placeholder="Department Name"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            {Dark === "lightMode" ? (
              <img src={searchicon} alt="" />
            ) : (
              <img src={ic_search_dark} alt="" />
            )}
            <select
              className="form-select select-drop-icon ml-5"
              aria-label="Default select example"
              onChange={(e) => {
                setUsersPerPage(e.target.value);
                setCurrentPage(0);
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25" selected>25</option>
            </select>
          </div>
          {
            permissions && permissions['Add Department'] &&

            <div className="d-flex justify-content-end my-3 ">
              <button className="allocateionBtn mt-2" onClick={handleShow}>
                + Add Department
              </button>
            </div>
          }
        </div>
        {/* <div className="d-flex justify-content-end my-3">
          <button className="allocateionBtn" onClick={handleShow}>
            Add New Record
          </button>
        </div> */}
        <div
          className="table-wrapper custom-table-width"
          id="scrol-tblNtable-wrapper-af"
        >
          {
            loading ? (
              <Loader />
            ) : departmentData && departmentData.length > 0 ? (
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <th>Sr. no.</th>
                    <th>Department Name</th>
                    <th>Department Head</th>
                    {permissions && (permissions['Edit Department'] || permissions['Delete Department']) &&
                      <th>Action</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {searchValue === "" ?
                    departmentData
                    : departmentDataSearch
                  }

                </tbody>
              </table>
            ) : (

              <>
                <NoData />
              </>
            )
          }

          {!loading && searchedDataPageCounts.length > usersPerPage && (
            <Pagination
              data={depatMentList}
              pageChangeHandler={pageChangeHandler}
              usersPerPage={usersPerPage}
              currentPage={currentPage}
              searchValue={searchValue}
              searchedDataPageCounts={searchedDataPageCounts}
            />
          )}
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          //dialogClassName="custom-modal"
          size="lg"
          dialogClassName="modal-90w"
          centered
        >
          <Modal.Header closeButton className="custom-close-btn">
            <Modal.Title className="custom-modal-title">
              {DepartMentId.current ? "Update Department" : "Add New Department"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Department Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Department Name"

                  value={addDepartMent}
                  onChange={(e) => setAddDepartMent(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Department Name!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Department Head</Form.Label>
                <Form.Select
                  value={selectHead}
                  onChange={(e) => setSelectHead(e.target.value)}
                >
                  <option >Select</option>

                  {
                    selectList.map((department) => (
                      <option value={department?.Id}>{department?.FullName}</option>

                    ))
                  }

                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please Enter Department Head!
                </Form.Control.Feedback>
              </Form.Group>

              <hr />
              <div className="d-flex justify-content-end">
                <button className="mx-2 allocateionBtnwhite" onClick={handleClose}>
                  cancel
                </button>
                <button className="allocateionBtn" onClick={onAddDepartMent}>

                  {DepartMentId.current ? "Update" : "save "}

                </button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={showdel}
          onHide={handleClosedel}
          size="lg"
          dialogClassName="modal-90w"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="delete-title">
              Delete Confirmation
            </Modal.Title>
          </Modal.Header>
          <div class="lineSeperate"></div>
          <Modal.Body className="delete-body ps-4">
            <p className="text-center m-3"> Are you sure, You want to delete? {userName.current}</p>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button className="btn-width cancelBtn mx-3" onClick={handleClosedel}>
              Cancel
            </button>
            <button
              className="btn-width saveBtn"
              onClick={OnDeleteDepartMent}
            >
              Delete
            </button>
          </div>

        </Modal>

      </div>
    </div>
  );
};
export default ManageDept;
