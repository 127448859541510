import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import optionIcon from "../../assets/images/bx_edit.svg";
import "../../assets/css/myAttendence.scss";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import disabled_edit from "../../assets/images/disabled_edit.svg";
import {
  convertTimeIn24Format,
  getDDateddmmyyyy,
  displayTime,
} from "../../common";

import moment from "moment";

const EmployeeAttendence = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  const [editRecord, setEditRecord] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setEditRecord(false);
  const [empolyeList, setEmpolyeList] = useState([]);
  console.log("empolyeList,", empolyeList);
  const [empoleName, setEmpoleName] = useState([]);
  const [emplyId, serEmplyId] = useState(Number(userData.EmpId));
  const [loading, setLoading] = useState(false);
  var d = new Date();
  let mon = d.getMonth() + 1;
  const [employeeListObj, setEmployeeListObj] = useState({
    EmployeeId: Number(userData.EmpId),
    OrgId: Number(userData.OrgId),
    Month: mon,
    Year: 1,
  });
  const [editObj, setEditObj] = useState({
    Id: 1,
    EmpId: Number(userData.EmpId),
    OrgId: Number(userData.OrgId),
  });
  const [ids, setIds] = useState();
  const [updatePayloadData, setupdatePayloadData] = useState({
    Id: 0,
    OrgId: Number(userData.OrgId),
    Intime: "",
    OutTime: "",
    Date: "",
    InPlatform: 1,
    OutPlatform: 1
  });
  const [DataShift, setDataShift] = useState([]);
  const [inoutTime, setInoutTime] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const minOffset = 0;
  const maxOffset = 1;
  let crryear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(crryear - x);
  }
  useEffect(() => {
    getEmpolylistApi();
  }, [employeeListObj]);

  const getEmpolylistApi = () => {
    setLoading(false);
    if (employeeListObj.Month >= 1 && employeeListObj.Year >= 1) {
      setLoading(true);

      PostCallWithErrorResponse(
        ApiConfig.EMPLOYEE_LIST,
        JSON.stringify(employeeListObj)
      )
        .then((res) => {
          setLoading(false);
          setEmpolyeList(
            res.json?.Data.Attendance ? res.json?.Data.Attendance : []
          );

          setDataShift(
            res.json?.Data.ShiftTime ? res.json?.Data.ShiftTime : []
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    PostCallWithErrorResponse(
      ApiConfig.EMPLOEE_NAME_LIST,
      JSON.stringify({ OrgId: Number(userData.OrgId) })
    )
      .then((res) => {
        console.log(res);
        setEmpoleName(res.json?.Data ? res.json?.Data : []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getTimeApi = (id, date) => {
    console.log("id,", id);
    console.log("date,", getDDateddmmyyyy(date));
    const body = {
      AttenId: id,
      EmpId: emplyId,
      OrgId: Number(userData.OrgId),
    };
    PostCallWithErrorResponse(ApiConfig.GETINOUT_TIME, JSON.stringify(body))
      .then((res) => {
        setInoutTime(res.json.Data);
        var inTimeIn24Format =
          res.json.Data[0]?.InTime &&
          moment(res.json.Data[0]?.InTime, "hh:mm A").format("HH:mm");
        var outTimeIn24Format =
          res.json.Data[0]?.OutTime &&
          moment(res.json.Data[0]?.OutTime, "hh:mm A").format("HH:mm");
        // if (res.json.Data.length !== 0) {
        setupdatePayloadData({
          ...updatePayloadData,
          EmpId: res.json.Data[0]?.EmployeeId
            ? res.json.Data[0]?.EmployeeId
            : emplyId,
          Id: res.json.Data[0]?.Id ? res.json.Data[0]?.Id : 0,
          Intime: inTimeIn24Format ? inTimeIn24Format : "",
          OutTime: outTimeIn24Format ? outTimeIn24Format : "",
          Date: getDDateddmmyyyy(date),
        });
        // }
        setEditObj({
          ...editObj,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateTimeApi = () => {
    setIsLoading(true);
    PostCallWithErrorResponse(
      ApiConfig.UPDATE_TIME,
      JSON.stringify(updatePayloadData)
    )
      .then((res) => {
        if (res.json && res.json?.Success === true) {
          ToastMsg("success", res.json.Message);
          setIsLoading(false);
          handleClose(false);
        } else {
          ToastMsg("error", res.json.Message);
          setIsLoading(false);
        }
        getEmpolylistApi(emplyId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertTime12to24forPunchOutTime = (time12h) => {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    } else {
      hours = parseInt(hours, 10);
    }
    return `${hours < 10 ? 0 : ""}${hours}:${minutes}`;
  };

  // let permissions = userData.Permissions;
  let splitPermissionsArray = permissions;

  var now = new Date();
  var datetime = now.toLocaleTimeString();

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content-main">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Subordinates Attendance</h6>
          </div>
          <div className="d-flex mainHeader2 custom-mb">
            <div className="role-table-header-main">
              <div className="search-input-wrapper-main">
                <select
                  className="form-select input-select-option"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setEmployeeListObj({
                      ...employeeListObj,
                      EmployeeId: Number(e.target.value),
                    });
                    serEmplyId(Number(e.target.value));
                  }}
                  value={employeeListObj.EmployeeId}
                >
                  <option value="">Select Employee Name</option>
                  {empoleName &&
                    empoleName.map((emp) => {
                      return <option value={emp.Id}>{emp.FullName}</option>;
                    })}
                </select>
              </div>
              <div className="search-input-wrapper-main mx-4">
                <select
                  className="form-select input-select-option"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setEmployeeListObj({
                      ...employeeListObj,
                      Year: Number(e.target.value) + 1,
                    });
                  }}
                  value={employeeListObj.Year - 1}
                >
                  {/* <option value="">Select Year</option> */}
                  {allYears.map((year, i) => {
                    return (
                      <option key={i} value={i}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="search-input-wrapper-main">
                <select
                  className="form-select input-select-option"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setEmployeeListObj({
                      ...employeeListObj,
                      Month: Number(e.target.value),
                    });
                  }}
                  value={employeeListObj.Month}
                >
                  {/* <option value="">Select Month</option> */}
                  <option value="1">Janaury</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
            </div>
            {/* <div className="entries-wrapper-main ">
              <p>Entries per page</p>
              <select
                className="form-select select-drop"
                aria-label="Default select example"
              >
                <option selected>10</option>
                <option value="1">20</option>
                <option value="2">10</option>
                <option value="3">5</option>
              </select>
            </div> */}
          </div>
          {/* <div className='emptyPage'>
                        <img src={emptypage} alt="" />
                    </div> */}
          <div className="table-wrapper-main mb-4">
            {loading ? (
              <Loader />
            ) : (
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <th>Sr. No</th>
                    <th>Date</th>
                    <th>Shift Timing</th>
                    <th>Check In</th>
                    <th>Check Out</th>
                    <th>Total Hours</th>
                    <th>Remarks</th>
                    {/* {splitPermissionsArray && splitPermissionsArray["Edit Employee Attendance"] && } */}

                    {permissions &&
                      permissions["Edit Subordinates Attendance"] &&
                      <th>Actions</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {empolyeList && empolyeList.length > 0 ? (
                    empolyeList.map((empoly, index) => {
                      return (
                        <tr key={index + "empoly"} className="table-row-main">
                          <td> {index + 1} </td>
                          <td>{getDDateddmmyyyy(empoly.InTime)}</td>
                          <td>
                            {DataShift?.InTime?.split("T")[1].substring(0, 5)} -
                            {DataShift?.OutTime?.split("T")[1].substring(0, 5)}{" "}
                          </td>
                          <td>{empoly.PunchIn}</td>
                          <td>{empoly.PunchOut}</td>
                          <td>{empoly.DayTotal} </td>
                          <td
                            className={
                              empoly.Remark === "Absent"
                                ? "status-label-danger"
                                : empoly.Remark === "Present" ||
                                  empoly.Remark === "Present Day"
                                  ? "status-label-green"
                                  : empoly.Remark === "Weekend" ||
                                    empoly.Remark === "Off Day"
                                    ? "status-grey"
                                    : empoly.Remark === "Late punch In" ||
                                      empoly.Remark === "Early punch Out" ||
                                      empoly.Remark === "Miss punch"
                                      ? "status-label-warning"
                                      : empoly.Remark === "On Leave" ||
                                        empoly.Remark === "Half Day Leave" ||
                                        empoly.Remark === empoly.Remark
                                        ? "status-h-leave"
                                        : "status-label-grey"
                            }
                          >
                            {empoly.Remark}
                          </td>
                          {/* {splitPermissionsArray && splitPermissionsArray.includes("Edit Employee Attendance") && (
                            
                          )} */}

                          {permissions &&
                            permissions["Edit Subordinates Attendance"] && (
                              <td>
                                <img
                                  src={optionIcon}
                                  alt=""
                                  className="pointer"
                                  onClick={() => {
                                    setEditRecord(true);
                                    getTimeApi(
                                      empoly.AttendanceId,
                                      empoly.InTime
                                    );
                                    setIds(empoly.Id);
                                    setupdatePayloadData({
                                      Id: 0,
                                      EmpId: Number(employeeListObj.EmployeeId),
                                      OrgId: Number(userData.OrgId),
                                      Intime: "",
                                      OutTime: "",
                                      Date: "",
                                    });
                                  }}
                                />


                                {/* {empoly.Remark === "Present" ? (
                                <img
                                  src={optionIcon}
                                  alt=""
                                  className="pointer"
                                  onClick={() => {
                                    setEditRecord(true);
                                    getTimeApi(empoly.Id, empoly.InTime);
                                    setIds(empoly.Id);
                                    setupdatePayloadData({
                                      Id: 0,
                                      EmpId: Number(userData.EmpId),
                                      OrgId: Number(userData.OrgId),
                                      Intime: "",
                                      OutTime: "",
                                      Date: ""
                                    });
                                  }}
                                />
                              ) : (
                                <img
                                  src={disabled_edit}
                                  alt=""
                                  className="me-3"
                                />
                              )} */}
                              </td>
                            )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="ErrorDataNotFound">
                      <td colSpan={6}>Data Not Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      {/* Modal For Edit */}
      <Modal
        Modal
        size="md"
        show={editRecord}
        onHide={() => setEditRecord(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Record</Modal.Title>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Modal.Body>
          <div className="editModalBody">
            <div className="d-flex align-items-center diffbtns">
              <div className="mx-2">
                <label>In Time</label>
              </div>
              <div>
                <input
                  value={
                    updatePayloadData?.Intime
                      ? convertTime12to24forPunchOutTime(
                        updatePayloadData?.Intime
                      )
                      : "00:00"
                  }
                  type="time"
                  onChange={(e) => {
                    setupdatePayloadData({
                      ...updatePayloadData,
                      Intime: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="d-flex align-items-center diffbtns">
              <div className="mx-2">
                <label>Out Time</label>
              </div>
              <div>
                <input
                  value={
                    updatePayloadData?.OutTime
                      ? convertTime12to24forPunchOutTime(
                        updatePayloadData?.OutTime
                      )
                      : "00:00"
                  }
                  type="time"
                  onChange={(e) => {
                    setupdatePayloadData({
                      ...updatePayloadData,
                      OutTime: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose}>
            Cancel
          </button>

          <button
            className="btn-width saveBtn"
            onClick={UpdateTimeApi}
            disabled={isLoading ? true : false}
          >
            {isLoading ? (
              <div
                className="spinner-border mx-auto mt-2"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EmployeeAttendence;
