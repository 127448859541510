import React, { useContext } from 'react'
import { AppContext } from "../../context/AppContext";


function AccountsMoneyReciept() {
  const { sidebar } = useContext(AppContext);

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"}  id="cx-main">

    <div
     
    >
      <div className="row">
        <div className="d-flex justify-content-center">
          <div className="col-lg-9 content-small22">
            <div className="payment-reciept-header small-header22">
              <div>
                <p htmlFor="" className="advance-pay-reciept small-heading22">
                Money Receipt
                </p>
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <p htmlFor="" className="reciept-number">
                    Receipt No.
                  </p>{" "}
                  <p htmlFor="" className="date-reciept">
                    XYZ123456
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p htmlFor="" className="reciept-number">
                    Date
                  </p>{" "}
                  <p htmlFor="" className="date-reciept">
                    29/09/2022
                  </p>
                </div>
              </div>
            </div>
            <div className="payment-reciept-wrapper">
              <div className="row">
                <div className="col-lg-6 mt-2">
                  <p>Client Name</p>
                  <label htmlFor="">Mr. John Doe</label>
                </div>
                <div className="col-lg-6 mt-2">
                  <p>Branch</p>
                  <label htmlFor="">Mr. John Doe</label>
                </div>
                
                <div className="col-lg-6 mt-4">
                  <p>Amount</p>
                  <label htmlFor="">Designing and Deployment</label>
                </div>
                <div className="col-lg-6 mt-4">
                  <p>Amount in Words</p>
                  <label htmlFor="">Designing and Deployment</label>
                </div>
                <div className="col-lg-6 mt-4">
                  <p>Payment Purpose</p>
                  <label htmlFor="">Designing and Deployment</label>
                </div>
                <div className="col-lg-6 mt-4">
                  <p>Invoice No & Date.</p>
                  <label htmlFor="">Designing and Deployment</label>
                </div>
                <div className="col-lg-6 pe-0 mt-4">
                    <div className='amount-details'>
                    Amount Details
                    </div>
                    <div className="amount-details-wrapper text-center">
                        <div className='mt-4'>
                        <label htmlFor="">Total Due Amount</label>
                        <span>2000.00</span>
                        </div>
                        <div className='mt-4'>
                        <label htmlFor="">Total Due Amount</label>
                        <span>2000.00</span>
                        </div>
                        <hr  style={{marginBottom:"9px"}}/>
                        <div className=''>
                        <label htmlFor="" className='balance'>Balance Due</label>
                        <span className='balance'>100</span>
                        </div>
                       
                    </div>
                </div>
                <div className="col-lg-6 ps-0 mt-4">
                    <div className='amount-details'>
                    Payment Modes
                    </div>
                    <div className="amount-details-wrapper-1 ">
                        <div className='border-before'>

                        <div className='mt-4'>
                        <label htmlFor="">Mode</label>
                        <span style={{marginLeft:"47px"}}>Cheque</span>
                        </div>
                        <div className='mt-4'>
                        <label htmlFor="">Cheque No.</label>
                        <span>123456789012345</span>
                        </div>
                        <div className='mt-4'>
                        <label htmlFor="">Date</label>
                        <span style={{marginLeft:"55px"}}>29-09-2022</span>
                        </div>
                        <div className='mt-2'>
                        
                        </div>
                        </div>

                       
                    </div>
                </div>
                <div className="col-lg-12">
                    <hr />
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6 mt-4 ">
                  <p>Authorized By</p>
                  <label htmlFor="">Name and Signature/Stamp</label>
                  <div className="stamp-box small-box22"></div>
                </div>
              </div>
            </div>
            <div className="reciept-footer">
                
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

  )
}

export default AccountsMoneyReciept