import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import deleteicon from "../../../assets/images/DelectAcBlc.svg";
import editicon from "../../../assets/images/EditacBlc.svg";
import { DeleteCallWithErrorResponseWithTokenQuery, PostCallWithErrorResponse, putWithAuthCall, simpleGetCallWithToken } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { ToastMsg } from "../../TostMsg";
import Loader from "../../Loader";
import searchicon from '../../../assets/images/ic_search_dark.svg'
import ic_search_dark from "../../../assets/images/ic_search_dark.svg";
import NoData from "../../../sharedComponent/NoData";
import Pagination from "../../../sharedComponent/Pagination";


const ManageDesig = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false)
  const [departmentList, setDepartMentList] = useState([]);
  const [departmentName, setDepatMentName] = useState("")
  const deleteId = useRef()
  const UpdateDesigetionId = useRef()
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [currentPage, setCurrentPage] = useState(0)
  const [usersPerPage, setUsersPerPage] = useState(25);
  const userName = useRef()

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);





  const [showed, setShowed] = useState(false);
  const handleClosed = (e) => {
    e?.preventDefault()
    setShowed(false);
  };
  const handleShowed = (item) => {
    console.log("department Details--->", item)
    setDepatMentName(item.DesignationName)
    UpdateDesigetionId.current = item.Id
    setShowed(true)

  };

  const [showdel, setShowdel] = useState(false);
  const handleClosedel = () => {
    setShowdel(false);
  };
  const handleShowdel = (item) => {
    console.log("department Delete--->", item)
    deleteId.current = item?.Id
    userName.current = item?.DesignationName
    setShowdel(true)
  };

  const getDepartmentList = () => {
    setLoading(true)
    simpleGetCallWithToken(ApiConfig.GET_DESIGNEGETION_LIST)
      .then((res) => {
        if (res.Success === true) {
          setLoading(false)
          setDepartMentList(res?.Data)
        }

        console.log("department List---->", res?.Data)
      })
      .catch((err) => {
        console.log("Error department List---->", err)

      })
  }

  const getDepartmentListData = () => {
    // setLoading(true)
    simpleGetCallWithToken(ApiConfig.GET_DESIGNEGETION_LIST)
      .then((res) => {
        if (res.Success === true) {
          // setLoading(false)
          setDepartMentList(res?.Data)
        }

        console.log("department List---->", res?.Data)
      })
      .catch((err) => {
        console.log("Error department List---->", err)

      })
  }

  useEffect(() => {
    getDepartmentList()
    getDepartmentListData()
  }, [])

  // useEffect(() => {
  //   const filteredDepartmentList = departmentList.filter((item) =>
  //     item.DesignationName.toLowerCase().includes(searchValue.toLowerCase())
  //   );
  //   setSearchResult(filteredDepartmentList)
  // }, [searchValue, departmentList])

  const onSubmitDepartmentFrom = (e) => {
    e.preventDefault();
    setLoading(true)
    console.log("Desigetion Id--->", UpdateDesigetionId.current)

    let body;
    if (UpdateDesigetionId.current) {
      body = {
        DesignationId: UpdateDesigetionId.current ? UpdateDesigetionId.current : "",
        DesignationName: departmentName
      }
      PostCallWithErrorResponse(ApiConfig.ADD_DESIGNEGETION, JSON.stringify(body))
        .then((res) => {
          console.log("department Add---->", res)
          if (res.json.Success === true) {
            ToastMsg("success", res.json?.Message)
            setShowed(false)
            setLoading(false)
            getDepartmentList();
          } else {
            ToastMsg("error", res.json?.Message)
            setLoading(false)
            setShowed(false)

          }
        })
        .catch((err) => {
          console.log("Error department Add---->", err)
        })
    } else {
      body = {
        DesignationName: departmentName
      }
      PostCallWithErrorResponse(ApiConfig.ADD_DESIGNEGETION, JSON.stringify(body))
        .then((res) => {
          console.log("department Add---->", res)
          if (res.json.Success === true) {
            ToastMsg("success", res.json?.Message)
            setShowed(false)
            setLoading(false)
            getDepartmentList();
          } else {
            ToastMsg("error", res.json?.Message)
            setLoading(false)
            setShowed(false)

          }
        })
        .catch((err) => {
          console.log("Error department Add---->", err)
        })
    }
  }

  const onDelete = (e) => {
    e.preventDefault();
    // setLoading(true)
    console.log("delete Id--->", deleteId.current)

    const url = ApiConfig.DELETE_DESIGNEGETION.replace('{id}', deleteId.current);


    DeleteCallWithErrorResponseWithTokenQuery(url)
      .then((res) => {
        console.log("department dELETE---->", res)
        if (res.json.Success === true) {
          ToastMsg("success", res.json?.Message)
          setShowdel(false)
          // setLoading(false)
          getDepartmentListData();
        } else {
          ToastMsg("error", res.json?.Message)
          setLoading(false)

        }
      })
      .catch((err) => {
        console.log("Error department Add---->", err)
      })

  }

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    departmentList.length &&
    departmentList.filter((item) =>
      item.DesignationName?.toLowerCase().includes(searchValue)
    );

  const desigentionData =
    departmentList && departmentList
      ?.filter((team) =>
        team.DesignationName?.toLowerCase().includes(searchValue.toLowerCase())
      ).slice(startIndex, endIndex).map((item, index) => {
        return (<tr className="table-row-custom" key={index}>
          <td>{startIndex + index + 1}</td>
          <td>{item?.DesignationName}</td>
          {
            permissions && (permissions['Delete Designation'] || permissions['Edit Designation']) &&
            <td>
              {permissions && permissions['Edit Designation'] &&
                <img onClick={() => handleShowed(item)} src={editicon} alt="Edit Icon" />
              }
              {permissions && permissions['Delete Designation'] &&
                <img onClick={() => handleShowdel(item)} src={deleteicon} alt="Delete Icon" />
              }
            </td>
          }
        </tr>
        )
      })

  const desigentionDataSearch =
    departmentList && departmentList
      ?.filter((team) =>
        team.DesignationName?.toLowerCase().includes(searchValue.toLowerCase())
      ).slice(startIndex, endIndex).map((item, index) => {
        return (<tr className="table-row-custom" key={index}>
          <td>{startIndex + index + 1}</td>
          <td>{item?.DesignationName}</td>
          {
            permissions && (permissions['Delete Designation'] || permissions['Edit Designation']) &&
            <td>
              {
                permissions && permissions['Edit Designation'] &&
                <img onClick={() => handleShowed(item)} src={editicon} alt="Edit Icon" />
              }
              {
                permissions && permissions['Delete Designation'] &&
                <img onClick={() => handleShowdel(item)} src={deleteicon} alt="Delete Icon" />
              }
            </td>
          }
        </tr>
        )
      })

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">Organization Info</h6>
        </div>
      </div>
      <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
        <div className="role-table-header accoridance">
          <div className="search-input-wrapper allAcordiance">
            {permissions && permissions["View Organization Details"] && (
              <Link to="/OrgDetails">
                <button className="all-anc-btn-sub-header">
                  Organization Details
                </button>
              </Link>
            )}
          </div>
          <div className="entries-wrapper myAccordiance">
            {permissions && permissions["View Manage Departments"] && (
              <Link to="/ManageDept">
                <button className="my-anc-btn-sub-header">
                  Manage Department
                </button>
              </Link>
            )}
          </div>
          <div className="entries-wrapper myAccordiance">
            {permissions && permissions["View Manage Designation"] && (
              <Link to="/ManageDesig">
                <button className="my-anc-btn-sub-header active">
                  Manage Designation
                </button>
              </Link>
            )}
          </div>
          <div className="entries-wrapper myAccordiance">
            {permissions && permissions["View Manage Locations"] && (
              <Link to="/ManageLoc">
                <button className="my-anc-btn-sub-header">
                  Manage Locations
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex  justify-content-between ">

          <div className="search-input-wrapper    ">
            <input
              type="text"
              placeholder="Designation Name"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            {Dark === "lightMode" ? (
              <img src={searchicon} alt="" />
            ) : (
              <img src={ic_search_dark} alt="" />
            )}
            {/* <div className="entries-wrapper"> */}
            {/* <p>Entries per page</p> */}
            <select
              className="form-select select-drop-icon ml-5"
              aria-label="Default select example"
              onChange={(e) => {
                setUsersPerPage(e.target.value);
                setCurrentPage(0);
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25" selected>25</option>
            </select>
          </div>
          {/* </div> */}
          {
            permissions && permissions['Add Designation'] &&

            <div className="d-flex justify-content-end my-3 ">
              <button className="allocateionBtn mt-2" onClick={handleShowed}>
                + Add Designation
              </button>
            </div>
          }
        </div>


        <div
          className="table-wrapper custom-table-width"
          id="scrol-tblNtable-wrapper-af"
        >

          {
            loading ? (
              <Loader />
            ) : desigentionData && desigentionData.length > 0 ? (
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <th>Sr. no.</th>
                    <th>Designation Name</th>
                    {
                      permissions && (permissions['Delete Designation'] || permissions['Edit Designation']) &&

                      <th>Action</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {searchValue === "" ?
                    desigentionData
                    : desigentionDataSearch
                  }
                </tbody>
              </table>
            ) : (
              <>
                <NoData />
              </>
            )
          }

          {!loading && searchedDataPageCounts.length > usersPerPage && (
            <Pagination
              data={departmentList}
              pageChangeHandler={pageChangeHandler}
              usersPerPage={usersPerPage}
              currentPage={currentPage}
              searchValue={searchValue}
              searchedDataPageCounts={searchedDataPageCounts}
            />
          )}

        </div>
        <Modal
          show={showed}
          onHide={handleClosed}
          centered
          //dialogClassName="custom-modal"
          size="lg"
          dialogClassName="modal-90w"

        >
          <Modal.Header closeButton className="custom-close-btn">
            <Modal.Title className="custom-modal-title">
              {UpdateDesigetionId.current ? "Update Designation " : "Add New Designation "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group controlId="company" className="mb-3">
              <Form.Label>Designation Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Designation Name"
                required
                value={departmentName}
                onChange={(e) => setDepatMentName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Designation Name!
              </Form.Control.Feedback>
            </Form.Group>
            <hr />
            <div className="d-flex justify-content-end">
              <button className="allocateionBtnwhite mx-2" onClick={handleClosed}>
                cancel
              </button>
              <button className="allocateionBtn" onClick={onSubmitDepartmentFrom}>

                {UpdateDesigetionId.current ? "Update" : "Add"}

              </button>
            </div>

          </Modal.Body>
        </Modal>



        <Modal
          show={showdel}
          onHide={handleClosedel}
          //dialogClassName="custom-modal"
          size="lg"
          dialogClassName="modal-90w"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="delete-title">
              Delete Confirmation
            </Modal.Title>
          </Modal.Header>
          <div class="lineSeperate"></div>
          <Modal.Body className="delete-body ps-4">
            <p className="text-center m-3"> Are you sure, You want to delete? {userName.current}</p>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button className="btn-width cancelBtn mx-3" onClick={handleClosedel}>
              Cancel
            </button>
            <button
              className="btn-width saveBtn"
              onClick={onDelete}
            >
              Delete
            </button>
          </div>

        </Modal>


      </div>
    </div>
  );
};
export default ManageDesig;
