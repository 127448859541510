import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";

import "../../assets/css/crmphonebook/Agent.scss";

import { Link, useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";



export default function UserDetailsForm() {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const { } = useContext(AppContext);

  const { id } = useParams();
  const navigate = useNavigate();
  const [detailsdata,setDetailsData] = useState({})
  const [loading,setLoding] = useState(false)

  console.log("userDatails Id>>>", id)

  const navigetEditeAgentDedails=(id)=>{
    navigate(`/UpdateUserDetails/${id}`); 
  }

  const navigetCallHistoryDedails=(id)=>{
    navigate(`/CallHistory/${id}`); 
  }

  // const agentdetails = [
  //   {
  //     FirstName: "None",
  //     LastName: "None",
  //     Email: "pankaj.p@coudxperte.com",
  //     ContactNumber: "+919307776804",
  //     Gender: "NA",
  //     Type: "Agent",
  //     City: "NA",
  //     State: "NA",
  //     ExpectedCTC: " NA",
  //     ExpectedJobRole: " NA",
  //     Offer: "NA",
  //     PreferredLocation: "NA",
  //     AgreeForRelocation: "NA",
  //     Negotiable: "NA",
  //     JobRoleDescription: "",
  //     CurrentCompany: "NA",
  //     Projects: "",
  //     JobChangeReason: "",
  //     ResumeTitle: "",
  //     Communication: "NA",
  //     Remark: "NA",
  //     AdditionalNotes: "NA",
  //     ECTCNegotiable: "NA",
  //     ECTCMarketStandard: "NA",
  //     ECTCHike: "NA",
  //     RelevantExp: "NA",
  //     Lastorganization: "NA",
  //     ScheduleDay: "NA",
  //     ScheduleTime: "NA",
  //   },
  // ];

  const getAgentdetails = () => {
    setLoding(true)
    const url = id
    simpleGetCallWithToken(ApiConfig.GET_USER_DETAILS + `${url}/`)
      .then((response) => {
        console.log("useDetails Data>>>>", response)
        setDetailsData(response?.user_details)
        setLoding(false)
      })
      .catch((err) => {
        console.log("adent Details Error>>>", err)
        setLoding(false)
      })
  }

  useEffect(()=>{
    getAgentdetails()
  },[])

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="main-chat">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">User Details</h6>
          </div>
          <div id="tabsBelow" className="mt-4">
            <div className="button-maindiv">
              <div className="button-subdiv">
                <button className="sml-btn-sub-header" onClick={()=>navigetEditeAgentDedails(detailsdata?.id)}>
                  <Link 
                  // to="/UpdateUserDetails" 
                  className="details-link">
                    Edit User Details
                  </Link>
                </button>

                <button className="sml-btn-sub-header"
                 onClick={()=>navigetCallHistoryDedails(detailsdata?.id)}
                 >
                  <Link 
                  // to="/CallHistory" 
                  className="details-link">
                    Call History
                  </Link>
                </button>
              </div>
            </div>
            <div className="tabledata1">
              {/* {agentdetails.map((item) => ( */}
              {loading?(<>
              <Loader/>
              </>):(
                <div className="tabledata">
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      First Name :
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.firstname}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Last Name :
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.lastname}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">Email :</label>
                    <label className="enter-role-text col-md">
                      {detailsdata.email}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">
                      Contact Number :
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.phone_number}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">Gender :</label>
                    <label className="enter-role-text col-md">
                      {detailsdata.gender}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">Type :</label>
                    <label className="col-md enter-role-text">
                      {detailsdata.type}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">City :</label>
                    <label className="enter-role-text col-md">
                      {detailsdata.City}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">State :</label>
                    <label className="enter-role-text col-md">
                      {detailsdata.state}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Expected CTC :
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.expected_ctc}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Expected Job Role :
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.expected_job_role}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">Offer :</label>
                    <label className="enter-role-text col-md">
                      {detailsdata.offer}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Preferred Location :
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.location}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Agree For Relocation :
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.agree_for_relocation}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">
                      Negotiable :
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.negotiable
                      }
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Job Role Description :
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.job_role_description}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">
                      Resume Title :
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.resume_title}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Communication :
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.communication}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">Remark :</label>
                    <label className="enter-role-text col-md">
                      {detailsdata.Remark}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Additional Notes :
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.Additional_notes}
                    </label>
                  </div>
                  <div className=" row  tablerowdata">
                    <label className="enter-role-text col-md">
                      ECTC Market Standard :
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.ectc_market_standard}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">ECTCHike :</label>
                    <label className="enter-role-text col-md">
                      {detailsdata.ectc_hike}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">
                      Relevant Exp:
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.relevant_exp}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Last day in organization:
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.last_day_in_organization}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Schedule Meeting Day:
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.schedule_meeting_day}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">
                      Schedule Meeting Time:
                    </label>
                    <label className="enter-role-text col-md">
                      {detailsdata.schedule_meting_time}
                    </label>
                  </div>
                </div>
                 )}
              {/* ))} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
