import BookIcon from "../../assets/images/ic_excel.svg";
import Modal from "react-bootstrap/Modal";
import Pagination from "../../sharedComponent/Pagination";
import React, { useContext, useEffect, useRef, useState } from "react";
import deleteicon from "../../assets/images/delete_icon_gray.svg";
import edit from "../../assets/images/edit_officeCaller.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import phone from "../../assets/images/phone.svg";
import phonetwentyfour from "../../assets/images/phonetwentyfour.svg";
import profileblue from "../../assets/images/profileblue.svg";
import GroundAmbualnce from "../../assets/images/ground-ambulance.svg";
import { postWithAuthCall, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import NoData from "../../sharedComponent/NoData";
import Loader from "../Loader";
import { formatDate } from "../../common";
import { ToastMsg } from "../TostMsg";
import info from "../../assets/images/info-circle.svg"

const WhatsappPromotion = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
    const [selectedRows, setSelectedRows] = useState(10);
    const [dateData, setDateData] = useState({
      startDate: "",
      endDate: "",
    })
    const [promotionList, setPromotionList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [details,setDetails] = useState({})
    const naviget = useNavigate()
    const promotionId = useRef()
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true)
    promotionId.current = item
  };


  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };

  const dataArray = [
    {
      id: 1,
      name: "Call",
      Count: "23232",
      img: phone,
    },
    { id: 2, name: "Users", Count: "23232", img: profileblue },
    { id: 3, name: "Candidates", Count: "23232", img: profileblue },
    { id: 4, name: "Agents", Count: "23232", img: profileblue },
    {
      id: 5,
      name: "Not Call Classification",
      Count: "23232",

      img: phone,
    },
    { id: 6, name: "Zero Call List", Count: "23232", img: phone },

    { id: 7, name: "Services Request", Count: "23232", img: phonetwentyfour },
  ];

  const dataMap = new Map(dataArray.map((item) => [item.id, item]));

  const handleEdit = (item) => {

    naviget("/CustomGroup/" + item)
  }

  const handleCancle = (item) => {
    setShow(true)
    console.log(item)
    promotionId.current = item
  }

  const handleClose = () => {
    setShow(false)
  }


  const formatDate = (date) => {
    // Helper function to format date as 'YYYY-MM-DD'
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getPromotionList = () => {
    const today = formatDate(new Date());
    const startDate = dateData.startDate || today;
    const endDate = dateData.endDate || today;
    setLoading(true)
    simpleGetCallWithErrorResponse(ApiConfig.GET_PROMOTION_LIST + `?search=${searchValue}&start_date=${startDate}&end_date=${endDate}&platform=SMS&role=CRM Admin
      `
      //  `?start_date=2024-09-17&end_date=2024-10-29&platform=SMS&role=CRM Admin`

    )
      .then((res) => {
        setLoading(false)
        console.log(res.data);
        setPromotionList(res?.json?.data)

      })
      .catch((err) => {
        console.log(err);
      })
  }

  const getDetailsById = (id) => {
    // setLoading(true)
    simpleGetCallWithErrorResponse(ApiConfig.GET_PROMOTION_DETAILS_BY_ID + id)
      .then((res) => {
        // setLoading(false)
        setDetails(res.json.data)
console.log("Details>>>>>>",res)

      })
      .catch((err) => {
        console.log(err)
      })
  }




  useEffect(() => {
 
    getPromotionList()
  }, [dateData, searchValue,])

  useEffect(()=>{
    if(promotionId.current){
      getDetailsById(promotionId.current)
    }
  },[promotionId.current])

  const onCanclePromotion = () => {
    setLoading(true)
    postWithAuthCall(ApiConfig.CANCLE_PROMOTION + promotionId.current)
      .then((res) => {
        if (res?.success === true) {
          ToastMsg("success", res.message);
          setLoading(false)
          getPromotionList()
          handleClose()
        } else {
          ToastMsg("error", res.message);
          setLoading(false)
          handleClose()
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
  }


  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">WhatsApp Promotion</h6>
            <Link to="/CustomGroup/nll">
              <button className="custoBtnForCrmCall" >
                <a className=" textcolor" href="CreateToken">
                  Create Promotion
                </a>
              </button>
            </Link>
          </div>
          <div className="d-flex justify-content-between mainHeader custom-mb mt-4">
            <div className="role-table-header-main">
              <div className="search-input-wrapper mt-0 cus-mb">
                <input type="text"
                  placeholder="Search here"
                  onChange={(e) => setSearchValue(e.target.value)}
                />

                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="modalProp mb-4">

                <input
                  onChange={(e) => {
                    setDateData({
                      ...dateData,
                      startDate: e.target.value
                    });
                  }}
                  name="Date"
                  value={dateData.startDate}
                  type="date"
                  className="promotiondate"


                />

              </div>
              <div className="modalProp mb-4 ml-5">
                <input
                  onChange={(e) => {
                    setDateData({
                      ...dateData,
                      endDate: e.target.value
                    });
                  }}
                  name="Dates"
                  value={dateData.endDate}
                  type="date"
                  className="promotiondate"
                />
              </div>
            </div>
            <div className="d-flex justify-content-between mainIcons">
              <div className="booksIcon" style={{ textAlign: "center" }}>
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            </div>
          </div>

          <div>
            <div style={{}} className="row">
              {

                loading ? (
                  // <tr>
                  // <td colSpan="9" className="text-center">
                  <Loader />
                  //   </td>
                  // </tr>
                ) : (

                  promotionList?.length > 0 && promotionList ? (
                    // Array.from(dataMap.values())
                    promotionList.map((item) => (
                      <div key={item.id} className="col-lg-4 col-md-8 col-sm-12 mb-4 " >
                        <div className="phonebook-dash-item-two" >
                          <div>
                            <div className="d-flex justify-content-between mb-2" >
                              <h7 className="head">{item?.message_title}</h7>
                              <img src={info} alt="" onClick={()=>handleShow3(item?.id)}/>
                            </div>
                            <div
                              className="outerrowstyle"

                            >
                              <div className="mt-1">
                                <div className="innerrowstyle">
                                  <p className="schedule_Date">schedule_Date:</p>
                                  <p className="schedule_Date ml-1"> {formatDate(item?.message_scheduled_date)}</p>
                                </div>
                                <div className="innerrowstyle">
                                  <p className="schedule_Date">schedule_Time:</p>
                                  <p className="schedule_Date ml-1"> {item?.message_scheduled_time}</p>
                                </div>
                                <div className="innerrowstyle">
                                  <p className="schedule_Date">Status:</p>
                                  <p className="schedule_Date ml-1" style={{
                                    color: item?.message_status === "Pending" ? "orange" : item?.message_status === "Sent" ? "green" : item?.message_status === "Cancelled" ? "red" : ""
                                  }}> {item?.message_status}</p>
                                </div>
                              </div>

                              <img
                                style={{
                                  width: "50%",
                                  height: "50%",
                                  objectFit: "cover",

                                }}
                                src={item?.message_attachment_url ? item?.message_attachment_url : GroundAmbualnce}
                              />
                            </div>

                            <div className=" mt-1 mb-2 d-flex justify-content-between mainBtnsSub" style={{ height: "25px" }}>
                              {/* <p></p> */}
                              <button className="btn-width saveBtn m-1 "
                                onClick={() => handleEdit(item.id)}
                              >
                                Repeat
                              </button>
                              {
                                item?.message_status === "Pending" ? (
                                  <button className="btn-width saveBtn m-1  "
                                    style={{
                                      backgroundColor: "red"
                                    }}
                                    onClick={() => handleCancle(item.id)}
                                  >
                                    Cancel
                                  </button>
                                ) : ("")
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    ))

                  ) : (
                    <>
                      <NoData />

                    </>
                  )
                )
              }
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Details
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
        <div
                              className="outerrowstyle"

                            >
                              <div className="mt-1">
                                <div className="innerrowstyle mb-2">
                                  <h6 className="schedule_Date">schedule_Date:</h6>
                                  <h6 className="schedule_Date ml-1"> {formatDate(details?.message_scheduled_date)}</h6>
                                </div>
                                <div className="innerrowstyle mb-2">
                                  <h6 className="schedule_Date">schedule_Time:</h6>
                                  <h6 className="schedule_Date ml-1"> {details?.message_scheduled_time}</h6>
                                </div>
                                <div className="innerrowstyle mb-2">
                                  <h6 className="schedule_Date">Status:</h6>
                                  <h6 className="schedule_Date ml-1" style={{
                                    color: details?.message_status === "Pending" ? "orange" : details?.message_status === "Sent" ? "green" : details?.message_status === "Cancelled" ? "red" : ""
                                  }}> {details?.message_status}</h6>
                                </div>

                                <div className="innerrowstyle mb-2">
                                  <h6 className="schedule_Date">message:</h6>
                                  {/* <br/> */}
                                  <h6 className="schedule_Date ml-1"> {details?.message}</h6>
                                </div>
                              </div>

                              <img
                                style={{
                                  width: "50%",
                                  height: "50%",
                                  objectFit: "cover",

                                }}
                                src={details?.message_attachment_url ? details?.message_attachment_url : GroundAmbualnce}
                              />
                            </div>
        </Modal.Body>

       
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Cancle Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p className="text-center m-3"> Are You Sure, You Want To Cancle This WhatsApp Promotion? </p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={onCanclePromotion}
          >
            Confirm
          </button>
        </div>

      </Modal>
    </>
  );
};

export default WhatsappPromotion;
