// import React, { useState, useContext, useEffect } from "react";
// import { AppContext } from "../../context/AppContext";
// import searchicon from "../../assets/images/ic_search.svg";
// import ic_search_dark from "../../assets/images/ic_search_dark.svg";
// import { Link } from "react-router-dom";
// import Pagination from "../../sharedComponent/Pagination";
// import BookIcon from "../../assets/images/ic_excel.svg";

// const ZeroCallList = () => {
//   const { sidebar, setSidebar, Dark, setDark, userData } =
//     useContext(AppContext);

//   // show button
//   const [selectedRows, setSelectedRows] = useState(10);

//   const handleSelectChange = (event) => {
//     setSelectedRows(event.target.value);
//     console.log(`Selected number of rows: ${event.target.value}`);
//     // You can add additional logic to handle the row change
//   };
//   // show button end

//   const crmZeroCallListData = [
//     {
//       name: "shoeb",
//       type: "Inquiry",
//       //   mobileNumber: "",
//       email: "shoeb@cloudxperte.com	",
//       createDate: "10-Jun-2024",
//       gender: "None",
//       loation: "None",
//       //   viewDetails :""
//     },
//     {
//       name: "shoeb",
//       type: "Inquiry",
//       //   mobileNumber: "",
//       email: "shoeb@cloudxperte.com	",
//       createDate: "10-Jun-2024",
//       gender: "None",
//       loation: "None",
//       //   viewDetails :""
//     },
//     {
//       name: "shoeb",
//       type: "Inquiry",
//       //   mobileNumber: "",
//       email: "shoeb@cloudxperte.com	",
//       createDate: "10-Jun-2024",
//       gender: "None",
//       loation: "None",
//       //   viewDetails :""
//     },
//     {
//       name: "shoeb",
//       type: "Inquiry",
//       //   mobileNumber: "",
//       email: "shoeb@cloudxperte.com	",
//       createDate: "10-Jun-2024",
//       gender: "None",
//       loation: "None",
//       //   viewDetails :""
//     },
//     {
//       name: "shoeb",
//       type: "Inquiry",
//       //   mobileNumber: "",
//       email: "shoeb@cloudxperte.com	",
//       createDate: "10-Jun-2024",
//       gender: "None",
//       loation: "None",
//       //   viewDetails :""
//     },
//     {
//       name: "shoeb",
//       type: "Inquiry",
//       //   mobileNumber: "",
//       email: "shoeb@cloudxperte.com	",
//       createDate: "10-Jun-2024",
//       gender: "None",
//       loation: "None",
//       //   viewDetails :""
//     },
//     {
//       name: "shoeb",
//       type: "Inquiry",
//       //   mobileNumber: "",
//       email: "shoeb@cloudxperte.com	",
//       createDate: "10-Jun-2024",
//       gender: "None",
//       loation: "None",
//       //   viewDetails :""
//     },
//     {
//       name: "shoeb",
//       type: "Inquiry",
//       //   mobileNumber: "",
//       email: "shoeb@cloudxperte.com	",
//       createDate: "10-Jun-2024",
//       gender: "None",
//       loation: "None",
//       //   viewDetails :""
//     },
//     {
//       name: "shoeb",
//       type: "Inquiry",
//       //   mobileNumber: "",
//       email: "shoeb@cloudxperte.com	",
//       createDate: "10-Jun-2024",
//       gender: "None",
//       loation: "None",
//       //   viewDetails :""
//     },
//     {
//       name: "shoeb",
//       type: "Inquiry",
//       //   mobileNumber: "",
//       email: "shoeb@cloudxperte.com	",
//       createDate: "10-Jun-2024",
//       gender: "None",
//       loation: "None",
//       //   viewDetails :""
//     },
//   ];

//   return (
//     <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
//       <div className="role-content">
//         <div className="sub-header-role mb-4">
//           <h6 className="mt-2">Zero Call List</h6>
//         </div>
//         <div className="d-flex justify-content-between mainHeader custom-mb">
//           <div className="role-table-header-main">
//             <div className="search-input-wrapper mt-0 cus-mb">
//               <input type="text" placeholder="Search here" />

//               {Dark === "lightMode" ? (
//                 <img src={searchicon} alt="" />
//               ) : (
//                 <img src={ic_search_dark} alt="" />
//               )}
//             </div>
//             <div className="entries-wrapper mt-0 cus-mb">
//               <p>Entries per page</p>
//               <select
//                 className="form-select select-drop-icon"
//                 aria-label="Default select example"
//                 value={selectedRows}
//                 onChange={handleSelectChange}
//               >
//                 <option value={10}>10</option>
//                 <option value={25}>25</option>
//                 <option value={50}>50</option>
//                 <option value={100}>100</option>
//               </select>
//             </div>
//           </div>
//           <div className="d-flex justify-content-between mainIcons">
//             <div className="booksIcon" style={{ textAlign: "center" }}>
//               <img src={BookIcon} className="innerIconsBtn" alt="" />
//             </div>
//           </div>
//         </div>
//         <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
//           <table>
//             <thead>
//               <tr className="firstTabelRow">
//                 <th scope="col">NAME</th>
//                 <th scope="col">TYPE</th>
//                 <th scope="col">MOBILE NUMBER</th>
//                 <th scope="col">EMAIL</th>
//                 <th scope="col">CREATE DATE</th>
//                 <th scope="col">GENDER</th>
//                 <th scope="col">LOCATION</th>
//                 <th scope="col">VIEW DETAILS</th>
//               </tr>
//             </thead>
//             <tbody>
//               {crmZeroCallListData.map((data, index) => (
//                 <tr key={index} className="table-row-custom">
//                   <td>{data.name}</td>
//                   <td>{data.type}</td>
//                   <td>
//                     <button className="custoBtnForCrmCall">Make Call</button>
//                   </td>
//                   <td>{data.email}</td>
//                   <td>{data.createDate}</td>
//                   <td>{data.gender}</td>
//                   <td>{data.loation}</td>
//                   <td>
//                     <button className="custoBtnForCrmCall">
//                       <Link
//                         to="/UserDetailsForm"
//                         className="custoBtnForCrmCallLink"
//                       >
//                         Details
//                       </Link>
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <Pagination />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ZeroCallList;


import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import ReactPaginate from "react-paginate";
import { formatDate } from "../../common";

const ZeroCallList = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  // show button
  const [userList, setUserList] = useState([])
  const [filterSerchName, setFilterSerchName] = useState([])

  const [selectedRows, setSelectedRows] = useState(10);
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [previousPageUrl, setPreviousPageUrl] = useState(null);
  const [totalPages, setTotalPages] = useState(0);

  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();


  const startIndex = (currentPage - 1) * usersPerPage; // Adjust to zero-indexed pages
  const endIndex = startIndex + parseInt(usersPerPage);

  const filteredDataForCurrentPage = filterSerchName?.slice(0, usersPerPage);

  console.log("page count2>>", filteredDataForCurrentPage)

  const navigetAgentDedails = (id) => {
    navigate(`/UserDetailsForm/${id}`);
  }


  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const getUserListData = () => {
    setLoading(true)
    simpleGetCallWithToken(ApiConfig.GET_ZERO_CALL_DATA + `?page=${currentPage}`)
      .then((res) => {
        console.log("Call List Data>>>", res)
        setUserList(res?.results?.zero_call_list)
        setNextPageUrl(res?.next); // Set next page URL
        setPreviousPageUrl(res?.previous); // Set previous page URL
        setTotalPages(Math.ceil(res?.count / usersPerPage));
        setLoading(false)

      })
      .catch((err) => {
        setLoading(false)

        console.log("call List Data>>>", err)
      })
  }


  useEffect(() => {
    getUserListData()
    console.log("userData>>>>", userList)
  }, [currentPage])

  useEffect(() => {
    // Otherwise, use location_list

    const filteredData = userList?.filter((item) =>
      item?.name?.toLowerCase().includes(searchValue.toLowerCase())
    );

    console.log("Filtered Data>>", filteredData);
    setFilterSerchName(filteredData); // Set the filtered data in the state
    // setTotalPages(Math.ceil(filteredData.length / usersPerPage))

  }, [userList, searchValue,]);



  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Zero Call List</h6>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text"
                placeholder="Search here"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setCurrentPage(currentPage);
                }}

              />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setUsersPerPage(parseInt(e.target.value, 10)); // Update users per page
                  setCurrentPage(currentPage);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25" selected>
                  25
                </option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            {
              permissions && permissions['View Zero Call List Export'] &&
              <div className="booksIcon" style={{ textAlign: "center" }}>
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            }
          </div>
        </div>

        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">Sr.No</th>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Email</th>
                <th scope="col">Create Date</th>
                <th scope="col">Gender</th>
                <th scope="col">Location</th>
                <th scope="col">View Details</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <Loader />
                  </td>
                </tr>
              ) : (
                filteredDataForCurrentPage?.length > 0 &&
                filteredDataForCurrentPage?.map((data, index) => (
                  <tr key={index} className="table-row-custom">
                    <td>{index + 1}</td>
                    <td>{data.name}</td>
                    <td>{data.type}</td>
                    <td>
                      <button className="imporButton">
                        <Link to="/CRMCalls" className="details-link">
                          Make Call
                        </Link>
                      </button>
                    </td>
                    <td>{data.email}</td>
                    <td>{formatDate(data.created_at)}</td>
                    <td>
                      {data.gender
                        //  ? <gender controls></gender> : "Not Found"
                      }
                    </td>

                    <td>
                      {data?.location
                        // ? (
                        //   <location controls></location>
                        // ) : (
                        //   "Not Found"
                        // )
                      }
                    </td>
                    <td>
                      <button className="imporButton" onClick={() => navigetAgentDedails(data?.id)}>
                        <Link
                          // to="/UserDetailsForm"
                          className="details-link">
                          View Details
                        </Link>
                      </button>
                    </td>
                  </tr>)
                ))}
            </tbody>
          </table>
          <div
            className="row mt-7"
            style={{
              position: "absolute",
              bottom: "5px",
              right: "25px",
            }}
          >
            <div className="col-lg-12">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={totalPages} // Use totalPages for correct page count
                onPageChange={pageChangeHandler}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                forcePage={currentPage - 1} // Adjusted for zero-indexed pages
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ZeroCallList;
