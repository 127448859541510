import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import editicon from "../../assets/images/bx_edit.svg";
import building from "../../assets/images/building.svg";
import righticon from "../../assets/images/right.svg";
import { AppContext } from "../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import close from "../../assets/images/close.svg";
import branch from "../../assets/images/branch.svg";
import { Form } from "react-bootstrap";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";

function EntitiesList() {
  const { sidebar } = useContext(AppContext);

  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [entityList, setEntityList] = useState([])
  const [isApiLoad, setIsApiLoad] = useState(false)

  const entity = {
    "Id": 0,
    "ClientId": Number(id),
    "EntityName": "",
    "EntityType": "",
    "PAN": "",
    "IECCode": "",
    "Constitution": "",
    "LLP_CIN": "",
    "DOB": "",
    "AadharNumber": "",
    "UdyogAadhaar": "",
    "NatureofBusiness": "",
    "EfilingPassword": ""
  }

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState(entity)

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleEdit = () => setEdit(true);
  const handleClosed = () => {

    setEdit(false)
    setState(entity)
    setValidated(false)


  }
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);


  const getEntities = () => {

    setIsLoading(true)

    simpleGetCallWithErrorResponse(ApiConfig.GET_ENTITIES_LIST + id).then((res) => {

      if (res?.json?.success) {

        setEntityList(res?.json?.data)

      }

      setIsLoading(false)

    })

  }

  useEffect(() => {

    getEntities()

  }, [])


  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return
    }

    setIsApiLoad(true)
    const isUpdate = state.Id === 0 ? ApiConfig.CREATE_ENTITY : ApiConfig.UPDATE_ENTITY


    PostCallWithErrorResponse(isUpdate, JSON.stringify(state)).then((res) => {

      if (res?.json?.success) {
        ToastMsg("success", res?.json?.message)
        handleClosed()
        getEntities()
      } else {
        ToastMsg('error', res?.json?.message)
      }

      setIsApiLoad(false)

    })



  };


  const handleChange = (key, val) => {

    setState(prev => ({
      ...prev,
      [key]: val
    }))

  }

  const getSingleEnity = (id) => {

    simpleGetCallWithErrorResponse(ApiConfig.GET_SINGLE_ENTITY + id).then((res) => {

      if (res?.json?.success) {

        const data = res?.json?.data

        setState({
          "Id": Number(data.id),
          "ClientId": Number(data.clientId),
          "EntityName": data.entityName,
          "EntityType": data.entityType,
          "PAN": data.pan,
          "IECCode": data.iecCode,
          "Constitution": data.constitution,
          "LLP_CIN": data.llP_CIN,
          "DOB": data.dob,
          "AadharNumber": data.aadharNumber,
          "UdyogAadhaar": data.udyogAadhaar,
          "NatureofBusiness": data.natureofBusiness,
          "EfilingPassword": data.efilingPassword
        })

        setEdit(true)

      }

    })

  }

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div

      >
        <div className="role-content">
          <div className="sub-header-role userList33">
            <h6 className="mt-2 role-text">
              <span
                className="me-1 bread-cumb-color"
                onClick={() => navigate("/clientlist")}
              >
                Client
              </span>
              <span className="me-1 back-slash"> /</span>
              Entities{" "}
            </h6>
            <div>
              <button
                className="create-btn-sub-header"
                style={{ marginRight: "0" }}
                onClick={handleEdit}
              >
                Create
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder">
            <div className="role-table-header reponsivesrc">
              <div className="search-input-wrapper">
                <input type="text" placeholder="Search" />
                <img src={searchicon} alt="" />
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  class="form-select select-drop-icon remove-border"
                  aria-label="Default select example"
                >
                  <option selected>10</option>
                  <option value="1">20</option>
                  <option value="2">10</option>
                  <option value="3">5</option>
                </select>
              </div>
            </div>
            <div className="role-btns-wrapper">
              <button>
                <img src={importicon} alt="" />
              </button>
              <button>
                {" "}
                <img src={exporticon} alt="" />
              </button>
            </div>
          </div>

          <div className="table-wrapper">
            <table>
              <tr>
                <th>Sr. no</th>
                <th>Entity Name</th>
                <th>PAN</th>
                <th>IEC (Code)</th>
                <th>Constitution</th>
                <th>Company/LLP CIN</th>
                <th>DOB/ Incorporation</th>
                <th>Udyog Aadhaar</th>
                <th>Nature of Business</th>

                <th>Status</th>
                <th>Action</th>
              </tr>
              {isLoading ? <Loader /> : entityList.length === 0 ? <NoData /> : entityList.map((entity, index) => {
                return (
                  <tr className="table-row-custom">
                    <td>{index + 1}</td>
                    <td>{entity?.entityName}</td>
                    <td>{entity?.pan}</td>
                    <td>{entity?.iecCode}</td>

                    <td>{entity?.constitution}</td>
                    <td>{entity.llP_CIN}</td>
                    <td>{entity?.dob}</td>
                    <td>{entity?.udyogAadhaar}</td>
                    <td>{entity?.natureofBusiness}</td>

                    <td
                      className={
                        entity.isActive
                          ? "green-active"
                          : "red-deactive"
                      }
                    >
                      {entity?.isActive ? 'Active' : 'In-Active'}
                    </td>
                    <td>
                      <img
                        src={editicon}
                        alt=""
                        className="mx-3"
                        onClick={() => getSingleEnity(entity.id)}
                      />
                      <img
                        src={branch}
                        alt=""
                        onClick={() => navigate("/branch_list/" + entity.id)}
                      />
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>



          {/* <<<<<<...................modal edit starts here..........................>>>> */}
          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={edit}
            onHide={handleClosed}
            className="modal-xl"
          >
            <Modal.Header>
              <Modal.Title>
                <label htmlFor="" className="create-role-text">
                  {
                    state.Id !== 0 ? "Update Entity" :
                      "Add Entity"
                  }
                </label>
              </Modal.Title>
              <img src={close} alt="" onClick={handleClosed} />
            </Modal.Header>
            <div className="hr-line-modal">
              <hr style={{ margin: "0" }} />
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="text-grey">
                      Entity Name
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control tasKCategory1"
                      required
                      value={state.EntityName}
                      onChange={(e) => handleChange('EntityName', e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Entity Name.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-lg-3 position-relative">
                    <div className="d-flex justify-content-between align-center">
                      <label htmlFor="" className="text-grey">
                        PAN
                      </label>
                    </div>
                    <Form.Control
                      type="text"
                      className="form-control tasKCategory1"
                      required
                      value={state.PAN}
                      onChange={(e) => handleChange('PAN', e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide PAN No.
                    </Form.Control.Feedback>
                    {validated && (
                      <img
                        src={righticon}
                        alt=""
                        className="right-icon-img-modal"
                      />
                    )}
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="" className="text-grey">
                      IEC Code
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control tasKCategory1"
                      required
                      value={state.IECCode}
                      onChange={(e) => handleChange('IECCode', e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide IEC Code.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="" className="text-grey">
                      Constitution
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control tasKCategory1"
                      required
                      value={state.Constitution}
                      onChange={(e) => handleChange('Constitution', e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Constitution.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-lg-3 mt-4">
                    <label htmlFor="" className="text-grey">
                      Company/LLP CIN
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control tasKCategory1"
                      required
                      value={state.LLP_CIN}
                      onChange={(e) => handleChange('LLP_CIN', e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Company/LLP CIN.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-lg-3 mt-4">
                    <label htmlFor="" className="text-grey">
                      DOB/ Incorporation
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control tasKCategory1"
                      required
                      value={state.DOB}
                      onChange={(e) => handleChange('DOB', e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide DOB/ Incorporation.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-lg-3 mt-4">
                    <label htmlFor="" className="text-grey">
                      Aadhar Number
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control tasKCategory1"
                      required
                      value={state.AadharNumber}
                      onChange={(e) => handleChange('AadharNumber', e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Aadhar Number.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-lg-3 mt-4">
                    <label htmlFor="" className="text-grey">
                      Udyog Aadhar
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control tasKCategory1"
                      required
                      value={state.UdyogAadhaar}
                      onChange={(e) => handleChange('UdyogAadhaar', e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Udyog Aadhar.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-lg-3 mt-4">
                    <label htmlFor="" className="text-grey">
                      Nature of Business
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control tasKCategory1"
                      required
                      value={state.NatureofBusiness}
                      onChange={(e) => handleChange('NatureofBusiness', e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Nature of Business.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-lg-3 mt-4">
                    <label htmlFor="" className="text-grey">
                      E-filling Password
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control tasKCategory1"
                      required
                      value={state.EfilingPassword}
                      onChange={(e) => handleChange('EfilingPassword', e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide E-filling Password.
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="hr-line-modal-1">
                  <hr style={{ margin: "0" }} />
                </div>
                <Modal.Footer>
                  <div className="model-btn22">
                    <button className="cancel-btn" type="button" onClick={handleClosed}>
                      Cancel
                    </button>
                    <button className="modal-create-btn ml-2" type="submit"
                      disabled={isApiLoad}
                    >
                      Save
                    </button>
                  </div>
                </Modal.Footer>
              </Modal.Body>
            </Form>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default EntitiesList;
