import BookIcon from "../../assets/images/ic_excel.svg";
import Pagination from "../../sharedComponent/Pagination";
import React, { useContext, useEffect, useState } from "react";
import down_arrow from "../../assets/images/down_arrow.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Form, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

function AgentCallSummary() {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  const [key, setKey] = useState("all");
  console.log("Key data >>>>>>>>>>", key);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const crmCallData = [
    {
      mobileNumber: "+912653103942",
      callTime: "9:57 a.m.",
      status: "Not Answered",
    },
    {
      mobileNumber: "+918261986730",
      callTime: "9:34 a.m.",
      status: "Not Answered",
    },
    {
      mobileNumber: "+919527754660",
      callTime: "9:23 a.m.",
      status: "Answered",
    },
    {
      mobileNumber: "+919876543210",
      callTime: "10:15 a.m.",
      status: "Answered",
    },
    {
      mobileNumber: "+918765432109",
      callTime: "10:30 a.m.",
      status: "Not Answered",
    },
    {
      mobileNumber: "+917654321098",
      callTime: "10:45 a.m.",
      status: "Answered",
    },
    {
      mobileNumber: "+918261986730",
      callTime: "9:34 a.m.",
      status: "Not Answered",
    },
    {
      mobileNumber: "+919527754660",
      callTime: "9:23 a.m.",
      status: "Answered",
    },
    {
      mobileNumber: "+919876543210",
      callTime: "10:15 a.m.",
      status: "Answered",
    },
    {
      mobileNumber: "+918765432109",
      callTime: "10:30 a.m.",
      status: "Not Answered",
    },
    {
      mobileNumber: "+917654321098",
      callTime: "10:45 a.m.",
      status: "Answered",
    },
  ];

  const crmInboundCallsData = [
    {
      name: "None",
      type: "0",
      callDate: "call history",
    },
    {
      name: "None",
      type: "0",
      callDate: "call history",
    },
    {
      name: "None",
      type: "0",
      callDate: "call history",
    },
    {
      name: "None",
      type: "0",
      callDate: "call history",
    },
    {
      name: "None",
      type: "0",
      callDate: "call history",
    },
  ];

  const crmOutboundCallsData = [
    {
      name: "None",
      type: "0",
      callDate: "call history",
    },

    {
      name: "None",
      type: "0",
      callDate: "call history",
    },
    {
      name: "None",
      type: "0",
      callDate: "call history",
    },
    {
      name: "None",
      type: "0",
      callDate: "call history",
    },
    {
      name: "None",
      type: "0",
      callDate: "call history",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role">
          <h6 className="mt-2">Agent Call Summary</h6>
          <button className="custoBtnForCrmCall">
            <a className=" textcolor" href="AgentcallList">
              Add Agent Priority
            </a>
          </button>
        </div>

        <div className="d-flex justify-content-between mainHeader custom-mb mt-4">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text" placeholder="Search here" />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                value={selectedRows}
                onChange={handleSelectChange}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            {
              permissions && permissions['View Agent Call Summary Export'] &&

              <div className="booksIcon" style={{ textAlign: "center" }}>
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            }
          </div>
        </div>

        <Form noValidate id="crm-call-form">
          <div id="tabsBelow" className="mt-4">
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              // className="mb-0"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="all" title="All">
                <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                  <table>
                    <thead>
                      <tr className="firstTabelRow">
                        <th scope="col">Mobile Number</th>
                        <th scope="col">Call Time</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {crmCallData.map((data, index) => (
                        <tr key={index} className="table-row-custom">
                          <td>{data.mobileNumber}</td>
                          <td>{data.callTime}</td>
                          <td>{data.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination />
                </div>
              </Tab>
              <Tab eventKey="inboundCalls" title="Agent Week Call Summary">
                <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                  <table>
                    <thead>
                      <tr className="firstTabelRow">
                        <th scope="col">AGENT NAME</th>
                        <th scope="col">COUNTS</th>
                        <th scope="col">CALL DETAILS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {crmInboundCallsData.map((data, index) => (
                        <tr key={index} className="table-row-custom">
                          <td>{data.name}</td>
                          <td>{data.type}</td>
                          <td>
                            <Link to="/AgentCallSummaryDetails">
                              <button className="custoBtnForCrmCall">
                                {data.callDate}
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination />
                </div>
              </Tab>
              <Tab eventKey="outboundCalls" title="Outbound Calls">
                <Tab.Content>
                  <div
                    className="table-wrapper"
                    id="scrol-tblNtable-wrapper-af"
                  >
                    <table>
                      <thead>
                        <tr className="firstTabelRow">
                          <th scope="col">AGENT NAME</th>
                          <th scope="col">COUNTS</th>
                          <th scope="col">CALL DETAILS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {crmOutboundCallsData.map((data, index) => (
                          <tr key={index} className="table-row-custom">
                            <td>{data.name}</td>
                            <td>{data.type}</td>
                            <td>
                              <Link to="/AgentCallSummaryDetails">
                                <button className="custoBtnForCrmCall">
                                  {data.callDate}
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination />
                  </div>
                </Tab.Content>
              </Tab>
            </Tabs>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AgentCallSummary;
