import { useContext, useEffect, useState } from "react"
import Loader from "../loader"
import NoDataFound from "../NodataFound"
import { AppContext } from "../../../context/AppContext"
import { simpleGetCallWithErrorResponse } from "../../../api/ApiServices"
import ApiConfig from "../../../api/ApiConfig"

function UserFeedBack() {

    const { sidebar, selectedDomainId, domainName } = useContext(AppContext)

    const [questionList, setQuestionList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState("")


    const getQustionList = () => {

        setIsLoading(true)

        simpleGetCallWithErrorResponse(ApiConfig.GET_LIKE_UNLIKE_LIST + selectedDomainId + '&&question_likes_text=' + search).then((res) => {


            if (res?.json?.success) {

                setQuestionList(res?.json?.data)

            } else {

                setQuestionList([])
            }

            setIsLoading(false)


        })

    }

    useEffect(() => {
        getQustionList()
    }, [selectedDomainId])


    return (
        <>
            <div className="main_container">

                <div
                    className={sidebar ? "taskMain " : "cx-active taskMain"}
                    id="cx-main"
                >


                    <div >
                        <div className="role-content">
                            <div className="sub-header-role d-flex justify-content-between">
                                <h6 className="mt-2">User Feedback</h6>

                                <h6 className="mt-2 mr-2">Domain Name : {domainName}</h6>
                            </div>
                            <div className="input_wraper d-flex mt-4">
                                <input
                                    placeholder="Search By Question"
                                    type="text"
                                    className=""
                                    style={{ width: '250px', height: "38px" }}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <button className="AddQuestion_submit_btn mx-3"
                                    onClick={getQustionList}
                                >Search</button>
                            </div>
                            <div className="table-wrapper table-wrapper-2" style={{ height: "600px" }}>
                                {
                                    isLoading ? <Loader /> :
                                        questionList.length > 0 ?

                                            <table style={{ marginBottom: "20px" }}>
                                                <thead className="">
                                                    <tr className="firstTabelRow">
                                                        <th>Sr. no</th>
                                                        <th>Question</th>
                                                        <th>Answer</th>
                                                        <th>Likes</th>
                                                        <th>DisLikes</th>
                                                    </tr>
                                                </thead>
                                                {questionList.map((item, index) => (
                                                    <tr key={index} className="table-row-custom">
                                                        <td>{index + 1}</td>
                                                        <td className="fixed-width">{item?.question_likes_text}</td>
                                                        <td className="fixed-width">{item?.answer}</td>
                                                        <td>{item?.like_count} </td>
                                                        <td>{item?.unlike_count}</td>

                                                    </tr>
                                                ))}
                                            </table> : <NoDataFound />
                                }
                            </div>

                        </div>
                    </div>
                </div>

            </div >
        </>
    )

}

export default UserFeedBack