import { AppContext } from "../../../context/AppContext";
import React, { useEffect, useState, useRef } from "react";
import { useContext } from "react";
import Loader from "../loader";
import NoDataFound from "../NodataFound";
import { Link } from "react-router-dom";
import addedit from "../../../assets/images/Editac.svg";
import { Form, Modal } from "react-bootstrap";
import add from "../../../assets/images/check.svg";
import close from "../../../assets/images/close.svg";
import uploadicon from "../../../assets/images/plus_officecaller.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { simpleGetCallWithErrorResponse, simplePostCall, multipartPostCallWithErrorResponse, PostCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig, { BASE_URL_CHATADMIN } from "../../../api/ApiConfig";
import { ToastMsg } from "../../TostMsg";
import { theme } from "antd";

const DataStat = () => {
  const { sidebar, selectedDomainId } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [editline, seteditline] = useState(null);

  const [editId, setEditId] = useState('')

  const [showadd, setShowadd] = useState(false);
  const handleCloseadd = () => setShowadd(false);
  const handleShowadd = () => setShowadd(true);

  const [isAutoUpdate, setIsAutoUpdate] = useState(false)

  const [moduleList, setModuleList] = useState([]);

  const initialModule = {
    module_by_display_name: '',
    module_by_description: "",
    module_by_name: "",
    update_icon_file: '',
    module_by_icon: ''
  }

  const [editModule, setEditModule] = useState(initialModule)

  const ref = useRef(null)

  const handleUploadIcon = () => {
    ref.current.click()

  }

  const handleEditModule = (key, val) => {

    setEditModule(prev => ({
      ...prev,
      [key]: val
    }))

  }


  const getModuleList = () => {

    simpleGetCallWithErrorResponse(ApiConfig.MODULE_LIST + selectedDomainId).then((res) => {


      if (res.json.success) {

        setIsLoading(false)

        setModuleList(res.json.data)

      }

    })

  }

  const handleUpdate = () => {

    if (isAutoUpdate) return

    setIsAutoUpdate(true)

    console.log(editModule);

    editModule.domain_id = selectedDomainId

    const formdata = new FormData()

    formdata.append('module_by_display_name', editModule.module_by_display_name)

    formdata.append('module_by_name', editModule.module_by_name)

    formdata.append('domain_id', selectedDomainId)

    formdata.append('module_by_description', '')

    formdata.append('module_by_icon', editModule.module_by_icon)

    console.log(module.update_icon_file);

    formdata.append('update_icon_file', editModule.update_icon_file)

    console.log(formdata)



    multipartPostCallWithErrorResponse(ApiConfig.UPDATE_DISPLAY_NAME, formdata).then((res) => {

      console.log(res);

      if (res?.json?.success) {
        ToastMsg('success', res?.json?.message)
        getModuleList()
        setEditId('')
        setIsAutoUpdate(false)
      } else {

        setIsAutoUpdate(false)

        ToastMsg('error', res?.json.message)

      }

    })

  }


  useEffect(() => {


    getModuleList()


  }, [selectedDomainId])

  const updateModule = () => {

    setIsAutoUpdate(true)

    PostCallWithErrorResponse(ApiConfig.AUTO_UPDATE_MODULES).then((res) => {

      if (res?.json?.success) {

        setIsLoading(true)


        ToastMsg('success', res?.json?.message)

        getModuleList()

        setIsAutoUpdate(false)

      }

    })

  }


  return (
    <div className={sidebar ? "taskMain" : "cx-active taskMain"} id="cx-main">
      <div>
        <div className="role-content">
          <div className="sub-header-role d-flex justify-content-between">
            <h6 className="mt-2">Data Statistics</h6>
            <button className="addSetting_btn" onClick={updateModule}
              disabled={isAutoUpdate}
            >
              Update Modules
            </button>
          </div>
          <div
            className="table-wrapper table-wrapper-2"
            style={{ height: "600px" }}
          >
            {
              isLoading ? <Loader /> :
                moduleList.length > 0 ?
                  <table style={{ marginBottom: "20px" }}>
                    <thead className="">
                      <tr className="firstTabelRow">
                        <th>Sr. No</th>
                        <th>Module Name</th>
                        <th>Icons</th>
                        <th>Display Name</th>
                        <th>Total Intents</th>
                        <th>Total Questions</th>
                        <th>Publish Intents</th>
                        <th>Publish Questions</th>
                        <th>Non-Publish Intents</th>
                        <th>Non-Publish Questions</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {moduleList.map((module, index) => (
                      <tr key={index} className="table-row-custom">
                        <td>{index + 1}</td>
                        <td>
                          <Link to={"/submoddataStat/" + module.intent_part}>{module.intent_part}</Link>
                        </td>
                        <td>
                          {
                            editId == module.module_by_id ?

                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 100, hide: 300 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {
                                      module.module_by_icon ? "Change Icon" : "Add icon"
                                    }
                                  </Tooltip>
                                )}
                              >
                                <img src={editModule.update_icon_file ? URL.createObjectURL(editModule.update_icon_file) :
                                  module.module_by_icon ? BASE_URL_CHATADMIN + module.module_by_icon : uploadicon}
                                  style={{ width: '20px' }}
                                  onClick={handleUploadIcon}
                                  onError={(e) => e.target.src = uploadicon}
                                />
                              </OverlayTrigger> : <img src={

                                module.module_by_icon ? BASE_URL_CHATADMIN + module.module_by_icon : uploadicon}
                                style={{ width: '20px' }}
                                onError={(e) => e.target.src = uploadicon}
                              />
                          }
                          <div class="image-upload">
                            <input id="file-input" type="file" ref={ref}
                              onChange={(e) => handleEditModule('update_icon_file', e.target.files[0])}
                              accept="image/*"
                            />
                          </div>
                        </td>
                        <td>
                          {
                            editId == module.module_by_id ?
                              <input type="text" className="form-control"
                                value={editModule.module_by_display_name}
                                onChange={(e) => handleEditModule('module_by_display_name', e.target.value)}
                              /> : module.module_by_display_name ? module.module_by_display_name : module.intent_part
                          }
                        </td>
                        <td>{Number(module.publish_intent_count) + Number(module.unpublish_intent_count)}</td>
                        <td>{Number(module.publish_question_count) + Number(module.unpublish_question_count)}</td>
                        <td>{module.publish_intent_count}</td>
                        <td>{module.publish_question_count}</td>
                        <td>{module.unpublish_intent_count}</td>
                        <td>{module.unpublish_question_count}</td>
                        <td>
                          {
                            editId === module.module_by_id ?

                              <>
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 100, hide: 300 }}
                                  overlay={(props) => (
                                    <Tooltip id="button-tooltip" {...props}>
                                      Save
                                    </Tooltip>
                                  )}
                                >
                                  <img src={add}
                                    onClick={handleUpdate}
                                  />
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 100, hide: 300 }}
                                  overlay={(props) => (
                                    <Tooltip id="button-tooltip" {...props}>
                                      cancel
                                    </Tooltip>
                                  )}
                                >
                                  <img
                                    src={close}
                                    onClick={() => {
                                      setEditModule(initialModule)
                                      setEditId('')
                                    }}
                                  ></img>
                                </OverlayTrigger>
                              </> :
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 100, hide: 300 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    Edit
                                  </Tooltip>
                                )}
                              >
                                <img
                                  src={addedit}
                                  onClick={() => {

                                    setEditModule(prev => ({
                                      ...prev,
                                      module_by_display_name: module.module_by_display_name ? module.module_by_display_name : '',
                                      // update_icon_file: module.module_by_icon,
                                      module_by_name: module.intent_part,
                                      module_by_icon: module.module_by_icon
                                    }))

                                    setEditId(module.module_by_id)

                                  }}
                                />
                              </OverlayTrigger>
                          }
                        </td>
                      </tr>
                    ))}
                  </table>
                  : <NoDataFound />
            }
          </div>
        </div>
      </div>
      {/* <Modal
        className="delete-news-modal"
        show={show}
        onHide={handleClose}
        dialogClassName="modal-90w"
        centered
          >
               <Modal.Header closeButton>
          <Modal.Title className="delete-title">Edit</Modal.Title>
        </Modal.Header>
              <div className="lineSeperate"></div>
              <div className="p-3">

                  <Form.Group className="mb-3">
              <Form.Label>Display Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Display Name"
                autoFocus
              />
                  </Form.Group>
                  
                  <Form.Group className="mb-3">
              <Form.Label>Icon</Form.Label>
              <Form.Control
                type="file"
              />
            </Form.Group>
              </div>

              
             
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button 
            className="btn-width cancelBtn mx-3"
           onClick={handleClose}
          >
            Cancel
          </button>

          <button className="btn-width saveBtn">
            Edit
          </button>
        </div>
      </Modal> */}
      <Modal
        className="delete-news-modal"
        show={showadd}
        onHide={handleCloseadd}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">Add</Modal.Title>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <div className="p-3">
          <Form.Select className="mb-3">
            <option>Select Module</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>

          <Form.Group className="mb-3">
            <Form.Label>Display Name</Form.Label>
            <Form.Control type="text" placeholder="Display Name" autoFocus />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Icon</Form.Label>
            <Form.Control type="file" />
          </Form.Group>
        </div>

        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleCloseadd}>
            Cancel
          </button>

          <button className="btn-width saveBtn">Add</button>
        </div>
      </Modal>
    </div>
  );
};
export default DataStat;
