import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import Pagination from "../../sharedComponent/Pagination";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import { ToastMsg } from "../TostMsg";
import Modal from "react-bootstrap/Modal";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const HoldTask = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [holdListData, setHoldListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const adminId = localStorage.getItem("EmpId");
  const orgId = localStorage.getItem("OrgId");

  //Confirmation popup
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [holdProgress, setHoldProgress] = useState("");
  const [inProgress, setInProgress] = useState("");

  const [excelLoading, setExcelLoading] = useState(false);

  const getHoldTaskList = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.HOLD_TASK_LIST).then((res) => {
      console.log("HoldList>>>>",res)
      let data = res?.Data;
      if (res?.Success === true) {
        setLoading(false);
        setHoldListData(data);
      }
    });
  };

  const sendProgress = (data) => {
    let payload = {
      Taskid: data?.TaskId,
      statusid: data?.status,
    };
    PostCallWithErrorResponse(
      ApiConfig.HOLD_TASK_CLICK,
      JSON.stringify(payload)
    ).then((res) => {
      if (res) {
        ToastMsg("success", res.json.Message);
        getHoldTaskList();
      }
    });
  };

  const holdClick = (data) => {
    let payload = {
      Taskid: data?.TaskId,
      statusid: data?.status,
    };
    PostCallWithErrorResponse(
      ApiConfig.HOLD_TASK_CLICK,
      JSON.stringify(payload)
    ).then((res) => {
      if (res) {
        ToastMsg("success", res.json.Message);
        getHoldTaskList();
      }
    });
  };

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    simpleGetCallWithErrorResponse(ApiConfig.HOLD_TASK_DOWNLOAD_EXCEL)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getHoldTaskList();
  }, []);

  const [searchValue, setSearchValue] = useState("");
  console.log("searchValue,", searchValue);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);
  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);
  console.log("Numeric", endIndex);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    holdListData &&
    holdListData.length &&
    holdListData.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const displayAllocationList = (
    <table>
      <thead>
        <tr className="firstTabelRow">
          <th>Sr.No</th>
          <th>Allocated Name</th>
          <th>Project</th>
          <th>Workflow</th>
          <th>Task Name</th>
          <th>Activity</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {holdListData.slice(startIndex, endIndex).map((data, index) => {
          return (
            <tr className="table-row-custom">
              <td id="sr-no-padding">{startIndex + index + 1}</td>
              <td>{data.AllocatedName}</td>
              <td>{data.ProjectName}</td>
              <td>{data.Workflow}</td>
              <td className="break-ln-spaces">{data.TaskName}</td>
              <td className="break-ln-spaces">{data.TaskDescription}</td>
              <td>{data.StartDate}</td>
              <td>{data.EndDate}</td>
              {permissions && permissions["Hold And InProgress Task"] ? (
                data.status === 6 || data.status === 9 ? (
                  <td>
                    <button
                      className="btnSuccess"
                      onClick={() => {
                        handleShow3();
                        setHoldProgress({
                          ...data,
                          status_task: "Hold",
                        });
                        // onClick={() => { sendProgress(data)
                      }}
                    >
                      In Progress
                    </button>
                  </td>
                ) : (
                  <td>
                    <button
                      className="btnAbort"
                      onClick={() => {
                        handleShow3();
                        setHoldProgress({ ...data, status_task: "In Progress" });
                      }}
                      // onClick={() => holdClick(data)}
                    >
                      Hold
                    </button>
                  </td>
                )
              ) : null}
            </tr>
          );
        })}
      </tbody>
      {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
    </table>
  );

  const displaySearchAllocationList = (
    <table>
      <thead>
        <tr className="firstTabelRow">
          <th>Sr.No</th>
          <th>Allocated Name</th>
          <th>Project</th>
          <th>Workflow</th>
          <th>Task Name</th>
          <th>Activity</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {holdListData
          .filter((item) =>
            item.ProjectName.toLowerCase().includes(searchValue.toLowerCase())
          )
          .slice(startIndex, endIndex)
          .map((data, index) => {
            return (
              <tr className="table-row-custom">
                <td id="sr-no-padding">{startIndex + index + 1}</td>
                <td>{data.AllocatedName}</td>
                <td>{data.ProjectName}</td>
                <td>{data.Workflow}</td>
                <td className="break-ln-spaces">{data.TaskName}</td>
                <td className="break-ln-spaces">{data.TaskDescription}</td>

                <td>{data.StartDate}</td>
                <td>{data.EndDate}</td>
                {data.status === 6 || data.status === 9 ? (
                  <td>
                    <button
                      className="btnSuccess"
                      onClick={() => {
                        handleShow3();
                        setHoldProgress({
                          ...data,
                          status_task: "In Progress",
                        });
                        // onClick={() => { sendProgress(data)
                      }}
                    >
                      In Progress
                    </button>
                  </td>
                ) : (
                  <td>
                    <button
                      className="btnAbort"
                      onClick={() => {
                        handleShow3();
                        setHoldProgress({ ...data, status_task: "Hold" });
                      }}
                      // onClick={() => holdClick(data)}
                    >
                      Hold
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
      </tbody>
      {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
    </table>
  );

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Hold Task</h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search By ProjectName..."
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25" selected>
                    25
                  </option>
                </select>
              </div>
            </div>
            {permissions && permissions["Hold Task Export"] && (
              <div className="import-export-btn-wrapper">
                <button>
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={ic_excel}
                      onClick={(e) => downloadExcelFile(e)}
                      alt=""
                    />
                  )}
                </button>
              </div>
            )}
          </div>
          <div className="table-wrapper-main" id="table-wrapper-af">
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : holdListData && holdListData.length > 0 ? (
              <>
                {searchValue === ""
                  ? displayAllocationList
                  : displaySearchAllocationList}
              </>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        {/* {!loading && searchedDataPageCounts.length > usersPerPage && ( */}
        <Pagination
          data={holdListData}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
        {/* )} */}
      </div>

      {/* Confirmation popup Announcement */}

      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">Confirmation</Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Please confirm your action</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>

          <button
            className="btn-width saveBtn"
            onClick={() => {
              holdClick(holdProgress);
              handleClose3();
            }}
          >
            {holdProgress.status_task}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default HoldTask;
