import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";

import Pagination from "../../../src/sharedComponent/Pagination";
import { simpleGetCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
import Loader from '../Loader';
import NoData from '../../sharedComponent/NoData';
import { ToastMsg } from '../TostMsg';

function Subscription() {

  const { sidebar, userData } = useContext(AppContext);

  const [subscriptionList, setSubscriptionList] = useState([])
  const [isLoadin, setIsLoading] = useState(false)
  const [usersPerPage, setUserPerPage] = useState(25)
  const [currentPage, setCurrentPage] = useState(0)
  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + usersPerPage


  const getSubscriptionList = () => {

    setIsLoading(true)

    simpleGetCallWithErrorResponse(ApiConfig.GET_SUBSCRIPTIONS).then((res) => {

      if (res?.json?.success) {

        setSubscriptionList(res?.json?.data)

      }

      setIsLoading(false)

    })


  }

  const trigerSubs = (id) => {

    simpleGetCallWithErrorResponse(ApiConfig.SUBS_TRIGGER + `${id}/${userData?.UserId}`).then((res) => {


      if (res?.json?.success) {

        ToastMsg('success', res?.json?.message)

      } else {

        ToastMsg('error', res?.json?.message)

      }


    })

  }

  useEffect(() => {

    getSubscriptionList()

  }, [])


  return (
    <div className=''>

      <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">

        <div className="role-content">
          <div className="sub-header-role">
            <h6 className="mt-2 role-text">Subscription List</h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder">
            <div className="role-table-header reponsivesrc">
              <div className="search-input-wrapper">
                <input type="text" placeholder="Search" />
                <img src={searchicon} alt="" />
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  class="form-select select-drop-icon remove-border"
                  aria-label="Default select example"
                  value={usersPerPage}
                  onChange={(e) => setUserPerPage(e.target.value)}
                >
                  <option selected value="25">25</option>
                  <option value="20">20</option>
                  <option value="10">10</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="client-wrapper">
                <p>Client Name</p>
                <select
                  class="form-select select-drop-icon remove-border"
                  aria-label="Default select example"
                >
                  <option selected>John Wick</option>
                  <option value="1">John Carter</option>
                  <option value="2">Ethan Hunt</option>
                  <option value="3">Brad Pitt</option>
                </select>
              </div>
            </div>
            <div className="role-btns-wrapper servicemappingbtnexport">
              <button className="import-icon-img">
                <img src={importicon} alt="" />
              </button>
              <button>
                {" "}
                <img src={exporticon} alt="" />
              </button>
            </div>
          </div>
          <div className="table-wrapper">
            <table>
              <tr>
                <th>Sr. no</th>
                <th>Id</th>
                <th>Client Name</th>
                <th>Action</th>
              </tr>
              {isLoadin ? <Loader /> : subscriptionList.length === 0 ? <NoData /> : subscriptionList.slice(startIndex, endIndex).map((subs, index) => {
                return (
                  <tr key={"srnumber" + index} className="table-row-custom">
                    <td>{startIndex + index + 1}</td>
                    <td>{subs?.id}</td>
                    <td>{subs?.name}</td>
                    <td>Trigger</td>
                    {/* <td
                      className={
                        srnumber.status === "Active"
                          ? "green-active"
                          : "red-deactive"
                      }
                    >
                      {srnumber.status}
                    </td> */}
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12">
            <Pagination
              data={subscriptionList && subscriptionList?.length > 0 && subscriptionList}
              currentPage={currentPage}
              usersPerPage={usersPerPage}
              pageChangeHandler={(selected) => {
                setCurrentPage(selected.selected)
              }}
              searchValue={''}

            />
          </div>
        </div>
      </div>
    </div>

  )
}

export default Subscription;