import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import AddLogo from "../../assets/images/addBtn.svg";
import { Modal } from "react-bootstrap";
import Export from "../../assets/images/exports.svg";
import Frame from "../../assets/images/img_vector.svg";
import member_img from "../../assets/images/member_img.svg";
import RB from "../../assets/images/custom-rb-logo.png";
import Test from "../../assets/images/testrevel.png";
import cloude from "../../assets/images/cloude.svg";
import { Theme } from "@fullcalendar/react";
import { Tree, TreeNode } from "react-organizational-chart";
import ApiConfig from "../../api/ApiConfig";
import {
  simpleGetCall,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";

const TeamChart = () => {
  const [pune, setPune] = useState(false);
  const [awb, setAwb] = useState(false);
  const [triv, setTriv] = useState(false);
  const [main, setMain] = useState(true);

  const changepune = () => {
    if (pune == false) {
      setPune(true);
      setAwb(false);
      setTriv(false);
      setMain(false);
    } else {
      setPune(false);
      setAwb(false);
      setTriv(false);
      setMain(true);
    }
  };
  const changeawb = () => {
    if (awb == false) {
      setPune(false);
      setAwb(true);
      setTriv(false);
      setMain(false);
    } else {
      setPune(false);
      setAwb(false);
      setTriv(false);
      setMain(true);
    }
  };
  const changetriv = () => {
    if (triv == false) {
      setPune(false);
      setAwb(false);
      setTriv(true);
      setMain(false);
    } else {
      setPune(false);
      setAwb(false);
      setTriv(false);
      setMain(true);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  let { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);

  const [chart, setChart] = useState([]);
  const [orgName, setOrgName] = useState('');

  useEffect(() => {
    simpleGetCallWithErrorResponse(ApiConfig.CHART)
      .then((res) => {
        console.log(res?.json);
        setChart(res?.json?.data?.Departments || []);
        setOrgName(res?.json?.data || "");
        console.log(chart);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [selectNode, setSelectNode] = useState(0);
  const handleClickNode = (nodeID) => {
    setSelectNode(nodeID);
  }

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content-main">
          <div className="sub-header-role mb-4">
            <div>
              <h6 className="mt-2">Team Chart</h6>
            </div>
            <div>
              {/* <button
                className="create-btn-sub-header"
                onClick={() => setShow(!show)}
              >
                <img
                  src={AddLogo}
                  width="10"
                  height="10"
                  className="mx-1"
                  alt=""
                />
                Add Member
              </button> */}
            </div>
          </div>
         

          {/* Flow Chart Content */}
          {/* <div className="chatover">
            <div className="TeamChartWrpa">
              <div className="main-chat-wrpa">
                <div className="middle-height"></div>
                <div className="main-box-wrap">
                  <img src={RB} alt="" />
                  <p className="box-main-heading">Director's Name</p>
                  <p className="box-sub-heading">Director</p>
                </div>
              </div>
              <div className="line-chart"></div>
              <div className="chart-contain-wrap">
                <div className="level-3">
                  <div className="line-contain"></div>
                  <div
                    className="main-chat-wrpa-contain"
                    onClick={() => changepune()}
                  >
                    {pune == false ? (
                      <div className="main-box-wrap unactive-office">
                        <p className="box-main-heading mb-2">Pune</p>
                        <img src={Test} alt="" />
                        <p className="box-sub-heading">Name</p>
                        <p className="box-sub-heading">Branch Head</p>
                      </div>
                    ) : (
                      <div className="active-main">
                        <div className="main-box-wrap acive-office">
                          <p className="box-main-heading mb-2">Pune</p>
                          <img src={Test} alt="" />
                          <p className="box-sub-heading">Name</p>
                          <p className="box-sub-heading">Branch Head</p>
                        </div>
                        <div className="active-chain"></div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="level-3">
                  <div className="line-contain"></div>
                  <div
                    className="main-chat-wrpa-contain"
                    onClick={() => changeawb()}
                  >
                    {awb == false ? (
                      <div className="main-box-wrap unactive-office">
                        <p className="box-main-heading mb-2">Aurangabad</p>
                        <img src={cloude} alt="" />
                        <p className="box-sub-heading">Name</p>
                        <p className="box-sub-heading">Branch Head</p>
                      </div>
                    ) : (
                      <div className="active-main">
                        <div className="main-box-wrap acive-office">
                          <p className="box-main-heading mb-2">Aurangabad</p>
                          <img src={cloude} alt="" />
                          <p className="box-sub-heading">Name</p>
                          <p className="box-sub-heading">Branch Head</p>
                        </div>
                        <div className="active-chain"></div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="level-3">
                  <div className="line-contain"></div>
                  <div
                    className="main-chat-wrpa-contain "
                    onClick={() => changetriv()}
                  >
                    {triv == false ? (
                      <div className="main-box-wrap unactive-office">
                        <p className="box-main-heading mb-2">Trivandram</p>
                        <img src={Test} alt="" />
                        <p className="box-sub-heading">Name</p>
                        <p className="box-sub-heading">Branch Head</p>
                      </div>
                    ) : (
                      <div className="active-main">
                        <div className="main-box-wrap acive-office">
                          <p className="box-main-heading mb-2">Trivandram</p>
                          <img src={Test} alt="" />
                          <p className="box-sub-heading">Name</p>
                          <p className="box-sub-heading">Branch Head</p>
                        </div>
                        <div className="active-chain"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {main == true ? (
                <div className="chart-contain-wrap">
                  <div className="level-3">
                    <div className="line-contain"></div>
                    <div className="main-chat-wrpa-contain inner-conain-main">
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">HR</p>
                      </div>
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">IOS</p>
                      </div>
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">Graphic Design</p>
                      </div>
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">Js</p>
                      </div>
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">Back End</p>
                      </div>
                    </div>
                  </div>
                  <div className="level-3">
                    <div className="line-contain"></div>
                    <div className="main-chat-wrpa-contain inner-conain-main">
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">HR</p>
                      </div>
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">IOS</p>
                      </div>
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">Graphic Design</p>
                      </div>
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">Js</p>
                      </div>
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">Back End</p>
                      </div>
                    </div>
                  </div>
                  <div className="level-3">
                    <div className="line-contain"></div>
                    <div className="main-chat-wrpa-contain inner-conain-main">
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">HR</p>
                      </div>
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">IOS</p>
                      </div>
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">Graphic Design</p>
                      </div>
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">Js</p>
                      </div>
                      <div className="main-box-wrap">
                        <p className="box-sub-heading">Back End</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {pune == true ? (
                <div className="main-inner-wrap">
                  <div className="inner-chart inner-chart-2 "></div>
                  <div className="inner-chart-contain ">
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-sub-heading">HR Team</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-sub-heading">IOS Team</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-sub-heading">
                              Graphic Design Team
                            </p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-sub-heading">Js Team</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-sub-heading">Back End Team</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {awb == true ? (
                <div className="main-inner-wrap">
                  <div className="inner-chart inner-chart-3"></div>
                  <div className="inner-chart-contain ">
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-sub-heading">HR Team</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-sub-heading">Asp .Net Team</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-sub-heading">React Js</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-sub-heading">Angular Team</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-sub-heading">PHP Team</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-main-heading">
                              Degital Marketing
                            </p>
                            <p className="box-sub-heading">Team</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {triv === true ? (
                <div className="main-inner-wrap">
                  <div className="inner-chart inner-chart-4"></div>
                  <div className="inner-chart-contain ">
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-sub-heading">HR Team</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-sub-heading">React Team</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="level-3">
                      <div className="main-chat-wrpa-contain-inner inner-conain-main">
                        <div className="line-contain-inner"></div>
                        <div className="inner-contain-main-wrpa">
                          <div className="main-box-wrap">
                            <p className="box-sub-heading">Node JS Team</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                          <div className="main-box-wrap">
                            <p className="box-main-heading">Name</p>
                            <p className="box-sub-heading">Designation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div> */}
           <Tree
              lineWidth={"2px"}
              lineColor={"green"}
              lineBorderRadius={"10px"}
              label={
                <div
                  className="mx-auto rounded-3"
                  style={{
                    backgroundColor: "white",
                    width: "20%",
                  }}
                >
                  {/* <img src={RB} alt="" /> */}
                  {/* <p className="box-main-heading">Sameer Kutty</p> */}
                  <p className="box-sub-heading">{orgName.Name}</p>
                  {/* <p className="box-sub-heading">{item?.Name}</p> */}
                </div>
              }
            >
              {chart.map((item, index) => {
                return (
                  <TreeNode key={item?.DepartmentId}
                    label={
                      <div key={item?.DepartmentId}
                        className="p-2 rounded-3 text-nowrap"
                        style={{
                          backgroundColor: "white",
                          width: "auto",
                        }}
                        onClick={()=>handleClickNode(item?.DepartmentId)}
                      >
                        <p className="box-main-heading mb-2">{item.Name}</p>
                      </div>
                    }
                    
                  >
                    {
                      item?.DepartmentId === selectNode && (
                        <>{item.Teams.length != 0 ? item.Teams.map((team, index) => (
                          <TreeNode
                            label={<div className={`p-2 rounded-3 text-nowrap`} style={{
                              backgroundColor: "white",
                              width: "auto",
                            }}>{ team.Name}</div>}
                          >
                            {
                              team.Employees.map((employee, index) => (
                              
                                  <TreeNode
                                   label={<div className="p-2 rounded-3 text-nowrap " style={{
                                    backgroundColor: "white",
                                    width: "auto",
                                  }}>{employee.Name}</div>}
                                  ></TreeNode>
                              
                              ))
                            }
                          </TreeNode>
                        )) : <></>}</>
                      )
                    }
                    
                  </TreeNode>
                );
              })}
            </Tree>
 {/* <div>
            {chart.map((item, index) => {
              return <div>{item?.Name}</div>;
            })}
          </div> */}
          <div>
            {/* <Tree
              lineWidth={"2px"}
              lineColor={"green"}
              lineBorderRadius={"10px"}
              label={ <div className="main-box-wrap">
                <img src={RB} alt="" />
                <p className="box-main-heading">Director's Name</p>
                <p className="box-sub-heading">Director</p>
              </div>}
            >
              <TreeNode label={ <div className="main-box-wrap unactive-office">
                        <p className="box-main-heading mb-2">Pune</p>
                        <img src={Test} alt="" />
                        <p className="box-sub-heading">Name</p>
                        <p className="box-sub-heading">Branch Head</p>
                      </div>}>
                <TreeNode label={<div>Grand Child</div>} />
              </TreeNode>
              <TreeNode label={<div>Child 2</div>}>
                <TreeNode label={<div>Grand Child</div>}>
                  <TreeNode
                    label={<div>Great Grand Child 1</div>}
                  />
                  <TreeNode
                    label={<div>Great Grand Child 2</div>}
                  />
                </TreeNode>
              </TreeNode>
              <TreeNode label={<div>Child 3</div>}>
                <TreeNode label={<div>Grand Child 1</div>} />
                <TreeNode label={<div>Grand Child 2</div>} />
              </TreeNode>
            </Tree> */}
          </div>
        </div>
      </div>

      {/* Modal For Add User */}
      <Modal show={show} onHide={() => setShow(false)} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Member</Modal.Title>
        </Modal.Header>
        <div className="lineSeperate"></div>

        <Modal.Body>
          <div className="innerData">
            <div className="row">
              <div className="mainContentmodel">
                <div className="row add-task-modal-inputs">
                  <div className="col-lg-3">
                    <label htmlFor="" className="mb-2 text-grey">
                      Branch
                    </label>
                    <select
                      className="form-select tasKCategory1 mb-2"
                      aria-label="Default select example"
                    >
                      <option value="1">Pune</option>
                      <option value="2">Mumbai</option>
                      <option value="3">Hydrabad</option>
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="" className="mb-2 text-grey">
                      Employee Name
                    </label>
                    <select
                      className="form-select tasKCategory1 mb-2"
                      aria-label="Default select example"
                    >
                      <option value="" disabled selected>
                        Select your option
                      </option>
                      <option value="1">Employee 1</option>
                      <option value="2">Employee 2</option>
                      <option value="3">Employee 3</option>
                    </select>
                  </div>
                  <div className="col-lg-3 smallInpt">
                    <label htmlFor="" className="mb-2 text-grey">
                      Team
                    </label>
                    <select
                      className="form-select tasKCategory1 mb-2"
                      aria-label="Default select example"
                    >
                      <option selected>Team Name</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div className="col-lg-3 smallInpt">
                    <label htmlFor="" className="mb-2 text-grey">
                      Designation
                    </label>
                    <select
                      className="form-select tasKCategory1 mb-2"
                      aria-label="Default select example"
                    >
                      <option selected>Graphic Designer</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div className="col-lg-6 smallInpt">
                    <label htmlFor="" className="mb-2 text-grey">
                      Upload Photo
                    </label>
                    <input
                      type="file"
                      name="photo"
                      className="form-control tasKCategoryFile mb-2"
                      placeholder="Browse file..."
                    />

                    {/* <img src={Export} alt="" className="exportImg" /> */}
                  </div>
                  <div class="col-lg-6 d-flex justify-content-between mb-3">
                    <label class="mb-2 text-grey">Preview</label>
                    {/* <textarea
                      name=""
                      id=""
                      cols="0"
                      rows="3"
                      class="form-control tasKCategory3"
                    > */}
                    <img src={member_img} alt="" className="preview-img" />
                    {/* </textarea> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn-width saveBtn">Submit</button>
        </div>
      </Modal>
    </>
  );
};

export default TeamChart;
