import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../context/AppContext";
import close from "../../assets/images/close.svg";

function CreateQuote() {
    const navigate = useNavigate();
    const { sidebar } = useContext(AppContext);


    return (
        <div className=''>

        <div className={sidebar ? "taskMain " : "cx-active taskMain"}  id="cx-main">
            <div className="sub-header-role">
                <h6 className="mt-2 role-text">Quotation</h6>

            </div>
            <div className='bg-white p-3 rounded-3 mt-4'>
                <div className=''>

                    <label htmlFor="" className="role-text">
                        Add Quotation
                    </label>

                    {/* <img src={close} alt=""  /> */}
                </div>

                <div className="row  mt-4">
                    <div className="col-lg-6 mb-4">
                        <label htmlFor="" className="text-grey">
                            Name
                        </label>
                        <input type="text" className="form-control tasKCategory1" placeholder="Name" />
                    </div>
                    <div className="col-lg-6 mb-4">
                        <label htmlFor="" className="text-grey">
                            Consultant Name
                        </label>
                        <select
                            class="form-select tasKCategory1"
                            aria-label="Default select example"
                        >
                            <option selected>select</option>
                            <option value="1">Consultant Name-1</option>
                            <option value="2">Consultant Name-2</option>
                            <option value="2">Consultant Name-3</option>
                        </select>
                    </div>
                    <div className="col-lg-4 mb-4 d-flex align-items-center">
                        <label htmlFor="" className="text-grey me-5">
                            Is GST Applicable ?
                        </label>
                        <input type="checkbox" className='' />
                    </div>
                    <div className="col-lg-4 mb-4  d-flex align-items-center">
                        <label htmlFor="" className="text-grey me-5">
                            Is TDS Applicable ?
                        </label>
                        <input type="checkbox" />
                    </div>
                    <div className="col-lg-4 mb-4 position-relative ">
                        <label htmlFor="" className="text-grey">
                            GST Rate
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                    </div>
                    <div className="col-lg-4 mb-4">
                        <label htmlFor="" className="text-grey">
                            TDS Section
                        </label>
                        <select
                            class="form-select tasKCategory1"
                            aria-label="Default select example"
                        >
                            <option selected>select</option>
                            <option value="1">TDS Section-1</option>
                            <option value="2">TDS Section-2</option>
                            <option value="2">TDS Section-3</option>
                        </select>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <label htmlFor="" className="text-grey">
                            TDS Amount
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                    </div>
                    <div className="col-lg-4 mb-4">
                        <label htmlFor="" className="text-grey">
                            TDS Percentage
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                    </div>
                    <div className="col-lg-12 mb-4 text-end">
                        <button className="modal-create-btn " onClick={() => navigate("")}>Add Subscription</button>
                    </div>
                </div>

                <div className='mt-4'>

                    <label htmlFor="" className="create-role-text">
                        Subscription 1
                    </label>

                    {/* <img src={close} alt=""  /> */}
                </div>
                <div className="row create-workflow-topbody pb-4 ">
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                            Service ID
                        </label>
                        {/* <input type="text" className="date-label-input" placeholder="07:00 pm" /> */}
                        <select
                            class="form-select tasKCategory1"
                            aria-label="Default select example"
                        >
                            <option selected>select</option>
                            <option value="1">Service ID-1</option>
                            <option value="2">Service ID-2</option>
                            <option value="2">Service ID-3</option>
                        </select>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                            Financial Year
                        </label>
                        {/* <input type="text" className="date-label-input" placeholder="07:00 pm" /> */}
                        <select
                            class="form-select tasKCategory1"
                            aria-label="Default select example"
                        >
                            <option selected>select</option>
                            <option value="1">Financial Year-1</option>
                            <option value="2">Financial Year-2</option>
                            <option value="2">Financial Year-3</option>
                        </select>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                            Subscription Name
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                            Consultant ID
                        </label>
                        {/* <input type="text" className="date-label-input" placeholder="07:00 pm" /> */}
                        <select
                            class="form-select tasKCategory1"
                            aria-label="Default select example"
                        >
                            <option selected>select</option>
                            <option value="1">Consultant ID-1</option>
                            <option value="2">Consultant ID-2</option>
                            <option value="2">Consultant ID-3</option>
                        </select>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                            Branch ID
                        </label>
                        {/* <input type="text" className="date-label-input" placeholder="07:00 pm" /> */}
                        <select
                            class="form-select tasKCategory1"
                            aria-label="Default select example"
                        >
                            <option selected>select</option>
                            <option value="1">Branch ID-1</option>
                            <option value="2">Branch ID-2</option>
                            <option value="2">Branch ID-3</option>
                        </select>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                            Period's
                        </label>
                        {/* <input type="text" className="date-label-input" placeholder="07:00 pm" /> */}
                        <select
                            class="form-select tasKCategory1"
                            aria-label="Default select example"
                        >
                            <option selected></option>
                            <option value="1">Period's-1</option>
                            <option value="2">Period's-2</option>
                            <option value="2">Period's-3</option>
                        </select>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                            Chart of Fees ₹
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                            Latest Avialiable Invoice ₹
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                            Latest Avialiable Quotation ₹
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                            Negotiated Amount ₹
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="" className="text-grey">
                            Advance Amount
                        </label>
                        <input type="text" className="form-control tasKCategory1" />
                    </div>
                    <div className='text-end'>
                        <button className="modal-cancel-btn" >
                            Close
                        </button>
                        <button className="modal-create-btn ">Submit</button>
                    </div>
                </div>
            </div>



        </div>
        </div>

    )
}

export default CreateQuote