import React, { useContext, useState, useEffect, Children } from "react";
import { AppContext } from "../../context/AppContext";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "../../assets/css/main.scss";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { json } from "react-router-dom";
import { Modal } from "react-bootstrap";

const CalendarBox = () => {
  const localizer = momentLocalizer(moment);
  const { userData, permissions } =
    useContext(AppContext);

  const [dailyEvents, setDailyEvents] = useState([]);

  const [events, setEvents] = useState({
    EmployeeAttendance: [],
    ApprovedLeave: [],
    Holiday: [],
    LeaveApplied: [],
    ProjectEndDate: [],
    ProjectStart: []
  });

  useEffect(() => {
    getHystory()
  }, []);

  const getHystory = (month, year) => {
    PostCallWithErrorResponse(
      ApiConfig.TIME_CALENDER_HISTORY,
      JSON.stringify({
        EmpId: Number(userData.EmpId),
        OrgId: Number(userData.OrgId),
        year: year ? year : new Date().getFullYear(),
        Month: month ? month : new Date().getMonth() + 1,
      })
    )
      .then((res) => {
        let data = res.json.Data
        setEvents({
          EmployeeAttendance: data.EmployeeAttendance,
          ApprovedLeave: data.ApprovedLeave,
          LeaveApplied: data.LeaveApplied,
          ProjectEndDate: data.ProjectEndDate,
          ProjectStart: data.ProjectStart,
          Holiday: data.Holiday,
        })
        let employeeAttendance = data.EmployeeAttendance && data.EmployeeAttendance.map((item) => {
          return {
            start: new Date(item.InTime?.replace("T", " ")),
            end: new Date(item.OutTime?.replace("T", " ")),
            title: "Punch In",
            description: "Employee Attendance",
            data: {
              type: "attendance"
            },
          }
        })

        setDailyEvents(employeeAttendance)

        // let approveLeave = data.ApprovedLeave.map((item) => {
        //   return {
        //     start: moment(item.LeaveApplicationDate).toDate(),
        //     end: moment(item.LeaveApplicationDate).toDate(),
        //     title: "Leave Approve",
        //   }
        // })

        // let leaveApplied = data.EmployeeAttendance.map((item) => {
        //   return {
        //     start: moment(item.LeaveApplicationDate).toDate(),
        //     end: moment(item.LeaveApplicationDate).toDate(),
        //     title: "Leave Applied",
        //   }
        // })

        // let startProject = data.EmployeeAttendance.map((item) => {
        //   return {
        //     start: moment(item.FromDate).toDate(),
        //     end: moment(item.FromDate).toDate(),
        //     title: "Project start",
        //   }
        // })

        // let endProject = data.EmployeeAttendance.map((item) => {
        //   return {
        //     start: moment(item.ToDate).toDate(),
        //     end: moment(item.ToDate).toDate(),
        //     title: "Project End",
        //   }
        // })

        // let Holiday = data.EmployeeAttendance.map((item) => {
        //   return {
        //     start: moment(item.InTime).toDate(),
        //     end: moment(item.InTime).toDate(),
        //     title: "Project End",
        //   }
        // })

      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getDate(dayString) {
    const today = new Date();
    const year = today.getFullYear().toString();
    let month = (today.getMonth() + 1).toString();

    if (month.length === 1) {
      month = "0" + month;
    }

    return dayString.replace("YEAR", year).replace("MONTH", month);
  }

  const ColoredDateCellWrapper = (event) => {
    let status = getStatus(event.value)
    return React.cloneElement(Children.only(event.children), {
      style: {
        ...event.children.style,
        backgroundColor:
          status === "weekOff" ? "#eef8fd" : "",
      },
    });
    // status?.status === "holiday" ? "#ff0000" :
  }

  const MyEventWrapper = (event) => {

    return React.cloneElement(Children.only(event.children), {
      style: {
        ...event.children.style,
        backgroundColor: "#eef8fd",
      },
    });
    // status?.status === "holiday" ? "#ff0000" :
  }

  const approvedLeaveCase = (approvel, date) => {
    switch (approvel) {
      case "P":
        return date;
      case "A":
        return date;
      case "R":
        return date;
      default:
        return null;

    }
  }
  const getStatus = (date) => {
    let givenDate = date
    var workingHours = null
    let day = givenDate?.getDay();
    let weekOff = day === 6 || day === 0 ? true : false

    let attendance = events.EmployeeAttendance.filter((event) => {
      let startDate = event.InTime && new Date(event.InTime.replace("T", " "))?.setHours(0, 0, 0)
      return startDate === givenDate?.setHours(0, 0, 0)
    })

    let filterItem = attendance[0]
    // var diff = filterItem && filterItem.InTimle && filterItem.OutTime && 
    // new Date(filterItem.OutTime.replace("T", " ")) - new Date(filterItem.InTime.replace("T", " "));
    // var hours = Math.floor(diff / 1000 / 60 / 60);
    // diff -= hours * 1000 * 60 * 60;
    // var minutes = Math.floor(diff / 1000 / 60);

    var dayTotal = filterItem && filterItem.DayTotal
    let hours1 = dayTotal && Number(dayTotal.toString().split(".")[0]) ? Number(dayTotal.toString()?.split(".")[0]) : 0
    let minutes1 = dayTotal && Number(dayTotal.toString().split(".")[1]) ? Number(dayTotal.toString()?.split(".")[1]) : 0
      ? Number(dayTotal.toString().split(".")[1]) : "0"

    workingHours = `${hours1 < 10 ? 0 : ""}${hours1}:${minutes1 < 10 ? 0 : ""}${minutes1}`
    let present = attendance.length

    let ApprovedLeave = events.ApprovedLeave.filter((event) => {
      let startDate = new Date(event.LeaveFrom.split("T")[0])?.setHours(0, 0, 0)
      let endDate = new Date(event.LeaveTo.split("T")[0])?.setHours(0, 0, 0)
      return startDate <= givenDate && endDate >= givenDate
    })
    let AppovedLeave = ApprovedLeave.length ? true : false;

    let LeaveApplied = events.LeaveApplied.filter((event) => {
      let startDate = new Date(event.LeaveFrom.split("T")[0])?.setHours(0, 0, 0)
      let endDate = new Date(event.LeaveTo.split("T")[0])?.setHours(0, 0, 0)
      return startDate <= givenDate && endDate >= givenDate
    })
    let ApliedforLeave = LeaveApplied.length ? true : false;

    let projectStart = events.ProjectStart.filter((event) => {
      let startDate = new Date(event.FromDate.split("T")[0])?.setHours(0, 0, 0)
      let endDate = new Date(event.FromDate.split("T")[0])?.setHours(0, 0, 0)
      return startDate <= givenDate && endDate >= givenDate
    })

    let projectEnd = events.ProjectStart.filter((event) => {
      let startDate = new Date(event.ToDate.split("T")[0])?.setHours(0, 0, 0)
      let endDate = new Date(event.ToDate.split("T")[0])?.setHours(0, 0, 0)
      return startDate <= givenDate && endDate >= givenDate
    })

    let holiday = events.Holiday.filter((event) => {
      let startDate = new Date(event.Date)?.setHours(0, 0, 0)
      let endDate = new Date(event.Date)?.setHours(0, 0, 0)
      return startDate <= givenDate && endDate >= givenDate
    })

    const isHoliday = holiday.length > 0 ? true : false;

    return present || projectStart.length > 0 || projectEnd.length > 0
      ? { status: "present", workingHours, projectStart, projectEnd } : isHoliday
        ? { status: "holiday", holiday } : AppovedLeave
          ? "AppovedLeave" : ApliedforLeave
            ? "ApliedforLeave" : weekOff
              ? "weekOff" : ""
  }

  const [selectedEvent, setSelectedEvent] = useState(undefined)
  const [modalState, setModalState] = useState(false)

  const handleSelect = (event) => {
    let status = getStatus(event.start)
    setSelectedEvent(event)
    setModalState(true)
  };


  const handleSelectedEvent = (event) => {
    setSelectedEvent(event)
    setModalState(true)
  }

  const component = {
    dateCellWrapper: ColoredDateCellWrapper,
    eventWrapper: MyEventWrapper,
    month: {
      dateHeader: ({ date, label }) => {
        let status = getStatus(date)
        return (
          <div >
            <h5>{label}</h5>
            <div style={{
              marginBottom: "5px",
              fontSize: "8px",
              color: "white",
              backgroundColor: status.status && status.status === "present" ? "#016da1" :
                status.status === "holiday" ? "#ff0000" :
                  status === "ApliedforLeave" ? "#ffa903" :
                    status === "AppovedLeave" ? "#04b520" :
                      status === "projectStart" ? "#e4612a" :
                        status === "weekOff" ? "#3a87ad" : "",
              borderRadius: "5px", textAlign: "center", padding: "3px"
            }}>
              {
                status.status && status.status === "present"
                  ? "Reported Time: " + status.workingHours : status.status === "holiday"
                    ? status.holiday[0].Event : status === "AppovedLeave"
                      ? "Approved Leave " : status === "ApliedforLeave"
                        ? "Leave Aplied" : status === "weekOff"
                          ? "Week Off" : ""}
            </div>

            {status.status && status?.projectStart?.length > 0 ? (<div
              style={{
                fontSize: "8px",
                color: "white",
                backgroundColor: "#e4612a",
                borderRadius: "5px", textAlign: "center", padding: "3px"
              }}
            >
              {
                status?.projectStart?.map((item, index) => {
                  return (
                    <div>{index + 1}. {item?.ProjectName}</div>
                  )
                })
              }
            </div>) : status?.projectEnd?.length > 0 ? (<div
              style={{
                fontSize: "8px",
                color: "black",
                backgroundColor: "#ffe600",
                borderRadius: "5px", textAlign: "center", padding: "3px"
              }}
            >
              {
                status?.projectEnd?.map((item, index) => {
                  return (
                    <div>{index + 1}. {item?.ProjectName}</div>
                  )
                })
              }
            </div>) : ""}
          </div>
        );
      },
    },

    event: (props) => {
      let status = getStatus(props?.event?.end)
      return (
        <>
          <div style={{
            marginBottom: "5px",
            fontSize: "10px",
            color: "white",
            backgroundColor: status.status && status.status === "present" ? "#016da1" :
              status.status === "holiday" ? "#ff0000" :
                status === "ApliedforLeave" ? "#ffa903" :
                  status === "AppovedLeave" ? "#04b520" :
                    status === "projectStart" ? "#e4612a" :
                      status === "weekOff" ? "#3a87ad" : "",
            borderRadius: "5px", textAlign: "center", padding: "7px"
          }}>
            {
              status.status && status.status === "present"
                ? "Reported Time: " + status.workingHours : status.status === "holiday"
                  ? status.holiday[0].Event : status === "AppovedLeave"
                    ? "Approved Leave" : status === "ApliedforLeave"
                      ? "Leave Aplied" : status === "weekOff"
                        ? "Week Off" : ""}
          </div>
          {status.status && status?.projectStart?.length > 0 ? (<div
            style={{
              fontSize: "10px",
              color: "white",
              backgroundColor: "#e4612a",
              borderRadius: "5px", textAlign: "center", padding: "7px",
            }}
          >
            {
              status?.projectStart?.map((item, index) => {
                return (
                  <div style={{ marginBottom: "7px" }} >{index + 1}. {item.ProjectName}</div>
                )
              })
            }
          </div>) : status?.projectEnd?.length > 0 ? (<div
            style={{
              fontSize: "10px",
              color: "white",
              backgroundColor: "#ffe600",
              borderRadius: "5px", textAlign: "center", padding: "7px",
            }}
          >
            {
              status?.projectEnd?.map((item, index) => {
                return (
                  <div style={{ marginBottom: "7px" }}>{index + 1}. {item.ProjectName}</div>
                )
              })
            }
          </div>) : ""}

        </>
      )
      // workingHours
      // const eventType = props?.event?.data?.type
      // switch (eventType) {
      //   case "attendance" : 
      //   return (
      //     <div style={{background : "green", color : "white"}}>
      //       {props.description}
      //     </div>
      //   );
      //   case "holiday" : 
      //   return (
      //     <div style={{background : "green", color : "white"}}>
      //       {props.description}
      //     </div>
      //   );
      //   default : 
      //   return null
      // }

    },

    day: {
      header: ({ date, label, localizer }) => {
        let status = getStatus(date)
        let min = localizer.startOf(new Date(), 'day')
        let max = localizer.endOf(new Date(), 'day')
        let scrollToTime = localizer.startOf(new Date(), 'day')
        let enableAutoScroll = true
        let range = localizer.range(date, { localizer: localizer })
        return (
          <div>
            Header
            {/* {
              status.status && status.status === "present"
                ? "Reported Time: " + status.workingHours : status.status === "holiday"
                  ? status.holiday[0].Event : status === "AppovedLeave"
                    ? "Approved Leave" : status === "ApliedforLeave"
                      ? "Leave Aplied" : status === "weekOff"
                        ? "Week Off" : ""} */}
          </div>
          //   <TimeGrid
          //   // {...props}
          //   range={range}
          //   eventOffset={10}
          //   localizer={localizer}
          //   min={min}
          //   max={max}
          //   scrollToTime={scrollToTime}
          //   enableAutoScroll={enableAutoScroll}
          // />

        )
      },

    }

  }

  return (
    <>
      <div className="calendar-section">
        {selectedEvent && <Modal />}
        <Calendar
          min={new Date("2023-04-18T10:00:00")}
          max={new Date("2023-04-18T20:00:00")}
          onNavigate={(date) => {
            getHystory(date.getMonth() + 1, date.getFullYear())
          }}
          localizer={localizer}
          startAccessor="start"
          style={{ height: "90vh" }}
          events={dailyEvents}
          tileClassName
          selectable
          onSelectSlot={handleSelect}
          onSelectEvent={handleSelectedEvent}
          views={['month', 'week', 'day']}
          showMultiDayTimes={true}
          step={30}
          components={component}
        />
        <div className="holiday-and-legend">
          <div className="row mt-2">
            <div className="col-6 align-self-stretch">
              <div className="legend-section h-100">
                <h5 className="title">Legend</h5>
                <div className="capsule-wrapper">
                  <div className="row d-flex">
                    <div className="col-md-6">
                      <div className="capsule d-flex align-items-center">
                        <span className="legend-color-capsule color-today"></span>
                        <span className="legend-label">Today</span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="capsule d-flex align-items-center">
                        <span className="legend-color-capsule color-week-off"></span>
                        <span className="legend-label">Week-Off</span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="capsule d-flex align-items-center">
                        <span className="legend-color-capsule color-leave-applied"></span>
                        <span className="legend-label">Leave Applied</span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="capsule d-flex align-items-center">
                        <span className="legend-color-capsule color-approved-leave"></span>
                        <span className="legend-label">Approved Leave</span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="capsule d-flex align-items-center">
                        <span className="legend-color-capsule color-reported-time"></span>
                        <span className="legend-label">Reported Time</span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="capsule d-flex align-items-center">
                        <span className="legend-color-capsule color-holiday"></span>
                        <span className="legend-label">Holiday</span>
                      </div>
                    </div>
                    {
                      permissions && permissions['Project Start'] &&

                      <div className="col-md-6">
                        <div className="capsule d-flex align-items-center">
                          <span className="legend-color-capsule color-project-start"></span>
                          <span className="legend-label">Project Start</span>
                        </div>
                      </div>
                    }
                    {
                      permissions && permissions['Project End'] &&
                      <div className="col-md-6">
                        <div className="capsule d-flex align-items-center">
                          <span className="legend-color-capsule color-project-end"></span>
                          <span className="legend-label">Project End</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 align-self-stretch" style={{ height: "16rem" }}>
              {
                events.Holiday && events.Holiday.length > 0 && (
                  <div className="holidays-section">
                    <h5 className="title">Holidays</h5>
                    {
                      events.Holiday.map((item) => {
                        const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                        let monthName = month[new Date(item.Date).getMonth()];
                        return (
                          <p>
                            <span className="holiday-date">{monthName} {new Date(item.Date).getDate()} </span>
                            <span className="holiday-name">{item.Event}</span>
                          </p>
                        )
                      })
                    }
                    {/* <p>
                      <span className="holiday-date">May 4 </span>
                      <span className="holiday-name"> XYZ Day</span>
                    </p> */}
                    {/* <p>
                  <span className="holiday-date">May 8 </span>
                  <span> Mother's Day</span>
                </p>
                <p>
                  <span className="holiday-date">May 30 </span>
                  <span> Memorial Day</span>
                </p> */}
                  </div>
                )
              }
            </div>

          </div>
        </div>
      </div>
      <Modal
        size="xl"
        className=""
        // show={modalState}
        onHide={() => setModalState(false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header className="announcement-header" closeButton>
          <Modal.Title className="announcement-title">Info</Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <div className="Announcement">
            <div className="top">
              <h3>Welcome to </h3>
              <p className="text-grey">
                {/* Posted on {anou.Date.slice(0, 9)}{" "}
                        <b className="right-divider">{anou.CreatedBy}</b>{" "}
                        <span>{anou.ViewCount}+ Views</span> */}
              </p>
            </div>

            {/* <img
                      alt=""
                      onClick={handleShow6}
                      className="AnnouncementImg"
                      src={
                        !anou.ImagePath
                          ? g20
                          : anou.ImagePath
                            ? ApiConfig.BASE_URL_FOR_IMAGES + anou.ImagePath
                            : anou.ImagePath && URL.createObjectURL(anou.ImagePath)
                      }
                    ></img> */}

            <div className="discribtion">
              {/* <p className="qution">{anou.Description}</p> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="delete-footer">
          {/* <Button
            variant="secondary"
            className="cancel-btn"
            onClick={handleClose4}
          >
            Close
          </Button>
          <Button variant="primary" className="sbmt-btn" onClick={handleClose3}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CalendarBox;
