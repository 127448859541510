import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import ImagePic from "../../assets/images/createUser.png";
import {
  PostCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import { useNavigate } from "react-router-dom";

const CreateTeam = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const navigate = useNavigate();
  const [teamHead, setTeamHead] = useState([]);
  const [departmentList, setDemaprtmentList] = useState([])
  const [createTeam, setCreateTeam] = useState({
    TeamId: "",
    TeamName: "",
    TeamHead: "",
    DepartmentId: "",
    PhotoPath: "",
  });

  const [error, setError] = useState({
    TeamId: "",
    TeamName: "",
    TeamHead: "",
    DepartmentId: "",
    PhotoPath: "",
  });

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   // Check if a file is selected
  //   if (file) {
  //     // Check if the file is an image
  //     if (!file.type.startsWith('image/')) {
  //       //setErrorMessage('Please select an image file.');
  //     } else if (file.size > 4 * 1024 * 1024) { // Check if file size is more than 4MB
  //       //setErrorMessage('Image size should not exceed 4MB.');
  //     } else {
  //       // Update the selected file if it meets the criteria
  //       //setSelectedFile(file);
  //       //setErrorMessage('');
  //     }
  //   }
  // };

  const CreateTeamApi = (e) => {
    if (createTeam.DepartmentId === "") {
      setError({
        ...error,
        DepartmentId: "Please Select Department",
      });
      if (createTeam.TeamName === "") {
        setError({
          ...error,
          TeamName: "Please enter TeamName",
        });
        return;
      }
      if (createTeam.TeamHead === "") {
        setError({
          ...error,
          TeamHead: "Please Select Team Head",
        });
        return;
      }

      return;
    }
    if (createTeam.PhotoPath === "") {
      setError({
        ...error,
        PhotoPath: "Please Upload Team Photo",
      });
      return;
    } else {
      let fromdata = new FormData();
      fromdata.append("TeamName", createTeam.TeamName);
      fromdata.append("TeamHead", createTeam.TeamHead);
      fromdata.append("DepartmentId", createTeam.DepartmentId);
      fromdata.append("PhotoPath", createTeam.PhotoPath);

      //   setBtnLoader(true);
      multipartPostCallWithErrorResponse(ApiConfig.CREATE_TEAM, fromdata)
        .then((res) => {
          //   setBtnLoader(false);
          if (res.json.Success === true) {
            ToastMsg("success", res.json.Message);
            // handleClose();
            navigate("/ManageTeam");
          } else {
            ToastMsg("error", res?.json?.Message);
            console.log("error response,", res);
          }
        })
        .catch((err) => {
          //   setBtnLoader(false);
          console.log("error response,", err);
        });
    }
  };

  useEffect(() => {
    simpleGetCallWithToken(ApiConfig.ACTIVE_EMPLOYEE_LIST)
      .then((res) => {
        console.log(res);
        setTeamHead(res?.Data);
      })
      .catch((err) => {
        console.log(err);
      });

    simpleGetCallWithToken(ApiConfig.GET_DEPARMENT_LIST)
      .then((res) => {
        console.log(res);
        setDemaprtmentList(res?.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Create Team</h6>
          </div>
          <div className="createCard">
            <div className="row">

              <div className="col-md-12 col-lg-6">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Select Department
                  </label>
                  <select
                    onChange={(e) => {
                      setCreateTeam({
                        ...createTeam,
                        DepartmentId: e.target.value,
                      });
                      setError({
                        ...error,
                        DepartmentId: "",
                      });
                    }}
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="">Select Department</option>
                    {departmentList.map((name, index) => {
                      return (
                        <option value={name.DepartmentId} key={index}>
                          {name.DepartmentName}
                        </option>
                      );
                    })}
                  </select>
                  {error.DepartmentId.length > 0 && (
                    <div className="NotFoundLeft">{error.DepartmentId}</div>
                  )}
                </div>
                <div className="modalProp mb-4">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "12.8px" }}
                  >
                    Team Name
                  </label>
                  <input
                    maxlength="40"
                    onChange={(e) => {
                      setCreateTeam({
                        ...createTeam,
                        TeamName: e.target.value,
                      });
                      setError({
                        ...error,
                        TeamName: "",
                      });
                    }}
                    value={createTeam.TeamName}
                    type="text"
                    placeholder="Enter Team Name"
                    className="date-label-input tasKCategory1 focusBlc"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                  {error.TeamName.length > 0 && (
                    <div className="NotFoundLeft">{error.TeamName}</div>
                  )}
                </div>
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Select Team Head
                  </label>
                  <select
                    onChange={(e) => {
                      setCreateTeam({
                        ...createTeam,
                        TeamHead: e.target.value,
                      });
                      setError({
                        ...error,
                        TeamHead: "",
                      });
                    }}
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="">Select Team Head</option>
                    {teamHead.map((name, index) => {
                      return (
                        <option value={name?.EmployeeId} key={index}>
                          {name.EmployeeName}
                        </option>
                      );
                    })}
                  </select>
                  {error.TeamHead.length > 0 && (
                    <div className="NotFoundLeft">{error.TeamHead}</div>
                  )}
                </div>

                <div className="modalProp mb-4">
                  <label for="name1" class="enter-role-text mb-2">
                    Upload Team Photo
                  </label>
                  <input
                    class="form-control tasKCategory1"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    id="name1"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      // Check if a file is selected
                      if (selectedFile) {
                        console.log("Selected file size:", selectedFile.size);
                        // Check if the file size exceeds 4MB
                        if (selectedFile.size > 4 * 1024 * 1024) {
                          console.log("File size exceeds 4MB");
                          setError({
                            ...error,
                            PhotoPath: "File size should not exceed 4MB.",
                          });
                        } else {
                          // Get the file extension
                          const fileExtension = selectedFile.name
                            .split(".")
                            .pop()
                            .toLowerCase();
                          // Define allowed extensions
                          const allowedExtensions = ["png", "jpg", "jpeg"];
                          // Check if the file extension is allowed
                          if (!allowedExtensions.includes(fileExtension)) {
                            setError({
                              ...error,
                              PhotoPath:
                                "Only .png, .jpg, and .jpeg files are allowed.",
                            });
                          } else {
                            setCreateTeam({
                              ...createTeam,
                              PhotoPath: selectedFile,
                            });
                            setError({
                              ...error,
                              PhotoPath: "",
                            });
                          }
                        }
                      }
                    }}
                    required={!createTeam.PhotoPath ? true : false}
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                  <label for="name1" class="enter-role-text mt-2">
                    File size limit: 4 MB
                  </label>
                  {error.PhotoPath.length > 0 && (
                    <div className="NotFoundLeft">{error.PhotoPath}</div>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="imageHolder">
                  <img
                    className="imgInside"
                    alt=""
                    src={
                      !createTeam.PhotoPath
                        ? ImagePic
                        : createTeam.PhotoPath.length
                          ? createTeam.PhotoPath
                          : createTeam.PhotoPath &&
                          URL.createObjectURL(createTeam.PhotoPath)
                    }
                  />
                </div>
              </div>
              <div className="col">
                <button className="modal-create-btn" onClick={CreateTeamApi}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTeam;
