import { AppContext } from "../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import Loader from "../Loader";
import Editac from "../../assets/images/bx_edit.svg";
import DelectAc from "../../assets/images/DelectAcBlc.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import NoData from "../../sharedComponent/NoData";
import { FormControl, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import {
  DeleteCallWithErrorResponseWithToken,
  PostCallWithErrorResponse,
  putMultipartWithAuthCall,
  putWithAuthCall,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Pagination from "../../sharedComponent/Pagination";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import { Link } from "react-router-dom";


function ShiftTime() {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  const [allShifts, setAllShifts] = useState([]);

  const [loading, setLoading] = useState(false);

  // Modal usestate
  const [addEditOpen, setAddEditOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOption, setDeleteOption] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);
  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const [deleteId, setDeleteId] = useState();
  const [deleteShiftName, setDelteteShiftName] = useState("");
  const [editId, setEditId] = useState(0);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    allShifts &&
    allShifts.length &&
    allShifts.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const [storeValues, setStoreValues] = useState({
    ShiftName: "",
    InTime: "",
    OutTime: "",
    GraceTime: 0,
    breakTimeIn: "",
    breakTimeOut: "",
    Id: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const shiftMasterStore = () => {
    if (
      !storeValues.ShiftName ||
      !storeValues.InTime ||
      !storeValues.OutTime ||
      !storeValues.GraceTime ||
      !storeValues.breakTimeIn ||
      !storeValues.breakTimeOut
    ) {
      ToastMsg("error", "Please Provide All Details");
      return;
    }

    let data = {
      ShiftName: storeValues.ShiftName,
      InTime: storeValues.InTime,
      OutTime: storeValues.OutTime,
      GraceTime: parseInt(storeValues.GraceTime),
      BreakStartTime: storeValues.breakTimeIn,
      BreakEndTime: storeValues.breakTimeOut,
    };

    PostCallWithErrorResponse(ApiConfig?.ADD_SHIFT_MASTER, JSON.stringify(data))
      .then((res) => {
        if (res.json && res?.json?.Success) {
          ToastMsg("success", res?.json?.Message);
          setStoreValues({
            ShiftName: "",
            InTime: "",
            OutTime: "",
            GraceTime: 0,
            breakTimeIn: 0,
            breakTimeOut: 0,
            Id: 0,
          });
          setAddEditOpen(false);
          getAllShift();
        } else {
          ToastMsg("error", res?.json?.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const shiftMasterUpdate = (id) => {
    if (
      !storeValues.ShiftName ||
      !storeValues.InTime ||
      !storeValues.OutTime ||
      !storeValues.GraceTime ||
      !storeValues.breakTimeIn ||
      !storeValues.breakTimeOut
    ) {
      ToastMsg("error", "Please Provide All Details");
      return;
    }

    let data = {
      ShiftName: storeValues.ShiftName,
      InTime: storeValues.InTime,
      OutTime: storeValues.OutTime,
      GraceTime: parseInt(storeValues.GraceTime),
      BreakStartTime: storeValues.breakTimeIn,
      BreakEndTime: storeValues.breakTimeOut,
    };

    putWithAuthCall(ApiConfig?.UPDATE_SHIFT_MASTER + id, JSON.stringify(data))
      .then((res) => {
        if (res?.Success) {
          ToastMsg("success", res?.Message);
          setStoreValues({
            ShiftName: "",
            InTime: "9:10",
            OutTime: "",
            GraceTime: 0,
            breakTimeIn: "",
            breakTimeOut: "",
            Id: 0,
          });
          setAddEditOpen(false);
          getAllShift();
        } else {
          ToastMsg("error", res?.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editShiftMaster = (editId) => {
    simpleGetCallWithErrorResponse(ApiConfig?.EDIT_SHIFT_MASTER + editId)
      .then((res) => {
        if (res && res?.json?.Success) {
          setStoreValues({
            ShiftName: res?.json?.Data.ShiftName,
            InTime: res?.json?.Data.InTime,
            OutTime: res?.json?.Data.OutTime,
            GraceTime: res?.json?.Data.GraceTime,
            breakTimeIn: res?.json?.Data.BreakStartTime,
            breakTimeOut: res?.json?.Data.BreakEndTime,
            Id: res?.json?.Data.Id,
          });

          console.log(" payload ", storeValues);
          setAddEditOpen(true);
        } else {
          ToastMsg("error", res?.json?.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteShift = (deleteId) => {
    DeleteCallWithErrorResponseWithToken(
      ApiConfig?.DELETE_SHIFT_MASTER + deleteId
    )
      .then((res) => {
        if (res?.json?.Success) {
          ToastMsg("success", res?.json?.Message);
          setDeleteOption(false);
          getAllShift();
          return;
        } else {
          ToastMsg("error", res?.json?.Message);
          setDeleteOption(false);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllShift();
  }, []);

  // GET Shift Master Data
  const getAllShift = (search = "") => {
    setLoading(true);

    PostCallWithErrorResponse(
      ApiConfig?.GET_ALL_SHIFT,
      JSON.stringify({
        startIndex: 10,
        count: 0,
        sorting: search,
      })
    )
      .then((res) => {
        if (res?.json?.Success) {
          setAllShifts(res?.json?.Data);
        } else {
          console.log("something went wrong");
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role d-flex justify-content-between">
            <h6 className="mt-2">Shift Time </h6>
            {permissions && permissions["Add Shift"] && (
              <button
                className="create-btn-sub-header"
                onClick={() => setAddEditOpen(true)}
              >
                Add Shift
              </button>
            )}
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header accoridance">
              <div className="search-input-wrapper allAcordiance">
                {/* {permissions && permissions["View All Announcement"] && ( */}
                <Link to="/ShiftMaster">
                  <button className=" active all-anc-btn-sub-header ">
                    Shift Time
                  </button>
                </Link>
                {/* )} */}
              </div>
              <div className="entries-wrapper myAccordiance">
                {/* {permissions && permissions["View My Announcement"] && ( */}
                <Link to="/CalendarSettings">
                  <button className="my-anc-btn-sub-header">
                    Shift Settings
                  </button>
                </Link>
                {/* )} */}
              </div>
              <div className="role-table-header">
                {/* <div className="entries-wrapper">
                  <p>Entries per page</p>
                  <select
                    style={{ width: "200px" }}
                    className="form-select select-drop-icon "
                    aria-label="Default select example"
                    onChange={(e) => {
                      setUsersPerPage(e.target.value);
                      setCurrentPage(0);
                    }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25" selected>
                      25
                    </option>
                  </select>
                </div> */}
                <div className="search-input-wrapper">
                  <input
                    type="text"
                    data-date=""
                    data-date-format="DD MMMM YYYY"
                    placeholder="Search"
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                  {Dark === "lightMode" ? (
                    <img src={searchicon} alt="" />
                  ) : (
                    <img src={ic_search_dark} alt="" />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-flex justify-content-between mainHeader custom-mb">
            <div className="role-table-header-main">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  data-date=""
                  data-date-format="DD MMMM YYYY"
                  placeholder="Search"
                  onChange={(e) => {
                    getAllShift(e.target.value);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
            </div>
          </div> */}

          <div
            className="table-wrapper custom-table-width"
            id="scrol-tblNtable-wrapper-af"
          >
            {loading ? (
              <Loader />
            ) : (
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <th>Sr No</th>
                    <th>Shift Name</th>
                    <th>In Time</th>
                    <th>Out Time</th>
                    <th>Grace Time {"(In min)"}</th>
                    <th>Break Time {"(In Min)"}</th>
                    {permissions && (permissions["Edit Shift"] || permissions["Delete Shift"]) &&
                      <th>Action</th>
                    }
                  </tr>
                </thead>
                {allShifts?.length > 0 ? (
                  <tbody>
                    {allShifts.filter((i) => {
                      return searchValue ? i.ShiftName.toLowerCase().includes(searchValue.toLowerCase()) : i
                    })
                      .slice(startIndex, endIndex)
                      .map((shift, indx) => {
                        return (
                          <tr className="table-row-custom">
                            <td>{startIndex + indx + 1}</td>
                            <td>{shift?.ShiftName}</td>
                            <td>{shift?.InTime}</td>
                            <td>{shift?.OutTime}</td>
                            <td>{shift?.GraceTime}</td>
                            <td>{shift?.BreakTime}</td>

                            {permissions && (permissions["Edit Shift"] || permissions["Delete Shift"]) &&
                              <td>
                                {permissions && permissions["Edit Shift"] && (
                                  <img
                                    onClick={() => {
                                      setEditId(shift?.Id);
                                      editShiftMaster(shift?.Id);
                                      // setAddEditOpen(true)
                                    }}
                                    src={Editac}
                                    alt=""
                                  />
                                )}
                                {permissions && permissions["Delete Shift"] && (
                                  <img
                                    onClick={() => {
                                      setDeleteOption(true);
                                      setDeleteId(shift?.Id);
                                      setDelteteShiftName(shift?.ShiftName);
                                    }}
                                    src={DelectAc}
                                    alt=""
                                  />
                                )}
                              </td>
                            }
                          </tr>
                        );
                      })}
                  </tbody>
                ) : (
                  <NoData />
                )}
              </table>
            )}
          </div>
        </div>
        <Pagination
          data={allShifts}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
      </div>

      {/* ADD MODAL */}
      <Modal
        className="delete-news-modal"
        show={addEditOpen}
        dialogClassName="modal-90w"
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="delete-title">
            {" "}
            {editId > 0 ? "Update Shift" : "Add New Shift"}
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setAddEditOpen(false)} />
          </div>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <Form className="d-flex flex-column gap-3">
            <Form.Group>
              <Form.Label>Shift Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Shift Name"
                name="ShiftName"
                value={storeValues.ShiftName}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback>
                Shift name Requered!
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex justify-content-start">
              <Form.Group className="mr-5">
                <Form.Label> Shift Start Time</Form.Label>
                <Form.Control
                  required
                  type="time"
                  placeholder="InTime"
                  name="InTime"
                  value={storeValues.InTime}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback>In Time Required!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Shift End Time</Form.Label>
                <Form.Control
                  required
                  type="time"
                  placeholder="Out Time"
                  name="OutTime"
                  value={storeValues.OutTime}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback>Out Time Required!</Form.Control.Feedback>
              </Form.Group>
            </div>
            <Form.Group>

              <Form.Label>Grace Time</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Grace Time"
                name="GraceTime"
                value={storeValues.GraceTime}
                onChange={(e) => {
                  if (Number(e.target.value) < 0) {
                    return;
                  }
                  handleInputChange(e);
                }}
              />
              <Form.Control.Feedback>
                Grace Time required!!
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex justify-content-start">
              <Form.Group className="mr-5">
                <Form.Label> Break Start Time
                  {/* {"(In Min)"} */}
                </Form.Label>
                <Form.Control
                  required
                  type="time"
                  placeholder="Break Time (In Min)"
                  name="breakTimeIn"
                  value={storeValues.breakTimeIn}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback>
                  Break Time In Min Required!!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label> Breake End Time
                  {/* {"(out Min)"} */}
                </Form.Label>
                <Form.Control
                  required
                  type="time"
                  placeholder="Break Time (Out Min)"
                  name="breakTimeOut"
                  value={storeValues.breakTimeOut}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback>
                  Break Time Out Min Requered!
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="lineSeperate"></div>
            <div className="d-flex justify-content-end align-items-center mainBtnsSub">
              <button
                type="button"
                className="btn-width cancelBtn mx-3"
                onClick={() => {
                  setAddEditOpen(false);
                  setStoreValues({
                    ShiftName: "",
                    InTime: "",
                    OutTime: "",
                    GraceTime: 0,
                    breakTimeIn: 0,
                    breakTimeOut: 0,
                    Id: 0,
                  });
                  setEditId(0);
                }}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn-width saveBtn"
                onClick={() => {
                  if (editId > 0) {
                    shiftMasterUpdate(editId);
                  } else {
                    shiftMasterStore();
                  }
                }}
              >
                {editId > 0 ? "Update " : "Save"}

              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* DELETE MODAL */}
      <Modal
        className="delete-news-modal"
        show={deleteOption}
        onHide={() => setDeleteOption(false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <div>
            <p>Are You Sure To You Went To Delete {deleteShiftName} shift !</p>
          </div>
        </Modal.Body>
        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end mainBtnsSub">
          <button
            type="button"
            className="btn-width cancelBtn mx-3"
            onClick={() => setDeleteOption(false)}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn-width saveBtn"
            onClick={() => {
              deleteShift(deleteId);
            }}
          >
            Confirm
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ShiftTime;
