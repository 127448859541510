import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import VectorImg from "../../assets/images/icon-user-default.png";
import Modal from "react-bootstrap/Modal";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";


import {
  multipartPostCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithToken,
  PostCallWithErrorResponse,
  PostCallWithErrorResponseReqBody,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import { Form } from "react-bootstrap";
import { add } from "lodash";
import { useNavigate } from "react-router-dom";
import excelicon from "../../assets/images/sidebar/icons/excelicon.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const AddEmployee = () => {
  const {
    sidebar,
    setSidebar,
    Dark,
    setDark,
    userData,
    countryList,
    getCountryList,
  } = useContext(AppContext);
  const [key, setKey] = useState("personalDetails");
  console.log("Key data >>>>>>>>>>", key);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const [departmentlist, setDepartmentlist] = useState([]);
  const [designationsname, setDesignationsname] = useState([]);
  const [role, setRole] = useState([]);
  const [employeeattlist, setEmployeeattlist] = useState([]);
  const [location, setLocation] = useState([]);
  const [shift, setShift] = useState([]);
  const [validated, setValidated] = useState(false);
  const [showPersonal, setShowPersonal] = useState(false);
  const [stateList, setStateList,] = useState([])
  const [cities, setCities] = useState([])
  // const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  // const [errorMessage2, setErrorMessage2] = useState('');
  const [errorMessage3, setErrorMessage3] = useState("");
  const [errorMessage4, setErrorMessage4] = useState("");
  const [errorMessage5, setErrorMessage5] = useState("");
  const [errorMessage6, setErrorMessage6] = useState("");
  const [errorMessage7, setErrorMessage7] = useState("");

  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);

  const [skill, setSkill] = useState("");
  const [proficiency, setProficiency] = useState("");
  const [addEmply, setAddEmply] = useState({
    EmployeePhoto: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    DateOfBirth: "",
    FatherName: "",
    MothersName: "",
    Religion: "",
    Nationality: "",
    Gender: "",
    BloodGroup: "",
    IdentificationMark: "",
    MaritalStatus: "",
    EmployeeCode: 0,
    GSMIId: "",
    TranscardId: "",
    WorkEmail: "",
    OtherEmail: "",
    MobilePhone: "",
    EmergencyContact: "",
    EmergencyContactPerson: "",
    Address: "",
    City: "",
    State: "",
    ZipCode: "",
    PermanentAddress: "",
    Country: "",
    DepartmentId: "",
    DesignationId: "",
    LocationId: "",
    ShiftId: "",
    Grade: "",
    NatureOfPost: "",
    DateOfJoining: "",
    ReportingId: "",
    RoleId: "",
    WorkTelephone: "",
    TeamId: 0,
    qualification: "",
    skillSet: [],
    ProbationPeriod: "",
  });
  console.log(addEmply, "addemployee");
  const [error, setError] = useState({
    EmployeePhoto: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    DateOfBirth: "",
    FatherName: "",
    MothersName: "",
    Religion: "",
    Nationality: "",
    Gender: "",
    BloodGroup: "",
    IdentificationMark: "",
    MaritalStatus: "",
    EmployeeCode: "",
    GSMIId: "",
    TranscardId: "",
    WorkEmail: "",
    OtherEmail: "",
    MobilePhone: "",
    EmergencyContact: "",
    EmergencyContactPerson: "",
    HomePhone: "",
    Extension: "",
    Address: "",
    City: "",
    State: "",
    ZipCode: "",
    PermanentAddress: "",
    Country: "",
    DepartmentId: "",
    DesignationId: "",
    LocationId: "",
    ShiftId: "",
    Grade: "",
    NatureOfPost: "",
    DateOfJoining: "",
    ReportingId: "",
    RoleId: "",
    WorkTelephone: "",
    TeamId: "",
    qualification: "",
    skillSetErr: "",
    ProbationPerioderror: "",
  });
  const [responseError, setResponseError] = useState({});
  const [errorResponse, setErrorResponse] = useState(false);
  //   useEffect(()=>{
  //     simpleGetCallWithToken(ApiConfig.GET_OWNER_NAMES_MODEL_OPTIONS)
  //     .then(res=>{
  // console.log("eee success")
  // setEmployeeattlist(res?.Data)
  // console.log('eeeee list : ', employeeattlist)
  //     })
  //   },[])

  console.log("location--->", location);
  console.log("responseError--->", responseError?.Data);
  useEffect(() => {
    if (!countryList.isFetched) {
      getCountryList();
    }

    Promise.all([
      simpleGetCallWithToken(ApiConfig.GETDEPARTMENT_LIST),
      simpleGetCallWithToken(ApiConfig.DESIGNATION_NAME),
      simpleGetCallWithToken(ApiConfig.ROLES_LIST),
      // postWithAuthCallWithErrorResponse(ApiConfig.TEAM_HEAD_lIST),
      simpleGetCallWithToken(ApiConfig.GET_OWNER_NAMES_MODEL_OPTIONS),
      simpleGetCallWithToken(ApiConfig.GET_LOCATION),
      simpleGetCallWithToken(ApiConfig.GET_SHIFT_LIST),
    ])
      .then(
        ([
          responses1,
          response2,
          response3,
          response4,
          response5,
          response6,
        ]) => {
          setDepartmentlist(responses1.Data);
          setDesignationsname(response2.Data);
          setRole(response3.Data);
          setEmployeeattlist(response4.Data);
          setLocation(response5.Data);
          setShift(response6.Data);
        }
      )
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  const getState = (countryId) => {

    simpleGetCallWithErrorResponse(ApiConfig.STATE_LIST + countryId).then((res) => {

      if (res?.json?.Success) {

        setStateList(res?.json?.Data)

      }

    })

  }


  const getCities = (stateId) => {
    simpleGetCallWithErrorResponse(ApiConfig.GET_CITIES_LIST + stateId).then((res) => {

      if (res?.json?.Success) {

        setCities(res?.json?.Data)

      }

    })


  }


  const handleScreenNextTab = (e) => {
    e.preventDefault();
    if (key === "personalDetails") {
      if (addEmply.EmployeePhoto === "") {
        setError({
          ...error,
          EmployeePhoto: "Please upload your profile picture",
        });
        return;
      }
      if (addEmply.FirstName.length === "") {
        setError({
          ...error,
          FirstName: "Enter first Name",
        });
        return;
      }
      // if (addEmply.MiddleName.length === 0) {
      //   setError({
      //     ...error,
      //     MiddleName: "Enter Middle Name",
      //   });
      //   return;
      // }
      if (addEmply.LastName.length === 0) {
        setError({
          ...error,
          LastName: "Enter Last Name ",
        });
        return;
      }
      if (addEmply.DateOfBirth.length === 0) {
        setError({
          ...error,
          DateOfBirth: "Enter Date Of Birth",
        });
        return;
      }
      if (addEmply.FatherName.length === 0) {
        setError({
          ...error,
          FatherName: "Enter Father Name",
        });
        return;
      }
      if (addEmply.MothersName.length === 0) {
        setError({
          ...error,
          MothersName: "Enter Mother Name ",
        });
        return;
      }
      if (addEmply.Religion.length === 0) {
        setError({
          ...error,
          Religion: "Please Enter Religion",
        });
        return;
      }
      if (addEmply.Nationality.length === 0) {
        setError({
          ...error,
          Nationality: " Please Enter Nationality",
        });
        return;
      }
      if (addEmply.Gender.length === 0) {
        setError({
          ...error,
          Gender: "Please Enter Gender",
        });
        return;
      }
      if (addEmply.BloodGroup.length === 0) {
        setError({
          ...error,
          BloodGroup: "Please Enter BloodGroup",
        });
        return;
      }
      // if (addEmply.IdentificationMark.length === 0) {
      //   setError({
      //     ...error,
      //     IdentificationMark: "Please Enter IdentificationMark",
      //   });
      //   return;
      // }
      if (addEmply.MaritalStatus.length === 0) {
        setError({
          ...error,
          MaritalStatus: "Please Enter MaritalStatus",
        });
        return;
      }
      if (addEmply.qualification.length === 0) {
        setError({
          ...error,
          qualification: "Please Enter Qualification",
        });
        return;
      } else {
        setKey("contactDetails");
      }


    }
    if (key === "contactDetails") {
      const regex = /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9.]+\.)+[A-Za-z]+$/;

      if (!regex.test(addEmply.WorkEmail)) {
        setError({ ...error, WorkEmail: "Enter Valid Email ID " });
        return;
      }

      if (!regex.test(addEmply.OtherEmail)) {
        setError({ ...error, OtherEmail: "Enter Valid Email ID " });
        return;
      }
      if (addEmply.MobilePhone.length === 0) {
        setError({
          ...error,
          MobilePhone: "Please Enter Mobile Number",
        });
        return;
      }
      if (addEmply.WorkTelephone.length === 0) {
        setError({
          ...error,
          WorkTelephone: "Please Enter WorkTelephone Number",
        });
        return;
      }
      if (addEmply.EmergencyContactPerson.length === 0) {
        setError({
          ...error,
          EmergencyContactPerson: "Please Enter EmergencyContactPerson Name",
        });
        return;
      }

      if (addEmply.EmergencyContact.length === 0) {
        setError({
          ...error,
          EmergencyContact: "Please Enter EmergencyContact Number",
        });
        return;
      }
      if (addEmply.Address.length === 0) {
        setError({
          ...error,
          Address: "Please Enter Address",
        });
        return;
      }
      if (addEmply.City.length === 0) {
        setError({
          ...error,
          City: "Please Enter City",
        });
        return;
      }
      if (addEmply.State.length === 0) {
        setError({
          ...error,
          State: "Please Enter State",
        });
        return;
      }
      if (addEmply.ZipCode.length === 0) {
        setError({
          ...error,
          ZipCode: "Please Enter valid ZipCode",
        });
        return;
      }

      if (addEmply.PermanentAddress.length === 0) {
        setError({
          ...error,
          PermanentAddress: "Please Enter PermanentAddress",
        });
        return;
      }

      if (addEmply.Country.length === 0) {
        setError({
          ...error,
          Country: "Please Enter Country",
        });
        return;
      } else {
        setKey("professionalDetails");
      }
    }
    if (key === "professionalDetails") {
      if (addEmply.DepartmentId.length === 0) {
        setError({
          ...error,
          DepartmentId: "Please Select Department",
        });
        return;
      }
      // if(addEmply.ProbationPeriod.length===0){
      //   setError({
      //     ...error,
      //     ProbationPerioderror:'Please Enter Probation Period in Days '
      //   })
      //   return
      // }
      if (addEmply.DesignationId.length === 0) {
        setError({
          ...error,
          DesignationId: "Please Select Designation ",
        });
        return;
      }
      if (addEmply.LocationId.length === 0) {
        setError({
          ...error,
          LocationId: "Please Select Location",
        });
        return;
      }
      if (addEmply.ShiftId.length === 0) {
        setError({
          ...error,
          ShiftId: "Please Select Shift",
        });
        return;
      }
      if (addEmply.Grade.length === 0) {
        setError({
          ...error,
          Grade: "Please Select Grade",
        });
        return;
      }
      if (addEmply.NatureOfPost.length === 0) {
        setError({
          ...error,
          NatureOfPost: "Please Select Nature Of Post ",
        });
        return;
      }
      if (addEmply.DateOfJoining.length === 0) {
        setError({
          ...error,
          DateOfJoining: "Please Select Date Of Joining ",
        });
        return;
      }
      if (addEmply.ReportingId.length === 0) {
        setError({
          ...error,
          ReportingId: "Please Select Reporting",
        });
        return;
      }
      if (addEmply.skillSet.length === 0) {
        setError({
          ...error,
          skillSetErr: "Please Add At laest One Skill",
        });
      }
      if (errorMessage === "") {
        return;
      } else {
        AddEmployeeApi();
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log("select", name, value);

    const regex = /^[A-Za-z ]+$/; // Regular expression to allow only alphabets
    const usercopy = { ...addEmply };
    const errMsgcopy = { ...error };
    usercopy[name] = value;
    errMsgcopy[name] = "";
    setError(errMsgcopy);

    if (name === "EmergencyContactPerson") {
      if (value === "" || regex.test(value)) {
        usercopy[name] = value;
        errMsgcopy[name] = "";
      } else {
        errMsgcopy[name] = "Only alphabets are allowed.";
      }
    } else {
      usercopy[name] = value;
      errMsgcopy[name] = "";
    }

    setAddEmply(usercopy);
    if (name == "FirstName") {
      if (!regex.test(value)) {
        setErrorMessage("Only alphabets are allowed.");
      } else {
        setErrorMessage("");
        setInputValue(value);
      }
    }

    // if (name == 'MiddleName') {
    //   if (!regex.test(value)) {
    //     setErrorMessage2('Only alphabets are allowed.');
    //   } else {
    //     setErrorMessage2('');
    //     setInputValue(value);
    //   }
    // };

    if (name == "LastName") {
      if (!regex.test(value)) {
        setErrorMessage3("Only alphabets are allowed.");
      } else {
        setErrorMessage3("");
        setInputValue(value);
      }
    }

    if (name == "FatherName") {
      if (!regex.test(value)) {
        setErrorMessage4("Only alphabets are allowed.");
      } else {
        setErrorMessage4("");
        setInputValue(value);
      }
    }

    if (name == "MothersName") {
      if (!regex.test(value)) {
        setErrorMessage5("Only alphabets are allowed.");
      } else {
        setErrorMessage5("");
        setInputValue(value);
      }
    }

    if (name == "Religion") {
      if (!regex.test(value)) {
        setErrorMessage6("Only alphabets are allowed.");
      } else {
        setErrorMessage6("");
        setInputValue(value);
      }
    }

    if (name == "Nationality") {
      if (!regex.test(value)) {
        setErrorMessage7("Only alphabets are allowed.");
      } else {
        setErrorMessage7("");
        setInputValue(value);
      }
    }
  };

  const AddEmployeeApi = (e) => {
    let fromdata = new FormData();
    fromdata.append("EmployeePhoto", addEmply.EmployeePhoto);
    fromdata.append("FirstName", addEmply.FirstName);
    fromdata.append("MiddleName", addEmply.MiddleName);
    fromdata.append("LastName", addEmply.LastName);
    fromdata.append("DateOfBirth", addEmply.DateOfBirth);
    fromdata.append("FatherName", addEmply.FatherName);
    fromdata.append("MothersName", addEmply.MothersName);
    fromdata.append("Religion", addEmply.Religion);
    fromdata.append("Nationality", addEmply.Nationality);
    fromdata.append("Gender", addEmply.Gender);
    fromdata.append("BloodGroup", addEmply.BloodGroup);
    fromdata.append("IdentificationMark", addEmply.IdentificationMark);
    fromdata.append("MaritalStatus", addEmply.MaritalStatus);
    fromdata.append("EmployeeCode", addEmply.EmployeeCode);
    fromdata.append("GSMIId", addEmply.GSMIId);
    fromdata.append("TranscardId", addEmply.TranscardId);
    fromdata.append("WorkEmail", addEmply.WorkEmail);
    fromdata.append("MobilePhone", addEmply.MobilePhone);
    fromdata.append("EmergencyContact", addEmply.EmergencyContact);
    fromdata.append("EmergencyContactPerson", addEmply.EmergencyContactPerson);

    fromdata.append("Address", addEmply.Address);
    fromdata.append("City", addEmply.City);
    fromdata.append("State", addEmply.State);
    fromdata.append("ZipCode", addEmply.ZipCode);
    fromdata.append("Country", addEmply.Country);
    fromdata.append("DepartmentId", addEmply.DepartmentId);
    fromdata.append("DesignationId", addEmply.DesignationId);
    fromdata.append("PermanentAddress", addEmply.PermanentAddress);
    fromdata.append("LocationId", addEmply.LocationId);
    fromdata.append("ShiftId", addEmply.ShiftId);
    fromdata.append("Grade", addEmply.Grade);
    fromdata.append("NatureOfPost", addEmply.NatureOfPost);
    fromdata.append("DateOfJoining", addEmply.DateOfJoining);
    fromdata.append("ReportingId", addEmply.ReportingId);
    fromdata.append("RoleId", addEmply.RoleId);
    fromdata.append("ProbationPeriod", addEmply.ProbationPeriod);
    fromdata.append("WorkTelephone", addEmply.WorkTelephone);
    fromdata.append("TeamId", addEmply.TeamId);
    fromdata.append("Qualification", addEmply.qualification);
    // Serialize skillSet to JSON string
    //  fromdata.append('SkillSets', JSON.stringify(addEmply.skillSet));
    addEmply.skillSet.forEach((item, index) => {
      fromdata.append(`Skillsets[${index}].SkillName`, item.skillName);
      fromdata.append(
        `Skillsets[${index}].ProficiencyLevel`,
        item.proficiencyLevel
      );
    });
    fromdata.append("OtherEmail", addEmply?.OtherEmail);
    multipartPostCallWithErrorResponse(ApiConfig.EMPLOYE_ADDED, fromdata)
      .then((res) => {
        console.log(res);
        if (res.json && res.json?.Success === true) {
          ToastMsg("success", res.json.Message);
          navigate("/List");
        } else {
          ToastMsg("error", res.json.Message);
          setResponseError(res.json.Data);
          setErrorResponse(true);
        }
      })
      .catch((err) => console.log(err));

    setValidated(true);
  };
  const [importdata, setimportdata] = useState({});
  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Assuming single file selection
  };

  // const importemployee=()=>{
  //   if (!file) {
  //     console.error("No file selected");
  //     return;
  //   }

  //   let formData = new FormData();
  //   formData.append("file", file);

  //   PostCallWithErrorResponseReqBody(ApiConfig.IMPORT_EMPLOYEE,formData

  //   )
  //   .then((res) => {
  //     if (res.Success === true) {
  //       setimportdata(res.Data);
  //     } else {
  //       console.log("error response,", res);
  //     }
  //   })
  //   .catch((error) => {
  //     // console.log("error,", error);
  //   });

  // };
  const [excelLoading, setExcelLoading] = useState(false);
  const importemployee = () => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    let formData = new FormData();
    formData.append("formFile", file);

    multipartPostCallWithErrorResponse(ApiConfig.IMPORT_EMPLOYEE, formData)
      .then((res) => {
        if (res.json.Success === true) {
          ToastMsg("success", res.json.Message);
          setimportdata(res.Data);
          setShowPersonal(false);
        } else {
          console.log("Error response:", res);
          ToastMsg("error", res.json.Message);
          setShowPersonal(false);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });

    // fetch(ApiConfig.IMPORT_EMPLOYEE, {
    //   method: "POST",
    //   body: formData,
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("Token"),
    //   },
    // })
    //   .then((response) => response.json())
  };

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    postWithAuthCallWithErrorResponse(ApiConfig.Excel_User)
      .then((res) => {
        console.log("Download File Response", res);
        // if (res.error) {
        //   console.log("Error response", res);
        // } else {
        if (res.json.Success === true) {
          setExcelLoading(false);
          let data = res.json.Data;
          FileSaver.saveAs(
            "data:" + data.ContentType + ";base64," + data.FileContents,
            data.FileDownloadName
          );
          ToastMsg("success", res.json.Message);
        } else {
          console.log("res", res);
          setExcelLoading(false);
          ToastMsg("error", res.json.Message);

          // }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlezPrevious = (key) => {
    setKey("contactDetails");
  };

  const onDowmlodEcelFormat = () => {
    postWithAuthCallWithErrorResponse(ApiConfig.IMPOR_EXEL)
      .then((res) => {
        ToastMsg("success", res.json.Message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Add Employee</h6>
            <button className="impButton" onClick={() => setShowPersonal(true)}>
              Import Employees
            </button>
          </div>
          <Form noValidate validated={validated} onSubmit={AddEmployeeApi}>
            <div id="tabsBelow" className="mt-4">
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="personalDetails" title="Personal Details">
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="selectImg">
                        <div className="mb-1 text-center">
                          {/* <label htmlFor="" className="enter-role-text">
                            Employee Photo
                          </label>{" "} */}
                          {/* <br /> */}
                          <label htmlFor="imgHolder">
                            <img
                              src={
                                !addEmply.EmployeePhoto
                                  ? VectorImg
                                  : addEmply.EmployeePhoto.length
                                    ? ApiConfig.BASE_URL_FOR_IMAGES +
                                    addEmply.EmployeePhoto
                                    : addEmply.EmployeePhoto &&
                                    URL.createObjectURL(addEmply.EmployeePhoto)
                              }
                              className="modalImg mb-4"
                              alt=""
                            />
                            <input
                              type="file"
                              accept="image/*"
                              id="imgHolder"
                              required
                              className="form-control tasKCategory1"
                              name="EmployeePhoto"
                              onChange={(e) => {
                                const selectedFile = e.target.files[0];

                                // Check if a file is selected
                                if (selectedFile) {
                                  console.log(
                                    "Selected file size:",
                                    selectedFile.size
                                  );

                                  // Check if the file size exceeds 4MB
                                  if (selectedFile.size > 4 * 1024 * 1024) {
                                    console.log("File size exceeds 4MB");
                                    setError((prevError) => ({
                                      ...prevError,
                                      EmployeePhoto:
                                        "File size should not exceed 4MB.",
                                    }));
                                  } else {
                                    // Get the file extension
                                    const fileExtension = selectedFile.name
                                      .split(".")
                                      .pop()
                                      .toLowerCase();
                                    // Define allowed extensions
                                    const allowedExtensions = [
                                      "png",
                                      "jpg",
                                      "jpeg",
                                    ];
                                    // Check if the file extension is allowed
                                    if (
                                      !allowedExtensions.includes(fileExtension)
                                    ) {
                                      setError((prevError) => ({
                                        ...prevError,
                                        EmployeePhoto:
                                          "Only .png, .jpg, and .jpeg files are allowed.",
                                      }));
                                    } else {
                                      // Clear any previous error messages and set the selected file
                                      setAddEmply((prevAddEmply) => ({
                                        ...prevAddEmply,
                                        EmployeePhoto: selectedFile,
                                      }));
                                      setError((prevError) => ({
                                        ...prevError,
                                        EmployeePhoto: "",
                                      }));
                                    }
                                  }
                                } else {
                                  // Handle case when no file is selected
                                  setError((prevError) => ({
                                    ...prevError,
                                    EmployeePhoto: "Please select a file.",
                                  }));
                                }
                              }}

                            //required={!addEmply.EmployeePhoto ? true : false}
                            />
                            {/* <h1 className="enter-role-text mt-2">
                            Click Here...
                          </h1>                             */}

                            {error.EmployeePhoto.length > 0 && (
                              <div className="text-danger ">
                                {error.EmployeePhoto}
                              </div>
                            )}
                          </label>
                        </div>
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            First Name{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <input
                            onChange={handleChange}
                            value={addEmply.FirstName}
                            name="FirstName"
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            required
                          />
                          <span className="text-danger ">{errorMessage}</span>
                          {error.FirstName.length > 0 && (
                            <span className="text-danger ">
                              {error.FirstName}
                            </span>
                          )}
                          {!error.FirstName && responseError?.FirstName && (
                            <span className="text-danger ">
                              {responseError.FirstName}
                            </span>
                          )}
                        </div>
                        {/* <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Middle Name
                          </label>
                          <input
                            onChange={handleChange}
                            value={addEmply.MiddleName}
                            name="MiddleName"
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                          />

                          <span className="text-danger ">
                            {errorMessage2}
                          </span>
                          {error.MiddleName.length > 0 && (
                            <span className="text-danger ">
                              {error.MiddleName}
                            </span>
                          )}
                        </div> */}
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Last Name{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <input
                            onChange={handleChange}
                            value={addEmply.LastName}
                            name="LastName"
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            required
                          />
                          <span className="text-danger ">{errorMessage3}</span>
                          {error.LastName.length > 0 && (
                            <span className="text-danger ">
                              {error.LastName}
                            </span>
                          )}
                          {!error.LastName && responseError?.LastName && (
                            <span className="text-danger ">
                              {responseError.LastName}
                            </span>
                          )}
                        </div>

                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Father Name{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <input
                            onChange={handleChange}
                            name="FatherName"
                            value={addEmply.FatherName}
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            required
                          />
                          <span className="text-danger ">{errorMessage4}</span>
                          {error.FatherName.length > 0 && (
                            <div className="text-danger">
                              {error.FatherName}
                            </div>
                          )}
                          {!error.FatherName && responseError?.FatherName && (
                            <span className="text-danger ">
                              {responseError.FatherName}
                            </span>
                          )}
                        </div>
                        {/* <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Father Name
                          </label>
                          <input
                            onChange={handleChange}
                            name="StartDate"
                            value={adminPostData.StartDate}
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                          />
                        </div> */}

                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Mother Name{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <input
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            onChange={handleChange}
                            name="MothersName"
                            value={addEmply.MothersName}
                            required
                          />
                          <span className="text-danger ">{errorMessage5}</span>
                          {error.MothersName.length > 0 && (
                            <div className="text-danger">
                              {error.MothersName}
                            </div>
                          )}
                          {!error.MothersName && responseError?.MothersName && (
                            <span className="text-danger ">
                              {responseError.MothersName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="modalProp my-2">
                        <label
                          htmlFor=""
                          className="enter-role-text mt-2"
                          style={{ fontSize: "14px" }}
                        >
                          Religion{" "}
                          <span className="text-danger fw-bolder">*</span>
                        </label>
                        <input
                          type="text"
                          className="date-label-input tasKCategory1"
                          style={{
                            fontSize: "14px",
                            height: "38px",
                            border: "1px solid #ced4da",
                          }}
                          onChange={handleChange}
                          name="Religion"
                          value={addEmply.Religion}
                          required
                        />
                        <span className="text-danger ">{errorMessage6}</span>
                        {error.Religion.length > 0 && (
                          <div className="text-danger">{error.Religion}</div>
                        )}
                        {!error.Religion && responseError?.Religion && (
                          <span className="text-danger ">
                            {responseError.Religion}
                          </span>
                        )}
                      </div>
                      <div className="modalProp mb-2">
                        <label
                          htmlFor=""
                          className="enter-role-text mt-2"
                          style={{ fontSize: "14px" }}
                        >
                          Nationality{" "}
                          <span className="text-danger fw-bolder">*</span>
                        </label>
                        <input
                          type="text"
                          className="date-label-input tasKCategory1"
                          style={{
                            fontSize: "14px",
                            height: "38px",
                            border: "1px solid #ced4da",
                          }}
                          onChange={handleChange}
                          name="Nationality"
                          value={addEmply.Nationality}
                          required
                        />
                        <span className="text-danger ">{errorMessage7}</span>
                        {error.Nationality.length > 0 && (
                          <div className="text-danger">{error.Nationality}</div>
                        )}
                        {!error.Nationality && responseError?.Nationality && (
                          <span className="text-danger ">
                            {responseError.Nationality}
                          </span>
                        )}
                      </div>
                      <div className="modalProp mb-2">
                        <label
                          htmlFor=""
                          className="enter-role-text mt-2"
                          style={{ fontSize: "14px" }}
                        >
                          Gender{" "}
                          <span className="text-danger fw-bolder">*</span>
                        </label>
                        <select
                          class="form-select tasKCategory1 select-drop-icon"
                          aria-label="Default select example"
                          onChange={handleChange}
                          name="Gender"
                          value={addEmply.Gender}
                          required
                        >
                          <option selected>Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="TransGender">Others</option>
                        </select>
                        {error.Gender.length > 0 && (
                          <div className="text-danger">{error.Gender}</div>
                        )}
                        {!error.Gender && responseError?.Gender && (
                          <span className="text-danger ">
                            {responseError.Gender}
                          </span>
                        )}
                      </div>
                      <div className="modalProp mb-2">
                        <label
                          htmlFor=""
                          className="enter-role-text mt-2"
                          style={{ fontSize: "14px" }}
                        >
                          Blood Group{" "}
                          <span className="text-danger fw-bolder">*</span>
                        </label>
                        <select
                          class="form-select tasKCategory1 select-drop-icon"
                          aria-label="Default select example"
                          onChange={handleChange}
                          name="BloodGroup"
                          value={addEmply.BloodGroup}
                        >
                          <option value="">Select Blood Group</option>
                          <option value="A">A+</option>
                          <option value="B+">B+</option>
                          <option value="AB+">AB+</option>
                          <option value="AB-">AB-</option>
                          <option value="B-">B-</option>
                          <option value="O+">O+</option>
                          <option value="O-">O-</option>
                        </select>
                        {error.BloodGroup.length > 0 && (
                          <div className="text-danger ">{error.BloodGroup}</div>
                        )}
                      </div>

                      {/* <div className="modalProp mb-2">
                        <label
                          htmlFor=""
                          className="enter-role-text mt-2"
                          style={{ fontSize: "14px" }}
                        >
                          Identification Mark
                        </label>
                        <input
                          type="text"
                          className="date-label-input tasKCategory1"
                          style={{
                            fontSize: "14px",
                            height: "38px",
                            border: "1px solid #ced4da",
                          }}
                          onChange={handleChange}
                          name="IdentificationMark"
                          value={addEmply.IdentificationMark}
                        />
                        {error.IdentificationMark.length > 0 && (
                          <div className="text-danger ">
                            {error.IdentificationMark}
                          </div>
                        )}
                      </div> */}
                      <div className="modalProp mb-2">
                        <label
                          htmlFor=""
                          className="enter-role-text mt-2"
                          style={{ fontSize: "14px" }}
                        >
                          Marital Status{" "}
                          <span className="text-danger fw-bolder">*</span>
                        </label>
                        <select
                          class="form-select tasKCategory1 select-drop-icon"
                          aria-label="Default select example"
                          onChange={handleChange}
                          name="MaritalStatus"
                          value={addEmply.MaritalStatus}
                          required
                        >
                          <option selected>Select Marital Status</option>
                          <option value="single">single</option>
                          <option value="Married">Married</option>
                        </select>
                        {error.MaritalStatus.length > 0 && (
                          <div className="text-danger ">
                            {error.MaritalStatus}
                          </div>
                        )}
                        {!error.MaritalStatus &&
                          responseError?.MaritalStatus && (
                            <span className="text-danger ">
                              {responseError.MaritalStatus}
                            </span>
                          )}
                      </div>

                      <div className="modalProp mb-2">
                        <label
                          htmlFor=""
                          className="enter-role-text mt-2"
                          style={{ fontSize: "14px" }}
                        >
                          Date of Birth{" "}
                          <span className="text-danger fw-bolder">*</span>
                        </label>
                        <input
                          onChange={handleChange}
                          value={addEmply.DateOfBirth}
                          name="DateOfBirth"
                          type="date"
                          className="date-label-input tasKCategory1"
                          style={{
                            fontSize: "14px",
                            height: "38px",
                            border: "1px solid #ced4da",
                          }}
                          required
                        />
                        {error.DateOfBirth.length > 0 && (
                          <div className="text-danger">{error.DateOfBirth}</div>
                        )}
                        {!error.DateOfBirth && responseError?.DateOfBirth && (
                          <span className="text-danger ">
                            {responseError.DateOfBirth}
                          </span>
                        )}
                      </div>

                      <div className="modalProp mb-2">
                        <label
                          htmlFor=""
                          className="enter-role-text mt-2"
                          style={{ fontSize: "14px" }}
                        >
                          Qualification <span className="text-danger fw-bolder">*</span>
                        </label>
                        <input
                          type="text"
                          className="date-label-input tasKCategory1"
                          style={{
                            fontSize: "14px",
                            height: "38px",
                            border: "1px solid #ced4da",
                          }}
                          onChange={handleChange}
                          name="qualification"
                          value={addEmply.qualification}
                        />
                        {error.qualification.length > 0 && !addEmply.qualification && (
                          <div className="text-danger ">
                            {error.qualification}
                          </div>
                        )}
                      </div>

                      {/* <div className="modalProp mb-2">
                        <label
                          htmlFor=""
                          className="enter-role-text mt-2"
                          style={{ fontSize: "14px" }}
                        >
                          Skill Set g
                        </label>
                        <div className="d-flex">
                        <input
                          type="text"
                          className="date-label-input tasKCategory1"
                          style={{
                            fontSize: "14px",
                            height: "38px",
                            border: "1px solid #ced4da",
                            marginRight:"2px"
                          }}
                          onChange={(e)=>setSkill(e.target.value)}
                          name="skillSet"
                          value={skill}
                        />
                        
                        <select
                          class="form-select tasKCategory1 select-drop-icon"
                          aria-label="Default select example"
                          onChange={handleChange}
                          name="MaritalStatus"
                          value={addEmply.MaritalStatus}
                          required
                        >
                          <option selected>Select proficiency levels</option>
                          <option value="Beginner">Beginner</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Advanced">Advanced</option>
                          <option value="Expert">Expert</option>
                        </select>
                        <button className="nextBtn" type="button"
                        onClick={()=>{
                          if(skill.length>0){
                          setAddEmply(prev=>({
                            ...prev,
                            skillSet:[...addEmply.skillSet,skill]
                          }))
                          setSkill('')
                        }
                        }}
                        >+</button>
                        </div>
                        {error.skillSetErr.length > 0 && (
                          <div className="text-danger ">
                            {error.skillSetErr}
                          </div>
                        )}
                      </div>
                     
                      <div className="row ml-1">
                        {
                          addEmply.skillSet?.map((skill,index)=>{
                            return <div className="col-md-3 gap-2 d-flex justify-content-between" style={{
                              width:'150px',
                              border :'1px solid grey',
                              borderRadius:'5px',
                              marginLeft:'3px'
                              }}> <p key={index} >{skill}</p>
                            <span style={{cursor:'pointer'}} onClick={()=>{

                               let removeSkills= addEmply.skillSet.filter((_,indx)=>{
                                return index!=indx
                               })

                               setAddEmply(prev=>({
                                ...prev,
                                skillSet:removeSkills
                               }))

                            }}>x</span>
                            </div>
                          })
                        }
                      </div> */}

                      {/* <div className="modalProp mb-2">
                        <label
                          htmlFor=""
                          className="enter-role-text mt-2"
                          style={{ fontSize: "14px" }}
                        >
                          Skill Set <span className="text-danger fw-bolder">*</span>
                        </label>
                        <div className="d-flex">
                          <input
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                              marginRight: "2px",
                            }}
                            onChange={(e) => setSkill(e.target.value)}
                            name="skillSet"
                            value={skill}
                          />


                          <select
                            className="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            onChange={(e) => setProficiency(e.target.value)}
                            name="MaritalStatus"
                            value={proficiency}
                            required
                          >
                            <option value="" disabled selected>
                              Select proficiency levels
                            </option>
                            <option value="Beginner">Beginner</option>
                            <option value="Intermediate">Intermediate</option>
                            <option value="Advanced">Advanced</option>
                            <option value="Expert">Expert</option>
                          </select>
                          <button
                            className="nextBtn"
                            type="button"
                            onClick={() => {
                              if (skill.length > 0 && proficiency.length > 0) {
                                setAddEmply((prev) => ({
                                  ...prev,
                                  skillSet: [
                                    ...prev.skillSet,
                                    { skillName: skill, proficiencyLevel: proficiency },
                                  ],
                                }));
                                setSkill('');
                                setProficiency('');
                              }
                            }}
                          >
                            +
                          </button>

                        </div>
                      </div> */}
                      {error.skillSetErr.length > 0 &&
                        !addEmply.skillSet[0] && (
                          <div className="text-danger ">
                            {error.skillSetErr}
                          </div>
                        )}

                      <div className="row ml-1">
                        {addEmply.skillSet?.map((item, index) => (
                          <div
                            key={index}
                            className="col-md-3 gap-2 d-flex justify-content-between"
                            style={{
                              width: "200px",
                              border: "1px solid grey",
                              borderRadius: "5px",
                              marginLeft: "3px",
                            }}
                          >
                            <p>{item.skillName}</p>
                            <p>({item.proficiencyLevel})</p>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const updatedSkills = addEmply.skillSet.filter(
                                  (_, indx) => index !== indx
                                );

                                setAddEmply((prev) => ({
                                  ...prev,
                                  skillSet: updatedSkills,
                                }));
                              }}
                            >
                              x
                            </span>
                          </div>
                        ))}
                      </div>
                      {console.log("gggg-->", addEmply.skillSet)}
                      {/* <div className="modalProp mb-2">
                        <label
                          htmlFor=""
                          className="enter-role-text mt-2"
                          style={{ fontSize: "14px" }}
                        >
                          GSM Id
                        </label>
                        <input
                          type="text"
                          className="date-label-input tasKCategory1"
                          style={{
                            fontSize: "14px",
                            height: "38px",
                            border: "1px solid #ced4da",
                          }}
                          onChange={handleChange}
                          value={addEmply.GSMIId}
                          name="GSMIId"
                        />
                      </div> */}
                      {/* <div className="modalProp mb-2">
                        <label
                          htmlFor=""
                          className="enter-role-text mt-2"
                          style={{ fontSize: "14px" }}
                        >
                          Transcard Id
                        </label>
                        <input
                          type="text"
                          className="date-label-input tasKCategory1"
                          style={{
                            fontSize: "14px",
                            height: "38px",
                            border: "1px solid #ced4da",
                          }}
                          onChange={handleChange}
                          value={addEmply.TranscardId}
                          name="TranscardId"
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center mt-2">
                    <button
                      className="nextBtn"
                      onClick={(e) => {
                        handleScreenNextTab(e);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </Tab>
                <Tab eventKey="contactDetails" title="Contact Details">
                  <div>
                    <div className="row">
                      <div className="col-md-12 col-lg-6">
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Work Email{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <input
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            onChange={handleChange}
                            name="WorkEmail"
                            value={addEmply.WorkEmail}
                            required
                          />
                          {error.WorkEmail.length > 0 && (
                            <div className="text-danger">{error.WorkEmail}</div>
                          )}
                          {!error.WorkEmail && responseError?.WorkEmail && (
                            <span className="text-danger ">
                              {responseError.WorkEmail}
                            </span>
                          )}
                        </div>
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Other Email{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <input
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            onChange={handleChange}
                            name="OtherEmail"
                            value={addEmply.OtherEmail}
                            required
                          />
                          {error.OtherEmail.length > 0 && (
                            <div className="text-danger">
                              {error.OtherEmail}
                            </div>
                          )}
                        </div>
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Mobile Phone{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <input
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            maxLength={10}
                            minLength={10}
                            // onChange={handleChange}
                            onChange={(e) => {
                              if (!isNaN(e.target.value)) {
                                setAddEmply({
                                  ...addEmply,
                                  MobilePhone: e.target.value,
                                });
                              }
                              setError({
                                ...error,
                                MobilePhone: "",
                              });
                            }}
                            name="MobilePhone"
                            value={addEmply.MobilePhone}
                            required
                          />
                          {error.MobilePhone.length > 0 && (
                            <div className="text-danger">
                              {error.MobilePhone}
                            </div>
                          )}
                          {!error.MobilePhone && responseError?.MobilePhone && (
                            <span className="text-danger ">
                              {responseError.MobilePhone}
                            </span>
                          )}
                        </div>
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Work Phone{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <input
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            maxLength={10}
                            minLength={10}
                            // onChange={handleChange}
                            onChange={(e) => {
                              if (!isNaN(e.target.value)) {
                                setAddEmply({
                                  ...addEmply,
                                  WorkTelephone: e.target.value,
                                });
                              }
                              setError({
                                ...error,
                                WorkTelephone: "",
                              });
                            }}
                            name="WorkTelephone"
                            value={addEmply.WorkTelephone}
                            required
                          />
                          {error.WorkTelephone.length > 0 && (
                            <div className="text-danger">
                              {error.WorkTelephone}
                            </div>
                          )}
                          {!error.WorkTelephone && responseError?.WorkTelephone && (
                            <span className="text-danger ">
                              {responseError.WorkTelephone}
                            </span>
                          )}
                        </div>
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Emergency Contact No.{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <input
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            minLength={10}
                            maxLength={10}
                            // onChange={handleChange}
                            onChange={(e) => {
                              if (!isNaN(e.target.value)) {
                                setAddEmply({
                                  ...addEmply,
                                  EmergencyContact: e.target.value,
                                });
                              }
                              setError({
                                ...error,
                                EmergencyContact: "",
                              });
                            }}
                            name="EmergencyContact"
                            value={addEmply.EmergencyContact}
                            required
                          />
                          {error.EmergencyContact.length > 0 && (
                            <div className="text-danger">
                              {error.EmergencyContact}
                            </div>
                          )}
                        </div>
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Emergency Contact Person{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <input
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            onChange={handleChange}
                            name="EmergencyContactPerson"
                            value={addEmply.EmergencyContactPerson}
                            required
                          />
                          {error.EmergencyContactPerson.length > 0 && (
                            <span className="text-danger ">
                              {error.EmergencyContactPerson}
                            </span>
                          )}
                        </div>
                        {/* <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Home Phone
                          </label>
                          <input
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            onChange={(e) => {
                              if (!isNaN(e.target.value)) {
                                setAddEmply({
                                  ...addEmply,
                                  HomePhone: e.target.value,
                                });
                              }
                              setError({
                                ...error,
                                HomePhone: "",
                              });
                            }}
                            maxLength={10}
                            minLength={10}
                            // onChange={handleChange}
                            name="HomePhone"
                            value={addEmply.HomePhone}
                          />
                          {error.HomePhone.length > 0 && (
                            <span className="text-danger ">
                              {error.HomePhone}
                            </span>
                          )}
                        </div>
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Extension
                          </label>
                          <input
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            onChange={handleChange}
                            name="Extension"
                            value={addEmply.Extension}
                          />
                          {error.Extension.length > 0 && (
                            <span className="text-danger ">
                              {error.Extension}
                            </span>
                          )}
                        </div> */}
                      </div>

                      <div className="col-md-12 col-lg-6">
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Current Address{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <textarea
                            rows="4"
                            className="tasKCategory1"
                            onChange={handleChange}
                            name="Address"
                            value={addEmply.Address}
                            required
                            style={{ height: "20%" }}
                          ></textarea>
                          {error.Address.length > 0 && (
                            <div className="text-danger">{error.Address}</div>
                          )}
                          {!error.Address && responseError?.Address && (
                            <span className="text-danger ">
                              {responseError.Address}
                            </span>
                          )}
                        </div>

                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Permanent Address{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <textarea
                            rows="4"
                            className=" tasKCategory1"
                            onChange={handleChange}
                            name="PermanentAddress"
                            value={addEmply.PermanentAddress}
                            style={{ height: "20%" }}
                            required
                          ></textarea>
                          {error.PermanentAddress.length > 0 && (
                            <div className="text-danger">
                              {error.PermanentAddress}
                            </div>
                          )}
                        </div>

                        {/* <div className="modalProp mt-3">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Country
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <CountrySelect
                            onChange={(e) => {
                              console.log(e);
                              setCountryid(e.id);
                            }}
                            placeHolder="Select Country"
                          />
                          {error.Country.length > 0 && (
                            <div className="text-danger">{error.Country}</div>
                          )}
                          {!error.Country && responseError?.Country && (
                            <span className="text-danger ">
                              {responseError.Country}
                            </span>
                          )}

                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            State/Province{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>

                          <StateSelect
                            countryid={countryid}
                            onChange={(e) => {
                              setstateid(e.id);
                            }}
                            placeHolder="Select State"
                          />
                          {error.State.length > 0 && (
                            <div className="text-danger">{error.State}</div>
                          )}
                          {!error.State && responseError?.State && (
                            <span className="text-danger ">
                              {responseError.State}
                            </span>
                          )}

                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            City{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <CitySelect
                            countryid={countryid}
                            stateid={stateid}
                            onChange={(e) => {
                              console.log(e);
                            }}
                            placeHolder="Select City"
                          />
                          {error.City.length > 0 && (
                            <div className="text-danger">{error.City}</div>
                          )}
                          {!error.City && responseError?.City && (
                            <span className="text-danger ">
                              {responseError.City}
                            </span>
                          )}
                        </div> */}

                        <div className="modalProp mt-3">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Country{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <select
                            class="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            onChange={(e) => {

                              const selectedCountry = countryList?.countries?.find((country) => e.target.value === country?.name)

                              handleChange(e)
                              getState(selectedCountry?.Countries_id)
                            }}
                            value={addEmply.Country}
                            name="Country"
                            required
                          >
                            <option selected>Select Country</option>
                            {countryList?.countries?.map((country, index) => {
                              return (
                                <option value={country?.name}>
                                  {country?.name}
                                </option>
                              );
                            })}
                          </select>
                          {error.Country.length > 0 && (
                            <div className="text-danger">{error.Country}</div>
                          )}
                          {!error.Country && responseError?.Country && (
                            <span className="text-danger ">
                              {responseError.Country}
                            </span>
                          )}
                        </div>

                        <div className="modalProp my-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            State/Province{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <select
                            class="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            onChange={(e) => {

                              const selectedState = stateList.find((state) => state.Name === e.target.value)

                              handleChange(e)
                              getCities(selectedState?.Id)

                            }}
                            value={addEmply.State}
                            name="State"
                            required
                            disabled={!addEmply.Country}
                          >
                            <option selected>Select State</option>
                            {
                              stateList?.map((state) => {
                                return <option value={state?.Name}>{state?.Name}</option>
                              })
                            }

                          </select>

                          {error.State.length > 0 && (
                            <div className="text-danger">{error.State}</div>
                          )}
                          {!error.State && responseError?.State && (
                            <span className="text-danger ">
                              {responseError.State}
                            </span>
                          )}
                        </div>

                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            City{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <select
                            class="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            onChange={handleChange}
                            value={addEmply.City}
                            name="City"
                            required
                            disabled={!addEmply.State}
                          >
                            <option selected>Select City</option>
                            {
                              cities?.map((city, index) => {
                                return <option value={city?.Name} key={index}>{city?.Name}</option>
                              })
                            }

                          </select>
                          {error.City.length > 0 && (
                            <div className="text-danger">{error.City}</div>
                          )}
                          {!error.City && responseError?.City && (
                            <span className="text-danger ">
                              {responseError.City}
                            </span>
                          )}
                        </div>

                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Zip/Postal Code{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <input
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            onChange={handleChange}
                            name="ZipCode"
                            value={addEmply.ZipCode}
                            required
                          />
                          {error.ZipCode.length > 0 && (
                            <div className="text-danger">{error.ZipCode}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end align-items-center mt-2">
                    <button
                      className="prevBtn"
                      type="button"
                      onClick={() => setKey("personalDetails")}
                    >
                      Previous
                    </button>
                    <button
                      className="nextBtn"
                      onClick={(e) => {
                        handleScreenNextTab(e);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </Tab>
                <Tab
                  eventKey="professionalDetails"
                  title="Professional Details"
                >
                  <Tab.Content>
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Department{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <select
                            class="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            onChange={handleChange}
                            value={addEmply.DepartmentId}
                            name="DepartmentId"
                            required
                          >
                            <option value="">Select Department</option>
                            {departmentlist &&
                              departmentlist.map((dept, index) => {
                                return (
                                  <option value={dept.Id}>
                                    {dept.DepartmentName}
                                  </option>
                                );
                              })}
                          </select>
                          {/* {!error.DepartmentId && responseError?.DepartmentId && (
                            <span className="text-danger ">
                              {responseError.DepartmentId}
                            </span>
                          )} */}
                          {error.DepartmentId.length > 0 && (
                            <div className="text-danger">
                              {error.DepartmentId}
                            </div>
                          )}
                        </div>
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Designation{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <select
                            class="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            onChange={handleChange}
                            name="DesignationId"
                            value={addEmply.DesignationId}
                            required
                          >
                            <option value="">Select Designation</option>
                            {designationsname &&
                              designationsname.map((desi, index) => {
                                return (
                                  <option value={desi.DesignationId}>
                                    {desi.Name}
                                  </option>
                                );
                              })}
                          </select>
                          {error.DesignationId.length > 0 && (
                            <div className="text-danger">
                              {error.DesignationId}
                            </div>
                          )}
                          {!error.DesignationId &&
                            responseError?.DesignationId && (
                              <span className="text-danger ">
                                {responseError.DesignationId}
                              </span>
                            )}
                        </div>
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Location{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <select
                            class="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            onChange={handleChange}
                            name="LocationId"
                            value={addEmply.LocationId}
                            required
                          >
                            <option value="">Select Location</option>

                            {location &&
                              location.map((loc) => {
                                return (
                                  <option value={loc.LocationId}>
                                    {loc.LocationName}
                                  </option>
                                );
                              })}
                          </select>
                          {error.LocationId.length > 0 && (
                            <div className="text-danger">
                              {error.LocationId}
                            </div>
                          )}
                          {!error.LocationId && responseError?.LocationId && (
                            <span className="text-danger ">
                              {responseError.LocationId}
                            </span>
                          )}
                        </div>

                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Shift{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <select
                            class="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            onChange={handleChange}
                            name="ShiftId"
                            value={addEmply.ShiftId}
                            required
                          >
                            <option value="">Select Shift</option>
                            {shift &&
                              shift.map((shif) => {
                                return (
                                  <option value={shif.ShiftId}>
                                    {shif.ShiftName} ( {shif?.ShiftTime})
                                  </option>
                                );
                              })}
                          </select>
                          {error.ShiftId.length > 0 && (
                            <div className="text-danger">{error.ShiftId}</div>
                          )}
                          {!error.ShiftId && responseError?.ShiftId && (
                            <span className="text-danger ">
                              {responseError.ShiftId}
                            </span>
                          )}
                        </div>
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Probation Period{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <input
                            type="text"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            placeholder="Please Enter Probation Period in Days"
                            onChange={handleChange}
                            name="ProbationPeriod"
                            value={addEmply.ProbationPeriod}
                            required
                          />
                          {!error.ProbationPerioderror.length > 0 && (
                            <div className="text-danger ">
                              {error.ProbationPerioderror}
                            </div>
                          )}
                          {!error.ProbationPeriod &&
                            responseError?.ProbationPeriod && (
                              <span className="text-danger ">
                                {responseError.ProbationPeriod}
                              </span>
                            )}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        {/* <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Grade
                          </label>
                          <select
                            class="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            onChange={handleChange}
                            name="Grade"
                            value={addEmply.Grade}
                          >
                            <option value="">Select Grade</option>
                            <option value="A+">A+</option>
                            <option value="B+">B+</option>
                          </select>
                          {error.Grade.length > 0 && (
                            <div className="text-center text-danger">
                              {error.Grade}
                            </div>
                          )}
                          {!error.NatureOfPost && errorResponse && (
                            <span className="text-danger ">
                              {"The value '' is invalid."}
                            </span>
                          )}
                        </div> */}
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Nature of post{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <select
                            class="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            onChange={handleChange}
                            name="NatureOfPost"
                            value={addEmply.NatureOfPost}
                            required
                          >
                            <option value="">Select Nature of post</option>
                            <option value="contract"> contract </option>
                            <option value="Internship">Internship</option>
                            <option value="Permanent"> Permanent </option>
                          </select>
                          {error.NatureOfPost.length > 0 && (
                            <div className="text-danger">
                              {error.NatureOfPost}
                            </div>
                          )}
                          {!error.NatureOfPost &&
                            responseError?.NatureOfPost && (
                              <span className="text-danger ">
                                {responseError.NatureOfPost}
                              </span>
                            )}
                        </div>
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Date of Joining{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <input
                            onChange={handleChange}
                            name="DateOfJoining"
                            value={addEmply.DateOfJoining}
                            type="date"
                            className="date-label-input tasKCategory1"
                            style={{
                              fontSize: "14px",
                              height: "38px",
                              border: "1px solid #ced4da",
                            }}
                            required
                          />
                          {error.DateOfJoining.length > 0 && (
                            <div className="text-danger">
                              {error.DateOfJoining}
                            </div>
                          )}
                          {!error.DateOfJoining &&
                            responseError?.DateOfJoining && (
                              <span className="text-danger ">
                                {responseError.DateOfJoining}
                              </span>
                            )}
                        </div>
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Reporting to{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <select
                            class="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            onChange={handleChange}
                            name="ReportingId"
                            value={addEmply.ReportingId}
                            required
                          >
                            <option value="">Select Reporting Person</option>
                            {employeeattlist &&
                              employeeattlist?.map((reporting) => {
                                return (
                                  <option value={reporting.Id}>
                                    {reporting.FirstName}
                                  </option>
                                );
                              })}
                          </select>
                          {error.ReportingId.length > 0 && (
                            <div className="text-danger">
                              {error.ReportingId}{" "}
                            </div>
                          )}
                          {!error.ReportingId && responseError?.ReportingId && (
                            <span className="text-danger ">
                              {responseError.ReportingId}
                            </span>
                          )}
                        </div>
                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Role Name{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </label>
                          <select
                            class="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            onChange={handleChange}
                            name="RoleId"
                            value={addEmply.RoleId}
                            required
                          >
                            <option value="">Select Role Name</option>
                            {role &&
                              role.map((rle) => {
                                return (
                                  <option value={rle.RoleId}>{rle.Name}</option>
                                );
                              })}
                          </select>
                          {!error.RoleId && responseError?.RoleId && (
                            <span className="text-danger ">
                              {responseError.RoleId}
                            </span>
                          )}
                        </div>



                        <div className="modalProp mb-2">
                          <label
                            htmlFor=""
                            className="enter-role-text mt-2"
                            style={{ fontSize: "14px" }}
                          >
                            Skill Set
                          </label>
                          <div className="d-flex">
                            <input
                              type="text"
                              className="date-label-input tasKCategory1"
                              style={{
                                fontSize: "14px",
                                height: "38px",
                                border: "1px solid #ced4da",
                                marginRight: "2px",
                              }}
                              onChange={(e) => setSkill(e.target.value)}
                              name="skillSet"
                              value={skill}
                            />

                            <select
                              class="form-select tasKCategory1 select-drop-icon"
                              aria-label="Default select example"
                              onChange={(e) => setProficiency(e.target.value)}
                              name="MaritalStatus"
                              value={proficiency}
                              required
                            >
                              <option selected>
                                Select proficiency levels
                              </option>
                              <option value="Beginner">Beginner</option>
                              <option value="Intermediate">Intermediate</option>
                              <option value="Advanced">Advanced</option>
                              <option value="Expert">Expert</option>
                            </select>
                            <button
                              className="nextBtn"
                              type="button"
                              onClick={() => {
                                if (skill.length > 0) {
                                  setAddEmply((prev) => ({
                                    ...prev,
                                    skillSet: [...addEmply.skillSet, {
                                      skillName: skill,
                                      proficiencyLevel: proficiency
                                    }],
                                  }));
                                  setSkill("");
                                  setProficiency('')
                                }
                              }}
                            >
                              +
                            </button>
                          </div>
                          {error.skillSetErr.length > 0 && (
                            <div className="text-danger ">
                              {error.skillSetErr}
                            </div>
                          )}
                        </div>

                        <div className="row ml-1">
                          {addEmply.skillSet?.map((skill, index) => {
                            return (
                              <div
                                className="col-md-3 gap-2 d-flex justify-content-between"
                                style={{
                                  width: "150px",
                                  border: "1px solid grey",
                                  borderRadius: "5px",
                                  marginLeft: "3px",
                                }}
                              >
                                {" "}
                                <p key={index}>{skill.skillName}</p>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    let removeSkills = addEmply.skillSet.filter(
                                      (_, indx) => {
                                        return index != indx;
                                      }
                                    );

                                    setAddEmply((prev) => ({
                                      ...prev,
                                      skillSet: removeSkills,
                                    }));
                                  }}
                                >
                                  x
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Tab.Content>
                  <div className="d-flex justify-content-end align-items-center mt-2">
                    <button
                      className="prevBtn"
                      type="button"
                      onClick={() => handlezPrevious()}
                    >
                      Previous
                    </button>
                    <button
                      className="nextBtn"
                      type="button"
                      onClick={(e) => {
                        handleScreenNextTab(e);
                        AddEmployeeApi();
                      }}
                    >
                      Add
                    </button>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Form>
        </div>
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        show={showPersonal}
        onHide={() => setShowPersonal(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Import Employees
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShowPersonal(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          {/* <div className="import-export-btn-wrapper mt-4"> */}
          <label>Download Format:</label>
          <button
            style={{ border: "none", background: "white" }}
            onClick={onDowmlodEcelFormat}
          >
            {excelLoading ? (
              <ExcelLoader />
            ) : (
              <img
                src={excelicon}
                onClick={(e) => downloadExcelFile(e)}
                alt=""
              />
            )}
          </button>
          {/* </div> */}
          <div>
            <label>Import your file</label>
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              //accept=".xlsx"
              id="imgHolder"
              required
              className="form-control tasKCategory1"
              name=" EmployeeFile"
              onChange={handleFileChange}
            // onChange={(e) => {
            //   const selectedFile = e.target.files[0];
            //   // Check if a file is selected
            //   if (selectedFile) {
            //     console.log("Selected file size:", selectedFile.size);
            //     // Check if the file size exceeds 4MB
            //     if (selectedFile.size > 4 * 1024 * 1024) {
            //       console.log("File size exceeds 4MB");
            //       setError({
            //         ...error,
            //         EmployeeFile: "File size should not exceed 4MB.",
            //       });
            //     } else {
            //       setAddEmply({
            //         ...addEmply,
            //         EmployeeFile: e.target.files[0],
            //       });
            //       setError({
            //         ...error,
            //         EmployeeFile: "",
            //       });
            //     }
            //   }
            // }}

            // required={!addEmply.EmployeePhoto ? true : false}
            />
            {/* <input
        type="file"
        accept=".xlsx, .xls"
        id="fileInput"
        required
        className="form-control"
        onChange={handleFileChange}
      /> */}
          </div>
        </Modal.Body>

        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div className="Bottom-btn-arbaz-responsive">
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={() => setShowPersonal(false)}
            >
              Cancel
            </button>
            <button
              onClick={importemployee}
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
            >
              Import
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddEmployee;
