import BookIcon from "../../assets/images/ic_excel.svg";
import Modal from "react-bootstrap/Modal";
import Pagination from "../../sharedComponent/Pagination";
import React, { useContext, useEffect, useRef, useState } from "react";
import deleteicon from "../../assets/images/DelectAc.svg";
import edit from "../../assets/images/Editac.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import ApiConfig from "../../api/ApiConfig";
import {
  simpleGetCallWithToken,
  DeleteCallWithErrorResponseWithToken,
} from "../../api/ApiServices";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { ToastMsg } from "../TostMsg";



const WhatsappSetting = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const deleteid = useRef();
  const handleShow3 = (item) => {
    setShow3(true);
    console.log("item--->", item.id);
    deleteid.current = item.id;
  };
  const [selectedRows, setSelectedRows] = useState(25);
  const [whatsAppList, setWhatsAppList] = useState([]);
  const naviget = useNavigate();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [searchParams] = useSearchParams();



  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };

  const dataArrayLocation = [
    {
      id: 1,
      WHATSAPP: "273903359143669",
      Token:
        "EAA0LrQxvdQABOyzCyT4MhZAFcnrdZBZCOJiIbo1HuzwOIRWS1o8dSWeO9cb1hK092o5vTAzzhoGTo2z6NpdvQBjZBsknbbna3pCI0oEkaUtTu3f8yr6NdExzyI6IxFbFZA1MnlYxHLomv9VzutcdQdNRkjnJhwrzhoQtxehK9TiZBJOy0dzxu4xC6CCytMs855",
      Sources: "Testbytes",
      Action: "Delete",
    },

    {
      id: 2,
      WHATSAPP: "273903359143669",
      Token:
        "EAA0LrQxvdQABOyzCyT4MhZAFcnrdZBZCOJiIbo1HuzwOIRWS1o8dSWeO9cb1hK092o5vTAzzhoGTo2z6NpdvQBjZBsknbbna3pCI0oEkaUtTu3f8yr6NdExzyI6IxFbFZA1MnlYxHLomv9VzutcdQdNRkjnJhwrzhoQtxehK9TiZBJOy0dzxu4xC6CCytMs855",
      Sources: "Cloud Xperte",
      Action: "Delete",
    },
    {
      id: 3,
      WHATSAPP: "273903359143669",
      Token:
        "EAA0LrQxvdQABOyzCyT4MhZAFcnrdZBZCOJiIbo1HuzwOIRWS1o8dSWeO9cb1hK092o5vTAzzhoGTo2z6NpdvQBjZBsknbbna3pCI0oEkaUtTu3f8yr6NdExzyI6IxFbFZA1MnlYxHLomv9VzutcdQdNRkjnJhwrzhoQtxehK9TiZBJOy0dzxu4xC6CCytMs855",
      Sources: "Redbytes",
      Action: "Delete",
    },
    {
      id: 4,
      WHATSAPP: "273903359143669",
      Token:
        "EAA0LrQxvdQABOyzCyT4MhZAFcnrdZBZCOJiIbo1HuzwOIRWS1o8dSWeO9cb1hK092o5vTAzzhoGTo2z6NpdvQBjZBsknbbna3pCI0oEkaUtTu3f8yr6NdExzyI6IxFbFZA1MnlYxHLomv9VzutcdQdNRkjnJhwrzhoQtxehK9TiZBJOy0dzxu4xC6CCytMs855",
      Sources: "Vehicle Tracking",
      Action: "Delete",
    },
    {
      id: 5,
      WHATSAPP: "273903359143669",
      Token:
        "EAA0LrQxvdQABOyzCyT4MhZAFcnrdZBZCOJiIbo1HuzwOIRWS1o8dSWeO9cb1hK092o5vTAzzhoGTo2z6NpdvQBjZBsknbbna3pCI0oEkaUtTu3f8yr6NdExzyI6IxFbFZA1MnlYxHLomv9VzutcdQdNRkjnJhwrzhoQtxehK9TiZBJOy0dzxu4xC6CCytMs855",
      Sources: "Lywo",
      Action: "Delete",
    },

    {
      id: 6,
      WHATSAPP: "273903359143669",
      Token:
        "EAA0LrQxvdQABOyzCyT4MhZAFcnrdZBZCOJiIbo1HuzwOIRWS1o8dSWeO9cb1hK092o5vTAzzhoGTo2z6NpdvQBjZBsknbbna3pCI0oEkaUtTu3f8yr6NdExzyI6IxFbFZA1MnlYxHLomv9VzutcdQdNRkjnJhwrzhoQtxehK9TiZBJOy0dzxu4xC6CCytMs855",
      Sources: "Track School Bus",
      Action: "Delete",
    },
  ];

  const splitToken = (token, chunkSize) => {
    const chunks = [];
    for (let i = 0, len = token.length; i < len; i += chunkSize) {
      chunks.push(token.substring(i, i + chunkSize));
    }
    return chunks.join("<br/>");
  };

  const getWhatsAppList = () => {
    setLoading(true);



    simpleGetCallWithToken(
      userData?.CRMUrlNode + ApiConfig.GETWHATSAPPLIST
    )
      .then((res) => {
        console.log("whatsAppList--->", res);
        // if (res.success === true) {
        setLoading(false);

        setWhatsAppList(res?.data);
        setTotalPage(res.total_pages);

        // }
      })
      .catch((err) => {
        console.log("whatsAppList Error--->", err);
      });
  };

  useEffect(() => {
    getWhatsAppList();
  }, []);

  const navigetion = (name, e) => {
    e?.preventDefault();
    naviget(name);
  };

  const onEditeWhatsAppData = (item) => {
    console.log("edite data-->", item);
  };

  const onDelete = (e) => {
    e.preventDefault();
    setLoading(true);



    DeleteCallWithErrorResponseWithToken(
      userData?.CRMUrlNode + ApiConfig?.GETWHATSAPPDELETE + deleteid.current
    )
      .then((res) => {
        console.log("delete Response--->", res.json.message);
        if (res.json.message === "Token deleted successfully" || res?.json?.success) {
          console.log("delete whatsApp--->", res);
          ToastMsg("success", res.json.message);
          setShow3(false);
          getWhatsAppList();
          setLoading(false);
        } else {
          ToastMsg("error", res.message);
          setShow3(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShow3(false);
      });
  };

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">WhatsApp Settings</h6>
            <Link
              style={{ color: "var(--blue_53B7E8_LightMode" }}
              to={"/CreateToken"}
            >
              <button className="create-btn-sub-header" style={{ width: "170px" }}>
                Add WhatsApp Source
              </button>
            </Link>
          </div>
          <div className="d-flex justify-content-between mainHeader custom-mb mt-4">
            <div className="role-table-header-main">
              <div className="search-input-wrapper mt-0 cus-mb">
                <input
                  type="text"
                  placeholder="Search here"
                  onChange={(e) => setSearch(e.target.value)}
                />

                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper mt-0 cus-mb">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  value={selectedRows}
                  onChange={handleSelectChange}
                >
                  <option value={10}>10</option>
                  <option value={25} selected>
                    25
                  </option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-between mainIcons">
              <div className="booksIcon" style={{ textAlign: "center" }}>
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            </div>
          </div>

          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table className="">
              <thead className="firstTabelRow">
                <tr classname="row-hover modal-footer-table-body-tr">
                  <th>WHATSAPP PHONE NUMBER ID</th>
                  <th>TOKEN</th>
                  <th>SOURCE</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <Loader />
                ) : (
                  whatsAppList?.map((item) => (
                    <tr key={item.id} className="table-row-custom">
                      <td>{item.phone_number_id}</td>
                      <td
                        className="token-column"
                        dangerouslySetInnerHTML={{
                          __html: splitToken(item.token, 40),
                        }}
                      ></td>
                      <td className="Source">{item.source}</td>
                      <td>
                        <div className="d-flex">
                          <div onClick={() => handleShow3(item)}>
                            <img
                              src={deleteicon}
                              alt="Image 2"
                              className="cell-image "
                            />
                          </div>

                          <Link to={`/Updatewhatsapp/${item.id}`}>
                            <img
                              src={edit}
                              alt="Image 1"
                              className="cell-image ml-2"
                            />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            <Pagination
              data={whatsAppList}
              pageChangeHandler={pageChangeHandler}
              usersPerPage={selectedRows}
              currentPage={currentPage}
              searchValue={search}
              searchedDataPageCounts={totalPage}
            />
          </div>
        </div>
      </div>

      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete this WhatsApp ?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button className="btn-width saveBtn" onClick={onDelete}>
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default WhatsappSetting;
