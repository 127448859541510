import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import verticle from "../../assets/images/verticle_line.svg";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import plus from "../../assets/images/Union_10.svg";
import Edit from "../../assets/images/edit.svg";
import close from "../../assets/images/close.svg";
import editicon from "../../assets/images/bx_edit.svg";
import ApiConfig from "../../api/ApiConfig";
import { Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  PostCallWithErrorResponse,
  DeleteCallWithErrorResponseWithToken,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ReactPaginate from "react-paginate";
import Beatloader from "../../sharedComponent/Beatloader";
import Loader from "../Loader";
import { notifyError, notifySuccess } from "../../toaster/notify";
import { ClipLoader } from "react-spinners";
import { filter } from "lodash";
import { useDebounce } from "../../common";
import NoData from "../../sharedComponent/NoData";
// import { useDownloadExcel } from "react-export-table-to-excel";
import { ToastMsg } from "../TostMsg";
import DelectAcBlc from "../../assets/images/DelectAcBlc.svg";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const Role = () => {
  const { sidebar, permissions } = useContext(AppContext);

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleName, setRoleName] = useState({
    name: "",
  });
  const [editRoleNameObj, setEditRoleNameObj] = useState({
    id: 0,
    name: "",
  });
  const [validated, setValidated] = useState(false);
  const Route = useLocation().pathname;
  const navigate = useNavigate();

  const [userRoleList, setUserRoleList] = useState([]);

  //Confirmation popup
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [holdProgress, setHoldProgress] = useState("");
  const [deletercd, setdeletercd] = useState("");
  const [useDeleteTaskList, setDeleteTaskList] = useState("");

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [deactive, setDeactive] = useState("");
  const [active, setActive] = useState("");
  const [isACtive, setIsActive] = useState(false);

  const [userToDelete, setUserToDelete] = useState("");
  const [changeStatus, setChangeStatus] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  const [excelLoading, setExcelLoading] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleEdit = () => setEdit(true);
  const handleClosed = () => setEdit(false);

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const debouncedValue = useDebounce(searchValue, 500);

  const handleDelete = (RoleId) => {
    let payload = {
      roleID: RoleId,
    };
    DeleteCallWithErrorResponseWithToken(
      ApiConfig.DELETE_ROLE,
      JSON.stringify(payload)
    ).then((res) => {
      if (res) {
        ToastMsg("success", res.json.Message);
        getUserRoleList();
      }
    });
  };

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    simpleGetCallWithErrorResponse(ApiConfig.ROLE_LIST_DOWNLOAD_EXCEL)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserRoleList("");
  }, [debouncedValue]);

  const getUserRoleList = async () => {
    setLoading(true);
    let payLoad = {
      Name: "",
    };
    try {
      const res = await PostCallWithErrorResponse(
        ApiConfig.SEARCH_ROLE_API,
        JSON.stringify(payLoad)
      );
      if (res.json.Success === true) {
        if (searchValue) {
          let filteredData = res.json.Data.filter((item) => {
            return item.Name.toLowerCase().includes(
              searchValue.toLocaleLowerCase()
            );
          });
          setUserRoleList(filteredData);
        } else {
          setUserRoleList(res.json.Data);
        }
      } else {
        console.log("error response,", res);
      }
    } catch (error) {
      console.log("error response,", error);
    } finally {
      setLoading(false);
    }
  };

  const createNewRole = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      try {
        setLoader(true);
        const responce = await PostCallWithErrorResponse(
          ApiConfig.ADD_NEW_ROLE,
          JSON.stringify(roleName)
        );
        if (responce.json.Success) {
          handleClose();
          notifySuccess("Role added successfully");
          getUserRoleList("");
        } else {
          notifyError("Role name is already exist");
        }
      } catch (error) {
        console.log("error response,", error);
      } finally {
        setLoader(false);
      }
    }
    setValidated(true);
  };

  const updateRoleHandler = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      try {
        setLoader(true);
        const response = await PostCallWithErrorResponse(
          ApiConfig.UPDATE_ROLE,
          JSON.stringify(editRoleNameObj)
        );
        if (response.json.Success) {
          handleClosed();
          notifySuccess("Role updated successfully");
          getUserRoleList("");
        } else {
          setLoader(false);
          notifyError("Role name is already exist");
        }
      } catch (error) {
        console.log("error response,", error);
      } finally {
        setLoader(false);
      }
    }
    setValidated(true);
  };

  const changeRoleStatusHandler = async (data) => {
    let dataObj = {
      roleId: data.RoleId,
      status: !data.IsActive,
    };
    try {
      const response = await PostCallWithErrorResponse(
        ApiConfig.CHANGE_ROLE_STATUS,
        JSON.stringify(dataObj)
      );
      if (!response.error) {
        notifySuccess(response.json.Message);
        getUserRoleList("");
      } else {
        notifyError(response.json.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role sub-header-role-arbaz-responsive ">
          <span
            onClick={() => {
              navigate("/Role");
            }}
          >
            <h6 className="mt-2 role-text">Role</h6>
          </span>
          <div className="span-wrapper-sub-header">
            <span
              className={
                Route === "/Role" ? "sub-header-span-active" : "sub-header-span"
              }
            >
              Create
            </span>
            {permissions && permissions["Assign Roles"] && (
              <>
                <img src={verticle} alt="" style={{ marginRight: "20px" }} />
                <span
                  className="sub-header-span"
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    navigate("/AssignRole");
                  }}
                >
                  Assign
                </span>
              </>
            )}
            {permissions && permissions["View Role Permissions"] && (
              <>
                <img src={verticle} alt="" style={{ marginRight: "20px" }} />
                <span
                  className="sub-header-span"
                  onClick={() => {
                    navigate("/Permission");
                  }}
                >
                  Permissions
                </span>
              </>
            )}
            {/* <img src={verticle} alt="" /> */}
          </div>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder role-searchbar-and-btns-responsive mb-4">
          <div className="role-table-header reponsivesrc">
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search Role"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <img src={searchicon} alt="" />
            </div>
            <div className="entries-wrapper">
              <p>Entries per page</p>
              <select
                class="form-select select-drop-icon remove-border"
                aria-label="Default select example"
                onChange={(e) => {
                  setUsersPerPage(e.target.value);
                  setCurrentPage(0);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25" selected>
                  25
                </option>
              </select>
            </div>
          </div>
          <div className="role-btns-wrapper ">
            {permissions && permissions["Role Export"] && (
              <button>
                {excelLoading ? (
                  <ExcelLoader />
                ) : (
                  <img
                    src={ic_excel}
                    onClick={(e) => downloadExcelFile(e)}
                    alt=""
                  />
                )}
              </button>
            )}
            <button>
              <img src={importicon} alt="" />
            </button>

            {/* <button onClick={onDownload}>
                            {" "}
                            <img src={exporticon} alt="" />
                        </button> */}
            {permissions && permissions["Create Roles"] && (
              <button onClick={handleShow}>
                {" "}
                <img
                  src={plus}
                  onClick={() => {
                    setRoleName({
                      name: "",
                    });
                    setValidated(false);
                  }}
                  alt=""
                />
              </button>
            )}
          </div>
        </div>
        <div className="table-wrapper table-wrapper-2">
          {/* {loading ? (
                        <BeatLoader />
                    ) : ( */}
          {/* <table ref={tableRef}> */}
          {loading ? (
            <Loader />
          ) : userRoleList && userRoleList.length > 0 ? (
            <table>
              <tr className="tbl-row">
                <th id="sr-no-padding">Sr. no</th>
                <th>Role</th>
                <th>Status</th>
                {permissions &&
                  (permissions["Edit Roles"] || permissions["Delete Role"]) &&
                  <th>Action</th>
                }
              </tr>
              {userRoleList &&
                userRoleList.length > 0 &&
                userRoleList?.slice(startIndex, endIndex).map((user, index) => {
                  return (
                    <tr key={"userRoleList" + index} class="table-row-custom">
                      <td className="paddingFirstRow">
                        {startIndex + index + 1}
                      </td>
                      <td color="red">{user?.Name}</td>
                      <td
                        onClick={() => {

                          if (user?.RoleId === 1) return

                          setChangeStatus(user.Name);
                          setIsActive(user?.IsActive);
                          handleShow4();
                          setActive(user);
                          //changeRoleStatusHandler(user)
                        }}
                      // className={
                      //   user?.IsActive ? "red-deactive" : "green-active"
                      // }

                      >
                        <div style={{
                          color: user?.IsActive ? "green" : "red",
                        }}>
                          {user?.IsActive ? "Active" : "De-Active"}
                        </div>
                      </td>
                      {permissions &&
                        (permissions["Edit Roles"] || permissions["Delete Role"]) &&
                        <td>{
                          user?.RoleId === 1 ? ("") : (
                            <>
                              {permissions &&
                                permissions["Edit Roles"] &&
                                ["top"].map((placement) => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Tooltip
                                        id="tooltip"
                                        arrowProps={{
                                          backgroundColor: "#0a0202",
                                        }}
                                        className={`tooltip-${placement}`}
                                      >
                                        <strong>Edit</strong>
                                      </Tooltip>
                                    }
                                  >
                                    <img
                                      src={editicon}
                                      alt=""
                                      onClick={() => {
                                        console.log("user --------", user);
                                        setEditRoleNameObj({
                                          ...editRoleNameObj,
                                          id: user.RoleId,
                                          name: user.Name,
                                          status: user.IsActive
                                            ? "De-Active"
                                            : "Active",
                                        });
                                        handleEdit();
                                      }}
                                    />
                                  </OverlayTrigger>
                                ))}{" "}
                            </>
                          )
                        }

                          &nbsp;
                          {
                            user?.RoleId === 1 ? ("") : (
                              <>
                                {permissions &&
                                  permissions["Delete Role"] &&
                                  ["top"].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip
                                          id="tooltip"
                                          arrowProps={{
                                            backgroundColor: "#0a0202",
                                          }}
                                          className={`tooltip-${placement}`}
                                        >
                                          <strong>Delete</strong>
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        src={DelectAcBlc}
                                        alt=""
                                        onClick={() => {
                                          handleShow3();
                                          setUserToDelete(user.Name);
                                          setDeleteTaskList(user.RoleId);
                                        }}
                                      />
                                    </OverlayTrigger>
                                  ))}
                              </>)}
                          {/* <img src={Edit} alt="" /> */}
                        </td>
                      }
                    </tr>
                  );
                })}
            </table>
          ) : (
            <NoData />
          )}
          {/* // )} */}
        </div>
      </div>

      {!loading && (
        <div className="row mt-4">
          <div className="col-lg-12">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={Math.ceil(userRoleList.length / usersPerPage)}
              onPageChange={pageChangeHandler}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
              forcePage={currentPage}
            />
          </div>
        </div>
      )}

      {/* <<<<<<...................modal create starts here..........................>>>> */}
      <Modal
        className="edit-role-model-arbaz"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Create Role
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClose} className="cr-class" />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Form noValidate validated={validated} onSubmit={createNewRole}>
          <Modal.Body>
            <div className="mb-4">
              <label htmlFor="" className="enter-role-text">
                Enter Role
              </label>
              {/* <select
                                class="form-select select-drop-icon-1"
                                aria-label="Default select example"
                            >
                                <option selected>CEO</option>
                                <option value="1">CEO</option>
                                <option value="2">Performance Manager</option>
                                <option value="3">Consultant</option>
                                <option value="4">AR Manager</option>
                                <option value="5">Counselling Assistant</option>
                            </select> */}
              <input
                type="text"
                className="form-control label-input"
                name="name"
                onChange={(e) => {
                  setRoleName({
                    ...roleName,
                    name: e.target.value,
                  });
                }}
                required
              />
              {/* <div class="invalid-feedback font-11">Title is require</div> */}
              <div className="invalid-feedback">Please enter role name</div>
            </div>
          </Modal.Body>
          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer>
            <div style={{ display: "flex" }} className="Bottom-btn-responsive">
              <button
                type="button"
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive "
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                disabled={loader ? true : false}
              >
                {loader ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <ClipLoader color="#fff" size={18} />
                  </div>
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* <<<<<<...................modal edit starts here..........................>>>> */}
      <Modal
        className="edit-role-model-arbaz"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={edit}
        onHide={handleClosed}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Edit Role
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClosed} className="cr-class" />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Form noValidate validated={validated} onSubmit={updateRoleHandler}>
          <Modal.Body>
            <div className="mb-4">
              <label htmlFor="" className="enter-role-text">
                Role
              </label>
              <input
                type="text"
                className="form-control label-input"
                name="name"
                value={editRoleNameObj.name ? editRoleNameObj.name : ""}
                onChange={(e) => {
                  setEditRoleNameObj({
                    ...editRoleNameObj,
                    name: e.target.value,
                  });
                }}
                required
              />
              <div className="invalid-feedback">Role name is required</div>
            </div>
          </Modal.Body>
          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div style={{ display: "inline-flex" }}>
              <button
                type="button"
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                onClick={handleClosed}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                disabled={loader ? true : false}
              >
                {loader ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <ClipLoader color="#fff" size={18} />
                  </div>
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Confirmation popup Announcement */}

      <Modal
        className="delete-news-modal"
        show={show4}
        onHide={handleClose4}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">Confirmation</Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>
            Are you sure, you want to {isACtive ? "De-Activate" : "Activate"}{" "}
            {changeStatus} role?
          </p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose4}>
            Cancel
          </button>

          <button
            className="btn-width saveBtn"
            onClick={() => {
              changeRoleStatusHandler(active);
              handleClose4();
            }}
          >
            Confirm
          </button>
        </div>
      </Modal>

      {/* Confirmation popup Announcement */}

      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure, you want to delete the {userToDelete} role? </p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>

          <button
            className="btn-width saveBtn"
            onClick={() => {
              handleDelete(useDeleteTaskList);
              // handleDelete2()
              handleClose3();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Role;
