import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import DelectAc from "../../assets/images/DelectAcBlc.svg";
import Editac from "../../assets/images/bx_edit.svg";
import Pagination from "../../sharedComponent/Pagination";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import {
  DeleteCallWithErrorResponseWithToken,
  deleteWithAuthCall,
  PostCallWithErrorResponse,
  simpleGetCall,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import { ToastMsg } from "../TostMsg";
import { getDateddmmyyyy, getDDateYYYYMMDD } from "../../common";
function Academic() {
  const { sidebar, setSidebar, Dark, setDark, permissions } =
    useContext(AppContext);
  const [createacedamic, setCreateAcedamic] = useState(false);
  const [editacedamic, setEditAcedamic] = useState(false);
  const [deleteacedamic, setDeleteAcedemic] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);

  const [acedamicList, setAccedmicList] = useState([]);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const [isLoading, setIsLoading] = useState(true);

  const [deleteId, setDeleteId] = useState(0);

  const [validated, setValidated] = useState(false);

  const [perPage, setPerpage] = useState(2);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const initialDate = {
    Id: 0,
    StartDate: "",
    EndDate: "",
  };

  const [dates, setDates] = useState(initialDate);

  const getAcedimicList = () => {
    simpleGetCallWithErrorResponse(ApiConfig.GET_ACEDEMIC_DETAILS).then(
      (res) => {
        if (res?.json?.Success) {
          setAccedmicList(res?.json?.Data);
          setIsLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    getAcedimicList();
  }, [currentPage]);

  const handleDelete = () => {
    DeleteCallWithErrorResponseWithToken(
      ApiConfig?.DELETE_CALENDER_YEAR + deleteId
    ).then((res) => {
      console.log(res);

      if (res?.json?.Success) {
        ToastMsg("success", res?.json?.Message);
        getAcedimicList();
        setDeleteAcedemic(false);
      } else {
        ToastMsg("error", res?.json?.Message);
      }
    });
  };

  const handleChange = (key, val) => {
    setDates((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      PostCallWithErrorResponse(
        ApiConfig.CREATE_ACEDEMIC_YEAR,
        JSON.stringify(dates)
      ).then((res) => {
        if (res?.json?.Success) {
          ToastMsg("success", res?.json?.Message);
          getAcedimicList();
          clearData();
        } else {
          ToastMsg("error", res?.json?.Message);
        }
      });
    }

    setValidated(true);
  };

  const editFinacialYear = (id) => {
    simpleGetCallWithErrorResponse(ApiConfig.GET_SINGLE_ACEDMIC_YEAR + id).then(
      (res) => {
        if (res?.json?.Success) {
          setDates({
            Id: res?.json?.Data?.Id,
            StartDate: getDDateYYYYMMDD(
              new Date(res?.json?.Data?.StartDate.split("T")[0])
            ),
            EndDate: getDDateYYYYMMDD(
              new Date(res?.json?.Data?.EndDate.split("T")[0])
            ),
          });
          setCreateAcedamic(true);
        } else {
          ToastMsg("error", res?.json?.Message);
        }
      }
    );
  };

  const clearData = () => {
    setValidated(false);
    setDates(initialDate);
    setCreateAcedamic(false);
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Financial Year</h6>
            {permissions && permissions["Add Financial Year"] && (
              <button
                className="create-btn-sub-header"
                variant="primary"
                onClick={() => setCreateAcedamic(true)}
              >
                + Financial Year
              </button>
            )}
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="table-wrapper">
              {acedamicList?.length === 0 ? (
                <NoData />
              ) : (
                <table>
                  <thead>
                    <tr className="firstTabelRow">
                      <></>
                      <th>Sr.no</th>
                      <th>Start Dtae</th>
                      <th> End Date </th>
                      {permissions &&
                        (permissions["Edit Financial Year"] || permissions["Delete Financial Year"]) &&
                        <th> Actions </th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {acedamicList
                      ?.slice(startIndex, endIndex)
                      .map((item, index) => (
                        <tr className="table-row-custom">
                          <td>{index + 1}</td>
                          <td>{item?.StartDate?.split("T")[0]}</td>
                          <td>{item?.EndDate?.split("T")[0]}</td>
                          {permissions &&
                            (permissions["Edit Financial Year"] || permissions["Delete Financial Year"]) &&
                            <td>
                              {permissions &&
                                permissions["Edit Financial Year"] && (
                                  <img
                                    src={Editac}
                                    className="mx-2 editBtn"
                                    alt=""
                                    onClick={() => editFinacialYear(item?.Id)}
                                  />
                                )}
                              {permissions &&
                                permissions["Delete Financial Year"] && (
                                  <img
                                    src={DelectAc}
                                    className="deleteBtn"
                                    alt=""
                                    onClick={() => {
                                      setDeleteId(item?.Id);
                                      setDeleteAcedemic(true);
                                    }}
                                  />
                                )}
                            </td>
                          }
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      </div>

      <Pagination
        data={acedamicList}
        pageChangeHandler={pageChangeHandler}
        usersPerPage={usersPerPage}
        currentPage={currentPage}
      />
      {/* Add Financial Year */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={createacedamic}
        onHide={clearData}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                {dates?.Id == 0 ? "Add" : "Update"} Financial Year
              </label>
            </Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => clearData()} />
            </div>
          </Modal.Header>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Body>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
                required
              >
                Start Date
              </label>
              <input
                name="HolidayName"
                placeholder="Enter Start Date"
                type="date"
                value={dates?.StartDate}
                onChange={(e) => handleChange("StartDate", e.target.value)}
                className="date-label-input tasKCategory1 focusBlc"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter Start Date
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                End Date
              </label>
              <input
                name="HolidayName"
                placeholder="Enter End Date"
                type="date"
                value={dates?.EndDate}
                onChange={(e) => handleChange("EndDate", e.target.value)}
                className="date-label-input tasKCategory1 focusBlc"
                required
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter End Date
              </Form.Control.Feedback>
            </div>
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={clearData}
              type="button"
            >
              Cancel
            </button>
            <button
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              type="submit"
            >
              {dates?.Id == 0 ? "Add" : "Update"}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* Confirmation popup Acedamic year */}

      <Modal
        className="delete-news-modal"
        show={deleteacedamic}
        onHide={() => setDeleteAcedemic(false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">Confirmation</Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure, You want to delete?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button
            className="btn-width cancelBtn mx-3"
            onClick={() => setDeleteAcedemic(false)}
          >
            Cancel
          </button>

          <button className="btn-width saveBtn" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Academic;
