import { split } from "lodash";
import ApiConfig from "../api/ApiConfig";
import profile from "../assets/images/profile.svg";
function ChatImage(props) {
  const { PhotoPath, name, lastName } = props;

  return (
    <>
      {!PhotoPath ? (
        <div
          className="circle text-center"
          style={{ border: "2px solid #D4D4D4", backgroundColor: "#E7E7E9" }}
        >
          <span
            className="text-center"
            style={{
              fontSize: "13px",
              fontWeight: "bolder",
              width: "40px",
              height: "40px",
              color: "black",
              marginTop: "10px",
            }}
          >
            {name && name[0].toUpperCase()}{lastName && lastName[0].toUpperCase()}
          </span>

        </div>
      ) : (
        <img
          src={PhotoPath}
          alt=""
          onError={(e) => (e.target.src = profile)}
          style={{ width: "40px", height: '40px' }}
        />
      )}
    </>
  );
}

export default ChatImage;
