import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Logo from "../../assets/images/wifi-attendance.svg";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";

import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../../pages/TostMsg";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Password from "antd/es/input/Password";
import { ClipLoader } from "react-spinners";

function OrganizationRegistration() {
  const location = useLocation();
  const { OTPresponse } = location.state || {};
  const navigate = useNavigate();

  console.log(" otp Response university--->", OTPresponse);

  const getPassword = localStorage.getItem("passWord");

  const { userData } = useContext(AppContext);

  const [companyDetails, setCompanyDetails] = useState({
    UserEmail: "",
    CompanyName: "",
    CompanyWebsite: "",
    YourName: "",
    DomainName: "",
    CompanyEmail: "",
    CompanyPhone: "",
    state: "",
    Industry: "",
    countryName: "",
    locations: "",
    timeZone: "",
  });

  // console.log("addCampany-->",companyDetails)
  const [country, setCountry] = useState("");
  const [sectore, setSectore] = useState("");
  const [sectoreOpetion, setSectoreOpetion] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [timeZone, setTimeZone] = useState(null);

  const [error, setError] = useState({
    UserEmail: "",
    CompanyName: "",
    CompanyWebsite: "",
    YourName: "",
    DomainName: "",
    CompanyEmail: "",
    CompanyPhone: "",
    state: "",
    Industry: "",
    countryName: "",
    locations: "",
    timeZone: "",
  });

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  useEffect(() => {
    simpleGetCallWithToken(ApiConfig.COUNTRY)
      .then((res) => {
        console.log("country-->", res);
        console.log("country-->", res);
        const options = res?.Data?.map((country) => ({
          value: country,
          label: country,
        }));
        setCountryOptions(options);
      })
      .then((err) => {
        console.log("err-->", err);
      });
    simpleGetCallWithToken(ApiConfig.SECTOR)
      .then((res) => {
        console.log("STATE-->", res);
        const options = res?.Data?.map((item) => ({
          value: item,
          label: item,
        }));
        setSectoreOpetion(options);
      })
      .then((err) => {
        console.log("err-->", err);
      });
  }, []);

  // console.log("timeZone-->",timeZone?.label)

  const onSubmitRegisterBusiness = (e) => {
    e.preventDefault();
    let hasError = false;

    // if (!validateEmail(companyDetails.UserEmail)) {
    //   setError((prevError) => ({
    //     ...prevError,
    //     UserEmail: "Please enter a valid email",
    //   }));
    //   hasError = true;
    // }

    if (companyDetails.CompanyName.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        CompanyName: "Please enter Company Name",
      }));
      hasError = true;
    }

    if (
      companyDetails.YourName.trim().length === 0 ||
      /[0-9]/.test(companyDetails.YourName)
    ) {
      setError((prevError) => ({
        ...prevError,
        YourName: "Please enter Your Name",
      }));
      hasError = true;
    }

    if (
      companyDetails.DomainName.trim().length === 0 ||
      companyDetails.DomainName.includes(" ")
    ) {
      setError((prevError) => ({
        ...prevError,
        DomainName: "Please enter a valid Domain Name without spaces",
      }));
      hasError = true;
    }

    if (!validateEmail(companyDetails.CompanyEmail)) {
      setError((prevError) => ({
        ...prevError,
        CompanyEmail: "Please enter a valid Company Email",
      }));
      hasError = true;
    }

    if (!/^\d{10}$/.test(companyDetails.CompanyPhone)) {
      setError((prevError) => ({
        ...prevError,
        CompanyPhone: "Please enter a valid 10-digit Company Phone number",
      }));
      hasError = true;
    }
    if (companyDetails.CompanyWebsite.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        CompanyWebsite: "Please enter a Company's URL",
      }));
      hasError = true;
    }
    if (!companyDetails.countryName) {
      setError((prevError) => ({
        ...prevError,
        countryName: "Please select the Country",
      }));
      hasError = true;
    }
    if (companyDetails.state.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        locations: "Please enter the Location/Branch Name",
      }));
      hasError = true;
    }
    if (!timeZone) {
      setError((prevError) => ({
        ...prevError,
        timeZone: "Please select the time zone",
      }));
      hasError = true;
    }
    if (!companyDetails.Industry) {
      setError((prevError) => ({
        ...prevError,
        Industry: "Please select the Industry",
      }));
      hasError = true;
    }
    if (hasError !== true) {
      const body = {
        ClientId: OTPresponse?.ClientId,
        UserEmail: OTPresponse?.UserEmail,
        Password: getPassword,
        CompanyName: companyDetails.CompanyName,
        CompanyWebsite: companyDetails.CompanyWebsite,
        YourName: companyDetails.YourName,
        DomainName: companyDetails.DomainName,
        CompanyEmail: companyDetails.CompanyEmail,
        CompanyPhone: companyDetails.CompanyPhone,
        Country: companyDetails.countryName,
        State: companyDetails.state,
        TimeZone: timeZone?.value,
        TimeZoneValue: timeZone?.label,
        Industry: companyDetails?.Industry,
      };

      console.log("add register account-->", body);
      setisCreateAccount(true);
      PostCallWithErrorResponse(
        ApiConfig.REGISTERBUSSNESS,
        JSON.stringify(body)
      )
        .then((res) => {
          console.log(res);
          if (res.json && res.json?.Success === true) {
            ToastMsg("success", res.json.Message);
            navigate("/");
          } else {
            ToastMsg("error", res.json.Message);
          }
          setisCreateAccount(false);
        })
        .catch((err) => console.log(err));
    }
  };
  const timeZoneOptions = [
    { value: "-12:00", label: "(GMT-12:00) International Date Line West" },
    { value: "-11:00", label: "(GMT-11:00) Midway Island, Samoa" },
    { value: "-10:00", label: "(GMT-10:00) Hawaii" },
    { value: "-09:00", label: "(GMT-09:00) Alaska" },
    {
      value: "-08:00",
      label: "(GMT-08:00) Pacific Time (US & Canada); Tijuana",
    },
    {
      value: "-07:00",
      label:
        "(GMT-07:00) Chihuahua, La Paz, Mazatlan, Mountain Time (US & Canada)",
    },
    {
      value: "-06:00",
      label:
        "(GMT-06:00) Central America, Central Time (US & Canada), Guadalajara",
    },
    {
      value: "-05:00",
      label: "(GMT-05:00) Bogota, Lima, Quito, Eastern Time (US & Canada)",
    },
    {
      value: "-04:00",
      label: "(GMT-04:00) Atlantic Time (Canada), Caracas, La Paz, Santiago",
    },
    {
      value: "-03:00",
      label:
        "(GMT-03:00) Newfoundland, Brasilia, Buenos Aires, Georgetown, Greenland",
    },
    { value: "-02:00", label: "(GMT-02:00) Mid-Atlantic" },
    { value: "-01:00", label: "(GMT-01:00) Azores, Cape Verde Island" },
    {
      value: "0",
      label:
        "(GMT) Casablance, Monrovia, Greenwich Mean Time: Edinburgh, London",
    },
    {
      value: "+01:00",
      label:
        "(GMT+01:00) Amsterdam, Berlin, Rome, Vienna, Paris, West Central Africa",
    },
    {
      value: "+02:00",
      label: "(GMT+02:00) Athens, Cairo, Jerusalem, Istanbul",
    },
    { value: "+03:00", label: "(GMT+03:00) Baghdad, Kuwait, Moscow, Tehran" },
    {
      value: "+04:00",
      label: "(GMT+04:00) Abu Dhabi, Muscat, Baku, Tbilisi, Yerevan",
    },
    { value: "+04:30", label: "(GMT+04:30) Kabul" },
    {
      value: "+05:00",
      label: "(GMT+05:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
    },
    {
      value: "+05:30",
      label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    },
    { value: "+05:45", label: "(GMT+05:45) Kathmandu" },
    {
      value: "+06:00",
      label: "(GMT+06:00) Almaty, Novosibirsk, Astana, Dhaka",
    },
    { value: "+06:30", label: "(GMT+06:30) Rangoon" },
    {
      value: "+07:00",
      label: "(GMT+07:00) Bangkok, Hanoi, Jakarta, Krasnoyarsk",
    },
    {
      value: "+08:00",
      label: "(GMT+08:00) Beijing, Taipei, Hong Kong, Ulaan Bataar, Singapore",
    },
    { value: "+09:00", label: "(GMT+09:00) Osaka, Tokyo, Seoul" },
    { value: "+09:30", label: "(GMT+09:30) Adelaide, Darwin" },
    {
      value: "+10:00",
      label: "(GMT+10:00) Brisbane, Sydney, Guan, Vladivostok, Melbourne",
    },
    {
      value: "+11:00",
      label: "(GMT+11:00) Magadan, Solomon Island, New Caledonia",
    },
    {
      value: "+12:00",
      label:
        "(GMT+12:00) Auckland, Wellington, Fiji, Kamchatka, Marshall Island",
    },
    { value: "+13:00", label: "(GMT+13:00) Nuku'alofa" },
  ];
  const [isCreateAccount, setisCreateAccount] = useState(false);
  return (
    <main>
      <div className="main-auth createAccount " id="create-acc-main">
        <div className="top-logo ms-5">
          <img src={Logo} alt="Logo" height={40} width={240} />
        </div>
        <div className="create-ac-form">
          <div className="needs-validation">
            <div className="top-heading">
              <div>
                <label className="heading">Register Organization</label>
              </div>
              <div>
                <label htmlFor="" className="para-below"></label>
              </div>
            </div>
            <div className="form-control-div">
              <label for="exampleFormControlInput1" className="form-label">
                Contact Person ?*
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter your Contact Person name"
                name="ProfileName"
                required
                value={companyDetails.YourName}
                onChange={(e) =>
                  setCompanyDetails({
                    ...companyDetails,
                    YourName: e.target.value,
                  })
                }
              />
              <div>
                {error.YourName && !companyDetails.YourName && (
                  <span className="text-danger">{error.YourName}</span>
                )}
              </div>

              <div class="valid-feedback">Looks good!</div>
            </div>
            <div className="form-control-div">
              <label for="exampleFormControlInput1" className="form-label">
                Company Name?*
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter your Company Name"
                name="name"
                value={companyDetails.CompanyName}
                onChange={(e) =>
                  setCompanyDetails({
                    ...companyDetails,
                    CompanyName: e.target.value,
                  })
                }
              />
              <div>
                {error.CompanyName && !companyDetails.CompanyName && (
                  <span className="text-danger">{error.CompanyName}</span>
                )}
              </div>
            </div>
            <div className="form-control-div changeMB">
              <label for="exampleFormControlInput1" className="form-label">
                What is your Company Domain Name ?*
              </label>
              <div className="d-flex justify-contant-center">
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter company domain Name"
                  name="Phone"
                  value={companyDetails.DomainName}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      DomainName: e.target.value,
                    })
                  }
                />
                <label
                  for="exampleFormControlInput1"
                  className="form-label ml-1"
                >
                  .wifiattendance.com
                </label>
              </div>
              <div>
                {error.DomainName && !companyDetails.DomainName && (
                  <span className="text-danger">{error.DomainName}</span>
                )}
              </div>
            </div>

            <div className="form-control-div changeMB">
              <label for="exampleFormControlInput1" className="form-label">
                What is your Company Email Name ?*
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter company Email"
                name="email"
                value={companyDetails?.CompanyEmail}
                onChange={(e) =>
                  setCompanyDetails({
                    ...companyDetails,
                    CompanyEmail: e.target.value,
                  })
                }
              />
              <div>
                {error.CompanyEmail && !companyDetails.CompanyEmail && (
                  <span className="text-danger">{error.CompanyEmail}</span>
                )}
              </div>
            </div>

            <div className="form-control-div changeMB">
              <label for="exampleFormControlInput1" className="form-label">
                What is your Contact Phone ?*
              </label>
              <input
                type="tel"
                maxLength={10}
                // min={10}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter Contact Phone"
                name="Phone"
                value={companyDetails.CompanyPhone}
                onChange={(e) =>
                  setCompanyDetails({
                    ...companyDetails,
                    CompanyPhone: e.target.value,
                  })
                }
              />
              <div>
                {error.CompanyPhone &&
                  !/^\d{10}$/.test(companyDetails.CompanyPhone) && (
                    <span className="text-danger">{error.CompanyPhone}</span>
                  )}
              </div>
            </div>
            <div className="form-control-div changeMB">
              <label for="exampleFormControlInput1" className="form-label">
                What is your Campany Website ?*
              </label>
              <input
                type="text"
                // max={10}
                // min={10}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter Comapany Website"
                name="Phone"
                value={companyDetails.CompanyWebsite}
                onChange={(e) =>
                  setCompanyDetails({
                    ...companyDetails,
                    CompanyWebsite: e.target.value,
                  })
                }
              />
              <div>
                {error.CompanyWebsite && !companyDetails.CompanyWebsite && (
                  <span className="text-danger">{error.CompanyWebsite}</span>
                )}
              </div>
            </div>
            <div className="form-control-div">
              <label for="exampleFormControlInput1" className="form-label">
                Country*
              </label>
              <Select
                options={countryOptions}
                value={countryOptions?.find(
                  (option) => option.value === country
                )}
                onChange={(selectedOption) =>
                  setCompanyDetails({
                    ...companyDetails,
                    countryName: selectedOption.value,
                  })
                }
              />
              <div>
                {error.countryName && !companyDetails?.countryName && (
                  <span className="text-danger">{error.countryName}</span>
                )}
              </div>
            </div>

            <div className="form-control-div">
              <label for="exampleFormControlInput1" className="form-label">
                Location / Branch Name*
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter Location / Branch Name "
                name="email"
                value={companyDetails?.state}
                onChange={(e) =>
                  setCompanyDetails({
                    ...companyDetails,
                    state: e.target.value,
                  })
                }
              />
              <div>
                {error.locations && !companyDetails?.state && (
                  <span className="text-danger">{error.locations}</span>
                )}
              </div>
            </div>

            <div className="form-control-div">
              <label for="exampleFormControlInput1" className="form-label">
                Time Zone*
              </label>
              <Select
                options={timeZoneOptions}
                value={timeZoneOptions.find((zone) => {
                  return zone === timeZone?.value;
                })}
                onChange={(selectedOption) => setTimeZone(selectedOption)}
              />
              <div>
                {error.timeZone && !timeZone && (
                  <span className="text-danger">{error.timeZone}</span>
                )}
              </div>
            </div>
            <div className="form-control-div">
              <label for="exampleFormControlInput1" className="form-label">
                Industry*
              </label>
              <Select
                options={sectoreOpetion}
                value={sectoreOpetion?.find(
                  (option) => option.value === companyDetails?.Industry
                )}
                onChange={(selectedOption) =>
                  setCompanyDetails((prev) => ({
                    ...prev,
                    Industry: selectedOption.value,
                  }))
                }
              />
              <div>
                {error.Industry && !companyDetails.Industry && (
                  <span className="text-danger">{error.Industry}</span>
                )}
              </div>
            </div>
            <div className="para-above-btn">
              <label htmlFor="">
                By Register an account, you agree to the{" "}
                <Link to="#">Terms of use</Link> and{" "}
                <Link to="#">Privacy Policy</Link> .
              </label>
            </div>
            <div className="ac-button">
              <Link to="">
                <button
                  type="submit"
                  onClick={onSubmitRegisterBusiness}
                  disabled={isCreateAccount}
                  className="d-flex justify-content-center align-items-center"
                >
                  {isCreateAccount ? (
                    <div>
                      <span className="me-2"> Register Organization</span>
                      <ClipLoader color="#fff" size={18} />
                    </div>
                  ) : (
                    <span className="me-2"> Register Organization</span>
                  )}
                </button>
              </Link>
            </div>
            <div className="need">
              <p>
                <label htmlFor="">Do you need help?</label>
              </p>
              <p>
                <Link to="#">Customer support</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default OrganizationRegistration;
