import React from "react";
import { Bar } from "react-chartjs-2";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

// src/BarChart.js

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({data}) => {
  // const data = {
  //   labels: [
  //     "Monday",
  //     "Tuesday",
  //     "Wednesday",
  //     "Thursday",
  //     "Friday",
  //     "Saturday",
  //     "Sunday",
  //   ],
  //   datasets: [
  //     {
  //       label: "Weekly Call Summary",
  //       data: [65, 59, 80, 81, 56, 55, 40],
  //       backgroundColor: " #53b7e8",
  //       borderColor: "  #53b7e8",
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  // const options = {
  //   scales: {
  //     y: {
  //       beginAtZero: true,
  //     },
  //   },
  // };

  const chartConfig = {
    labels: data?.labels,
    datasets: [
      {
        label: "Call Data",
        data: data?.data,
        backgroundColor: "#53b7e8",
        borderColor: "#53b7e8",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
          y: {
            beginAtZero: true,
          },
        },
  };


  return (
    <div className="Barcharttsize">
      <Bar data={chartConfig} options={options} />
    </div>
  );
};

export default BarChart;
