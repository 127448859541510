import React, { useContext, useState } from 'react'
import { AppContext } from "../../context/AppContext";
import Pagination from "../../../src/sharedComponent/Pagination";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import editicon from "../../assets/images/bx_edit.svg";
import eye_icon from "../../assets/images/eye_icon.svg";
import Modal from "react-bootstrap/Modal";
import close from "../../assets/images/close.svg";
import printicon from "../../assets/images/print.svg";
import eyeicon from "../../assets/images/eye.svg";
import file from "../../assets/images/file_icon.svg";
import paint from "../../assets/images/paint.svg";
import seprater from "../../assets/images/seprate.svg";
import avatar from "../../assets/images/avatar.svg";
import uploadicon from "../../assets/images/cloud-icon.svg";
import pen from "../../assets/images/penicon.svg";
import { useNavigate } from 'react-router-dom';


function InvoiceGenerate() {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEdit = () => setEdit(true);
  const handleClosed = () => setEdit(false);
  const { sidebar } = useContext(AppContext);
  const [customerDataTable] = useState([
    {
      serial_no: "1",
      Task_ID: "Avinash",
      Workflow_new: "Default Ho",
      Start_Date: "Service T1",
      Workflow: "Quote T",
      Task_Name: "Subscription T1",
      Resource_Name: "01-09-2022",
      status: "Active",

      image: [eye_icon],
    },
    {
      serial_no: "2",
      Task_ID: "Avinash",
      Workflow_new: "Default Ho",
      Start_Date: "Service T1",
      Workflow: "Quote T",
      Task_Name: "Subscription T1",
      Resource_Name: "01-09-2022",
      status: "Active",
      image: [eye_icon],

    },
  ]);
  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"}  id="cx-main">

    <div 
     >
      <div className="role-content">
        <div className="sub-header-role sub-header-role-responsive">
          <h6 className="mt-2 role-text">Invoice Generate </h6>
          <div>
            <button
              className="create-btn-sub-header"
              onClick={handleShow}
              style={{ marginRight: "0" }}
            >
              Create
            </button>
          </div>
        </div>

        <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder">
          <div className="role-table-header reponsivesrc">
            <div className="search-input-wrapper">
              <input type="text" placeholder="Search" />
              <img src={searchicon} alt="" />
            </div>
            <div className="entries-wrapper">
              <p>Entries per page</p>
              <select
                class="form-select select-drop-icon remove-border"
                aria-label="Default select example"
              >
                <option selected>10</option>
                <option value="1">20</option>
                <option value="2">10</option>
                <option value="3">5</option>
              </select>
            </div>
          </div>
          <div className="role-btns-wrapper">
            <button>
              <img src={importicon} alt="" />
            </button>
            <button>
              {" "}
              <img src={exporticon} alt="" />
            </button>
          </div>
        </div>

        <div className="table-wrapper">
          <table>
            <tr>
              <th>Sr. no</th>
              <th>Consultant Name</th>
              <th>Branch</th>

              <th>Service</th>
              <th>Quotation</th>
              <th>Subscription</th>

              <th>Action</th>


            </tr>
            {customerDataTable.map((srnumber, index) => {
              return (
                <tr className="table-row-custom">
                  <td>{srnumber.serial_no}</td>
                  <td>{srnumber.Task_ID}</td>
                  <td>{srnumber.Workflow_new}</td>

                  <td>{srnumber.Start_Date}</td>
                  <td>{srnumber.Workflow}</td>

                  <td>{srnumber.Task_Name}</td>

                  <td>
                    <img src={eye_icon} alt="" onClick={handleEdit} />
                  </td>


                  {/* <td
                    className={
                      srnumber.status === "Completed"
                        ? ""
                        : ""
                    }
                  >
                    {srnumber.status}
                  </td>
                  <td>
                    {srnumber.outsorce}
                  </td> */}
                </tr>
              );
            })}
          </table>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <Pagination />
        </div>
      </div>


      {/* <<<<<<...................modal create starts here..........................>>>> */}
      <Modal
        className='modal-lg '
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
      >
        {/* <div className='text-end pe-3 pt-3'>
      <img src={close} alt="" onClick={handleClose} />

      </div> */}
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
          
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClose} />
        </Modal.Header>

        <div
          className=''
        //   className={
        //     slideOpen
        //       ? "main-content-container"
        //       : "main-content-container-after-shrink"
        //   }
        >
          {/* <div className="sub-header-role">
        <h6 className="mt-2 role-text">Generate Invoice</h6>
      </div> */}
          {/* <div className="row mt-4">
        <div className="d-flex justify-content-center">
          <div className="col-lg-10 invoice-head-content">
            <div className="invoice-header">
              <div>
                <label htmlFor="">INVOICE :</label>
                <label> #AS0001</label>
              </div>
              <div className="right-side-menus">
                <div>
                  <img src={printicon} alt="" />
                </div>
                <div>
                  <img src={eyeicon} alt="" className="ms-2" />
                </div>
                <div>
                  <img src={file} alt="" className="ms-2" />
                </div>
                <div>
                  <img src={paint} alt="" className="ms-2" />
                </div>
                <div>
                  <img src={seprater} alt="" className="ms-2" />
                </div>
                <div>
                  <img src={avatar} alt="" className="ms-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


          <div className="row ">
            <div className="">
              <div className=" tax-invoice-content responsivemodell content-margin22">
                <div className="taxinvoice-section-1">
                  <div className="section1_Accounting">
                    <div>
                      <label for="uploadcloud" id="upload-btn-cloud">
                        <div className="text-center align-self-center uplodeAccount">
                          <img src={uploadicon} alt="" className="" />
                          <p>Upload Files</p>
                        </div>
                        <input type="file" name="uploadcloud" id="uploadcloud" />
                      </label>
                    </div>
                    <div className="maindivSection-2">
                      <label htmlFor="" className="tax-invoice">
                        Tax Invoice
                      </label>
                      <div>
                        <label htmlFor="" className="invoice-numbers">
                          Invoice No.
                        </label>
                        <label htmlFor="" className="invoice-number-color">
                          XXXXXX
                        </label>
                      </div>
                      <div className="minboxwrapper">
                        <div>
                          <label htmlFor="" className="invoice-numbers">
                            Date :
                          </label>
                        </div>
                        <div className='responsivetextend'>
                          <input type="date" className="minInputBox" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                      <div className="minboxwrapper">
                        <div>
                          <label htmlFor="" className="invoice-numbers">
                            Due Date :
                          </label>
                        </div>
                        <div className='responsivetextend'>
                          <input type="date" className="minInputBox" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="taxinvoice-section-1">
                  <div className="section1_Accounting">
                    <div>
                      <div className="mt-2">
                        <input
                          type="text"
                          placeholder="Owner Name"
                          className="owner-name-input leftinput"
                        />

                      </div>
                      <div className="mt-2">
                        <textarea
                          placeholder="Address"
                          name=""
                          id=""
                          className="cloud-text-area"
                        ></textarea>
                      </div>
                      <div className="minboxwrapperLeft">
                        <div>
                          <label htmlFor="" className="invoice-numbers">
                            Date :
                          </label>
                        </div>
                        <div className='responsivetextend'>
                          <input type="date" className="minInputBoxLeft" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                      <div className="minboxwrapperLeft">
                        <div>
                          <label htmlFor="" className="invoice-numbers">
                            GST No.  :
                          </label>
                        </div>
                        <div className='responsivetextend'>
                          <input type="date" className="minInputBoxLeft" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                      <div className="minboxwrapperLeft">
                        <div>
                          <label htmlFor="" className="invoice-numbers">
                            PAN No. :
                          </label>
                        </div>
                        <div className='responsivetextend'>
                          <input type="date" className="minInputBoxLeft" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                    </div>
                    <div className="maindivSection-2">
                      <div className="mb-3">
                        <p>Place of Supply:</p>
                        <div className="d-flex selectborderremove">
                          <select
                            class="form-select  select-drop-icon-cloud"
                            aria-label="Default select example"
                          >
                            <option selected>Maharashtra-27</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                      <div>
                        <p>Mode of Payment::</p>
                        <div className="d-flex mb-2 selectborderremove">
                          <select
                            class="form-select  select-drop-icon-cloud"
                            aria-label="Default select example"
                          >
                            <option selected>Maharashtra-27</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                        <div className="d-flex mb-2">
                          <input type="text" className="owner-name-input" placeholder="Cheque No. XXX06542" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                        <div className="d-flex">
                          <input type="text" className="owner-name-input" placeholder="Name of Bank" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="taxinvoice-section-1 lastsection">
                  <div className="section1_Accounting">
                    <div>
                      <div className=" maindivSection-2">
                        <p>Bill to</p>
                        <input
                          type="text"
                          placeholder="Owner Name"
                          className="owner-name-input leftinput"
                        />
                      </div>
                      <div className="mt-2">
                        <textarea
                          placeholder="Address"
                          name=""
                          id=""
                          className="cloud-text-area"
                        ></textarea>
                      </div>
                      <div className="minboxwrapperLeft">
                        <div>
                          <label htmlFor="" className="invoice-numbers">
                            Date :
                          </label>
                        </div>
                        <div className='responsivetextend'>
                          <input type="date" className="minInputBoxLeft" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                      <div className="minboxwrapperLeft">
                        <div>
                          <label htmlFor="" className="invoice-numbers">
                            GST No.  :
                          </label>
                        </div>
                        <div className='responsivetextend'>
                          <input type="date" className="minInputBoxLeft" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                      <div className="minboxwrapperLeft">
                        <div>
                          <label htmlFor="" className="invoice-numbers">
                            PAN No. :
                          </label>
                        </div>
                        <div className='responsivetextend'>
                          <input type="date" className="minInputBoxLeft" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                    </div>
                    <div className="parparapar maindivSection-2">
                      <p>Ship to</p>
                      <div className=" d-flex">
                        <input
                          type="text"
                          placeholder="Owner Name"
                          className="owner-name-input"
                        />
                        <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                      </div>
                      <div className="mt-2 d-flex">
                        <textarea
                          placeholder="Address"
                          name=""
                          id=""
                          className="cloud-text-area"
                        ></textarea>
                        <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                      </div>
                      <div className="minboxwrapper">
                        <div>
                          <label htmlFor="" className="invoice-numbers">
                            Date :
                          </label>
                        </div>
                        <div className='responsivetextend'>
                          <input type="date" className="minInputBox" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                      <div className="minboxwrapper">
                        <div>
                          <label htmlFor="" className="invoice-numbers">
                            GST No.  :
                          </label>
                        </div>
                        <div className='responsivetextend'>
                          <input type="date" className="minInputBox" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                      <div className="minboxwrapper">
                        <div>
                          <label htmlFor="" className="invoice-numbers">
                            PAN No. :
                          </label>
                        </div>
                        <div className='responsivetextend'>
                          <input type="date" className="minInputBox" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accountsectiontabelrecipt">
                    <table border="1">
                      <tr>
                        <th>#</th>
                        <th>Product Name</th>
                        <th>HSN/SAC</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th colSpan="2">CGST</th>
                        <th colSpan="2">SGST</th>
                        <th>Amount</th>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td><input type="text" placeholder="Enter Name of Product" /></td>
                        <td><input type="number" placeholder="Code" /></td>
                        <td><input type="number" placeholder="0" /></td>
                        <td><input type="number" placeholder="0.0" /></td>
                        <td><input type="number" placeholder="0%" /></td>
                        <td><input type="number" placeholder="0.0" /></td>
                        <td><input type="number" placeholder="0%" /></td>
                        <td><input type="number" placeholder="000" /></td>
                        <td><input type="number" placeholder="00" /></td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-lg-12 mt-4 ">
                    <button className="modal-create-btn pe-4  ps-4">Add Row</button>
                  </div>
                  <div className="row text-end accountsectiontotal">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 maintabel">
                      <div>
                        <label>Sub Total</label>
                        <p>$0.0</p>
                      </div>
                      <div>
                        <label>CGST@ 0%</label>
                        <p>$0.0</p>
                      </div>
                      <div>
                        <label>SGST@ 0%</label>
                        <p>$0.0</p>
                      </div>
                    </div>
                  </div>
                  <div className="row text-end accountsectiontotal-2">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-4"></div>
                    <div className="col-lg-5 maintabel-2">
                      <div>
                        <label>Total Amount</label>
                        <p>$0.0</p>
                      </div>
                      <div>
                        <label></label>
                        <p>Amount in words</p>
                      </div>
                    </div>
                  </div>
                  <div className="row bottonsectionaccounyting">
                    <div className="col-lg-7 textareabottom">
                      <textarea name="" id="" placeholder="Declaration"></textarea>
                    </div>
                    <div className="col-lg-5 uplodebottomsection">
                      <label for="uploadcloud" >
                        <div className="text-center align-self-center">
                          <img src={uploadicon} alt="" className="" />
                          <p>Authorized Signature</p>
                        </div>
                        <input type="file" name="uploadcloud" id="uploadcloud" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Add Task
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClose} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <label htmlFor="" className="enter-role-text">
            Project Name
          </label>
          <select
            class="form-select select-drop-icon-1"
            aria-label="Default select example"
          >
            <option selected>CEO</option>
            <option value="1">CEO</option>
            <option value="2">Performance Manager</option>
            <option value="3">Consultant</option>
            <option value="4">AR Manager</option>
            <option value="5">Counselling Assistant</option>
          </select>
          <label htmlFor="" className="enter-role-text mt-2">
            Created by
          </label>
          <select
            class="form-select select-drop-icon-1"
            aria-label="Default select example"
          >
            <option selected>Employee Name</option>
            <option value="1">CEO</option>
            <option value="2">Performance Manager</option>
            <option value="3">Consultant</option>
            <option value="4">AR Manager</option>
            <option value="5">Counselling Assistant</option>
          </select>
          <label htmlFor="" className="enter-role-text mt-2">
            Task Name
          </label>
          <select
            class="form-select select-drop-icon-1"
            aria-label="Default select example"
          >
            <option selected>CEO</option>
            <option value="1">CEO</option>
            <option value="2">Performance Manager</option>
            <option value="3">Consultant</option>
            <option value="4">AR Manager</option>
            <option value="5">Counselling Assistant</option>
          </select>
          <label
            htmlFor=""
            className="label-text mt-2"
            style={{ fontSize: "14px" }}
          >
            Start Date
          </label>
          <input
            type="date"
            className="date-label-input"
            style={{ fontSize: "14px", height: "38px",border:"1px solid #ced4da" }}
          />
          <label
            htmlFor=""
            className="label-text mt-2"
            style={{ fontSize: "14px" }}
          >
            End Date
          </label>
          <input
            type="date"
            className="date-label-input"
            style={{ fontSize: "14px", height: "38px",border:"1px solid #ced4da" }}
          />
           <label
            htmlFor=""
            className="label-text mt-2"
            style={{ fontSize: "14px" }}
          >
            Budjet Hours
          </label>
          <input
            type="time"
            className="date-label-input"
            style={{ fontSize: "14px", height: "38px",border:"1px solid #ced4da" }}
          />
          <div className="mt-2 text-end">
<input type="checkbox" name="" id="" />
<label htmlFor="" className="label-text ms-2"> On Priority</label>
          </div>
        </Modal.Body>
        
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div>
            <button className="modal-cancel-btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="modal-create-btn">Add</button>
          </div>
        </Modal.Footer> */}
      </Modal>


      {/* <<<<<<...................modal edit starts here..........................>>>> */}
      <Modal
        className='modal-xl '

        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={edit}
        onHide={handleClosed}
      >
        <Modal.Header>
          <Modal.Title>

          </Modal.Title>
          <img src={close} alt="" onClick={handleClosed} />
        </Modal.Header>


        <div className="row">
          <div className="d-flex justify-content-center">
            <div className="col-lg-9 modalwidth992 small-modelview22">
              <div className="payment-reciept-header small-header22">
                <div>
                  <p htmlFor="" className="advance-pay-reciept small-heading22">
                    Tax Invoice
                  </p>
                </div>
                <div>
                  <div className="d-flex justify-content-between">
                    <p htmlFor="" className="reciept-number">
                      Invoice No.
                    </p>{" "}
                    <p htmlFor="" className="date-reciept">
                      XYZ123456
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p htmlFor="" className="reciept-number">
                      Date
                    </p>{" "}
                    <p htmlFor="" className="date-reciept">
                      29/09/2022
                    </p>
                  </div>
                </div>
              </div>
              <div className="payment-reciept-wrapper">
                <div className="taxinvoice-section-1 lastsection">
                  <div className="section1_Accounting">
                    <div>
                      <div className=" maindivSection-2 mb-2">
                        <p>Name</p>
                        <input
                          type="text"
                          placeholder="Name"
                          className="owner-name-input leftinput"
                        />
                      </div>
                      <div className=" maindivSection-2 mb-2">
                        <input
                          type="text"
                          placeholder="Email"
                          className="owner-name-input leftinput"
                        />
                      </div>
                      <div className=" maindivSection-2 mb-2">
                        <input
                          type="text"
                          placeholder="Phone Number"
                          className="owner-name-input leftinput"
                        />
                      </div>
                      <div className="parparapar mb-3">
                        <p>Place of Supply:</p>
                        <div className="d-flex selectborderremove">
                          <select
                            class="form-select  select-drop-icon-cloud"
                            aria-label="Default select example"
                          >
                            <option selected>Maharashtra-27</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                        </div>
                      </div>


                    </div>
                    <div className="parparapar maindivSection-2">
                      <p>Bill to</p>
                      <div className=" d-flex mb-2">
                        <input
                          type="text"
                          placeholder="Name"
                          className="owner-name-input"
                        />
                        <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                      </div>
                      <div className=" d-flex">
                        <input
                          type="text"
                          placeholder="Email"
                          className="owner-name-input"
                        />
                        <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                      </div>
                      <div className="mt-2 d-flex mb-3">
                        <textarea
                          placeholder="Address"
                          name=""
                          id=""
                          className="cloud-text-area"
                        ></textarea>
                        <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                      </div>
                      <div className="minboxwrapper">
                        <div className='respo1stdiv'>
                          <label htmlFor="" className="invoice-numbers">
                            Contact No.
                          </label>
                        </div>
                        <div className='respo2stdiv'>
                          <input type="text" className="minInputBox" />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>
                      <div className="minboxwrapper">
                        <div className='respo1stdiv'>
                          <label htmlFor="" className="invoice-numbers">
                            GST IN.  :
                          </label>
                        </div>
                        <div className='respo2stdiv'>
                          <input type="text" className="minInputBox" placeholder='XXXX-XXX-XXX' />
                          <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="texAccountTabel">
                    <table>
                      <tr>
                        <th>#</th>
                        <th>Service Description</th>
                        <th>FY</th>
                        <th>Period</th>
                        <th>GST</th>
                        <th>Basic Amount</th>
                        <th>Tax Amount</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td><input type="text" placeholder="Enter Name of Product" /></td>
                        <td><input type="number" placeholder="Code" /></td>
                        <td><input type="number" placeholder="0" /></td>
                        <td><input type="number" placeholder="0.0" /></td>
                        <td><input type="number" placeholder="0%" /></td>
                        <td><input type="number" placeholder="0.0" /></td>
                        <td><input type="number" placeholder="0%" /></td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-lg-12 mt-4 ">
                    <button className="save-btn pe-4  ps-4">Add Row</button>
                  </div>
                  <div className="row text-end accountsectiontotal">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 maintabel">
                      <div>
                        <label>Total</label>
                        <p>$0.0</p>
                      </div>
                    </div>
                  </div>
                  <div className="row text-end mb-4 accountsectiontotal-2 botdernonr">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-4"></div>
                    <div className="col-lg-5 Txemaintabel-2">
                      <div>
                        <label>Net Payable</label>
                        <p>$0.0</p>
                      </div>
                    </div>
                  </div>
                  <div className="taxBreckups">
                    <div>
                      <p>Tax Break Ups</p>
                    </div>
                    <div className='linTax'>

                    </div>
                  </div>
                  <div className="texAccountTabel mb-5 text-center">
                    <table>
                      <tr>
                        <th>Rate</th>
                        <th>Basic</th>
                        <th>IGST</th>
                        <th>CGST</th>
                        <th>SGST</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td><p className='tabeldataTex'>18%</p></td>
                        <td><p className='tabeldataTex'>10</p></td>
                        <td><p className='tabeldataTex'>$0.0</p></td>
                        <td><p className='tabeldataTex'>$0.0</p></td>
                        <td><p className='tabeldataTex'>$000</p></td>
                        <td><p className='tabeldataTex'>$0.0</p></td>
                      </tr>
                    </table>
                  </div>
                  <div className="taxBreckups">
                    <div>
                      <p>Bank Details</p>
                    </div>
                    <div className='linTax'>

                    </div>
                  </div>
                  <div className="texAccountTabel mb-5 textablelast lasttableintax">
                    <table>
                      <tr>
                        <th className='tabeldataTexhead'>Particular</th>
                        <th className='tabeldataTexhead'>Details</th>
                      </tr>
                      <tr>
                        <td><p className='tabeldataTexhead'>Account No.</p></td>
                        <td><p className='tabeldataTex'>123456789456123</p></td>
                      </tr>
                      <tr>
                        <td><p className='tabeldataTexhead'>Bank Name</p></td>
                        <td><p className='tabeldataTex'>Bank of India</p></td>
                      </tr>
                      <tr>
                        <td><p className='tabeldataTexhead'>Branch Name</p></td>
                        <td><p className='tabeldataTex'>Vishrantvadi, Pune</p></td>
                      </tr>
                      <tr>
                        <td><p className='tabeldataTexhead'>IFSC Code</p></td>
                        <td><p className='tabeldataTex'>123456</p></td>
                      </tr>
                      <tr>
                        <td><p className='tabeldataTexhead'>Swift Code</p></td>
                        <td><p className='tabeldataTex'>000000</p></td>
                      </tr>
                      <tr>
                        <td><p className='tabeldataTexhead'>PAN Card No.</p></td>
                        <td><p className='tabeldataTex'>123456789012345</p></td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-lg-12 mt-4 text-end btnbtnbtn">
                    <button className="modal-cancel-btn">Cancel</button>
                    <button className="modal-create-btn add-btn22" onClick={() => navigate("")}>Draft</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <label htmlFor="" className="enter-role-text">
            Project Name
          </label>
          <select
            class="form-select select-drop-icon-1"
            aria-label="Default select example"
          >
            <option selected>CEO</option>
            <option value="1">CEO</option>
            <option value="2">Performance Manager</option>
            <option value="3">Consultant</option>
            <option value="4">AR Manager</option>
            <option value="5">Counselling Assistant</option>
          </select>
          <label htmlFor="" className="enter-role-text mt-2">
            Created by
          </label>
          <select
            class="form-select select-drop-icon-1"
            aria-label="Default select example"
          >
            <option selected>Employee Name</option>
            <option value="1">CEO</option>
            <option value="2">Performance Manager</option>
            <option value="3">Consultant</option>
            <option value="4">AR Manager</option>
            <option value="5">Counselling Assistant</option>
          </select>
          <label htmlFor="" className="enter-role-text mt-2">
            Task Name
          </label>
          <select
            class="form-select select-drop-icon-1"
            aria-label="Default select example"
          >
            <option selected>CEO</option>
            <option value="1">CEO</option>
            <option value="2">Performance Manager</option>
            <option value="3">Consultant</option>
            <option value="4">AR Manager</option>
            <option value="5">Counselling Assistant</option>
          </select>
          <label
            htmlFor=""
            className="label-text mt-2"
            style={{ fontSize: "14px" }}
          >
            Start Date
          </label>
          <input
            type="date"
            className="date-label-input"
            style={{ fontSize: "14px", height: "38px",border:"1px solid #ced4da" }}
          />
          <label
            htmlFor=""
            className="label-text mt-2"
            style={{ fontSize: "14px" }}
          >
            End Date
          </label>
          <input
            type="date"
            className="date-label-input"
            style={{ fontSize: "14px", height: "38px",border:"1px solid #ced4da" }}
          />
           <label
            htmlFor=""
            className="label-text mt-2"
            style={{ fontSize: "14px" }}
          >
            Budjet Hours
          </label>
          <input
            type="time"
            className="date-label-input"
            style={{ fontSize: "14px", height: "38px",border:"1px solid #ced4da" }}
          />
          <div className="mt-2 text-end">
<input type="checkbox" name="" id="" />
<label htmlFor="" className="label-text ms-2"> On Priority</label>
          </div>
        </Modal.Body>
        
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div>
            <button className="modal-cancel-btn" onClick={handleClosed}>
              Cancel
            </button>
            <button className="modal-create-btn">Edit</button>
          </div>
        </Modal.Footer> */}
      </Modal>
    </div>
    </div>

  )
}

export default InvoiceGenerate