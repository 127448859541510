import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/Role.scss";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import editicon from "../../assets/images/bx_edit.svg";
import eye_icon from "../../assets/images/eye_icon.svg";
import close from "../../assets/images/close.svg";
import deleteicon from "../../assets/images/delete.svg";
import Modal from "react-bootstrap/Modal";
import { AppContext } from "../../context/AppContext";
import dropicon from "../../assets/images/circle_drop.svg";
import Pagination from "../../../src/sharedComponent/Pagination";
import { useNavigate } from "react-router-dom";
import { simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";

const Quotation = () => {
  const navigate = useNavigate();

  const { sidebar, userData } = useContext(AppContext);

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [priviwe, setpriviwe] = useState(false);

  const [services, setServices] = useState([])
  const [consutants, setConsultants] = useState([])
  const [clients, setClients] = useState([])
  const [periods, setPeriods] = useState([])
  const [finanacialYear, setFinancialYear] = useState([])

  const getServices = () => {

    if (services.length) return

    simpleGetCallWithErrorResponse(ApiConfig.GET_SERVICES).then((res) => {

      if (res?.json?.success) {

        setServices(res?.json?.data)

      }

    })
  }


  const getconsultants = () => {

    if (consutants.length) return

    simpleGetCallWithErrorResponse(ApiConfig.GET_CONSULTANTS).then((res) => {

      if (res?.json?.Success) {

        setConsultants(res?.json?.Data)

      }

    })

  }

  const getClient = (id) => {


    simpleGetCallWithErrorResponse(ApiConfig.GET_CLIENTS_WORKFLOW + id).then((res) => {

      if (res?.json?.success) {
        setClients(res?.json?.data)
      }

    })

  }

  const getBranches = (id) => {


    simpleGetCallWithErrorResponse(ApiConfig.GET_BRANCH_BY_CLIENT + `${id}/branches`).then((res) => {

      if (res?.json?.success) {

      }

    })

  }

  const getPeriods = (id) => {

    simpleGetCallWithErrorResponse(ApiConfig.GET_SINGEL_SERVICE + id).then((res) => {

      if (res?.json?.success) {

        setPeriods(res?.json?.data.addPeriods)

      }

    })

  }

  const getmasterData = () => {


    if (finanacialYear.length) return


    simpleGetCallWithErrorResponse(ApiConfig.MASTER_DATA).then((res) => {

      if (res?.json?.success) {
        let filt = res?.json?.data?.filter((i) => {
          return i.listName === 'FinancialYears'
        })

        setFinancialYear(filt[0]?.listValues)
      }

    })


  }



  const handleClosed = () => setShow(false);
  const handleClose = () => setEdit(false);
  const priveclose = () => setpriviwe(false);

  const handleShow = () => setShow(true);
  const handleEdit = () => setEdit(true);

  const handleShowCraetQuataion = () => {

    getServices()
    getconsultants()
    // getClient()
    getmasterData()
    handleShow()

  }

  const handlePriviwe = () => setpriviwe(true);
  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div

      >
        <div className="role-content">
          <div className="sub-header-role">
            <h6 className="mt-2 role-text">Quotation</h6>
            <button className="create-btn-sub-header" onClick={handleShowCraetQuataion}>
              Create
            </button>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns responsivetopbar resposiveheadder">
            <div className="role-table-header firstdiv reponsivesrc">
              <div className="search-input-wrapper">
                <input type="text" placeholder="Search" />
                <img src={searchicon} alt="" />
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  class="form-select tasKCategory1 remove-border"
                  aria-label="Default select example"
                >
                  <option selected>10</option>
                  <option value="1">20</option>
                  <option value="2">10</option>
                  <option value="3">5</option>
                </select>
              </div>
            </div>
            <div className="role-btns-wrapper">
              <button>
                <img src={importicon} alt="" />
              </button>
              <button>
                {" "}
                <img src={exporticon} alt="" />
              </button>
            </div>
          </div>
          <div className="table-wrapper">
            <table>
              <tr>
                <th>Sr. no</th>
                <th>Name</th>
                <th>Consultant ID</th>
                <th>Create Date</th>
                <th>Submit Date</th>
                <th>Quotation Status</th>
                <th>Actions</th>
              </tr>
              <tr className="table-row-custom">
                <td>1</td>
                <td>ITR170822</td>
                <td></td>
                <td>09/08/2022</td>
                <td>16/09/2022</td>
                <td className="Qutationtabelselect">
                  <select
                    class="form-select tasKCategory1"
                    aria-label="Default select example"
                  >
                    <option selected></option>
                    <option value="1">Active</option>
                    <option value="2">Waiting for Advance</option>
                    <option value="2">Suspended</option>
                    <option value="2">Aborted</option>
                  </select>
                </td>
                <td>
                  <img
                    src={editicon}
                    alt=""
                    onClick={handleEdit}
                    className="me-3"
                  />
                  <img src={eye_icon} alt="" onClick={handlePriviwe} />
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12">
            <Pagination />
          </div>
        </div>
      </div>

      {/* <<<<<<...................modal create starts here..........................>>>> */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        className="create-workflow-main modal-lg"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Add Quotation
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClosed} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="row create-workflow-topbody pb-4">
            <div className="col-lg-6 mt-4">
              <label htmlFor="" className="text-grey">
                Name
              </label>
              <input
                type="text"
                className="form-control tasKCategory1"
                placeholder="Name"
              />
            </div>
            <div className="col-lg-6 mt-4">
              <label htmlFor="" className="text-grey">
                Consultant Name
              </label>
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
                onChange={(e) => {
                  getClient(e.target.value)
                }}
              >
                <option selected>Select Consultant</option>
                {
                  consutants?.map((consultant, index) => {
                    return <option value={consultant.Id}>
                      {consultant.FirstName + ' ' + consultant?.LastName}
                    </option>
                  })
                }
              </select>
            </div>
          </div>
          <div className="row create-workflow-topbody pb-4">
            <div className="col-lg-4  mt-4 d-flex align-items-center">
              <label htmlFor="" className="text-grey me-5">
                Is GST Applicable ?
              </label>
              <input type="checkbox" />
            </div>
            <div className="col-lg-4  mt-4 d-flex align-items-center">
              <label htmlFor="" className="text-grey me-5">
                Is TDS Applicable ?
              </label>
              <input type="checkbox" />
            </div>
            <div className="col-lg-4 position-relative mt-4">
              <label htmlFor="" className="text-grey">
                GST Rate
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
          </div>
          <div className="row create-workflow-topbody pb-4 ">
            <div className="col-lg-4">
              <label htmlFor="" className="text-grey">
                TDS Section
              </label>
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
              >
                <option selected></option>
                <option value="1">TDS Section-1</option>
                <option value="2">TDS Section-2</option>
                <option value="2">TDS Section-3</option>
              </select>
            </div>
            <div className="col-lg-4 ">
              <label htmlFor="" className="text-grey">
                TDS Amount
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
            <div className="col-lg-4 ">
              <label htmlFor="" className="text-grey">
                TDS Percentage
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12 text-end">
              <button
                className="modal-create-btn mb-2"
                onClick={() => navigate("")}
              >
                Add Subscription
              </button>
            </div>
          </div>
          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                Subscription 1
              </label>
            </Modal.Title>
            <img src={close} alt="" onClick={handleClose} />
          </Modal.Header>
          <div className="row create-workflow-topbody pb-4 ">
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Service ID
              </label>
              {/* <input type="text" className="form-control tasKCategory1" placeholder="07:00 pm" /> */}
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
                onChange={(e) => {

                  getPeriods(e.target.value)
                  // getClient(e.target.value)

                }}
              >
                <option selected>Select Service</option>
                {
                  services.map((service, index) => {
                    return <option value={service.id}>{service.name}</option>
                  })
                }
              </select>
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Financial Year
              </label>
              {/* <input type="text" className="form-control tasKCategory1" placeholder="07:00 pm" /> */}
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
              >
                <option selected>Select Financial Year</option>
                {
                  finanacialYear?.map((year) => {
                    return <option>{year.value}</option>
                  })
                }
              </select>
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Subscription Name
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Client Name
              </label>
              {/* <input type="text" className="form-control tasKCategory1" placeholder="07:00 pm" /> */}
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
                onChange={(e) => {

                  getBranches(e.target.value)

                }}
              >
                <option selected>Select Client</option>
                {
                  clients?.map((client, index) => {
                    return <option value={client.id}>
                      {client.firstName + ' ' + client?.lastName}
                    </option>
                  })
                }
              </select>
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Branch ID
              </label>
              {/* <input type="text" className="form-control tasKCategory1" placeholder="07:00 pm" /> */}
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
              >
                <option selected></option>
                <option value="1">Branch ID-1</option>
                <option value="2">Branch ID-2</option>
                <option value="2">Branch ID-3</option>
              </select>
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Period's
              </label>
              {/* <input type="text" className="form-control tasKCategory1" placeholder="07:00 pm" /> */}
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
              >
                <option selected>Select Period</option>
                {
                  periods.map((period, index) => {
                    return <option value={period.id} key={index}>{period.name}</option>
                  })
                }
              </select>
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Chart of Fees ₹
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Latest Avialiable Invoice ₹
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Latest Avialiable Quotation ₹
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Negotiated Amount ₹
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Advance Amount
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
            <Modal.Footer>
              <div>
                <button className="modal-cancel-btn" onClick={handleClose}>
                  Close
                </button>
                <button className="modal-create-btn ">Submit</button>
              </div>
            </Modal.Footer>
          </div>
        </Modal.Body>
      </Modal>

      {/* <<<<<<...................modal edit starts here..........................>>>> */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={edit}
        className="create-workflow-main modal-lg"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Edit Quotation
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClose} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="row create-workflow-topbody pb-4">
            <div className="col-lg-6 mt-4">
              <label htmlFor="" className="text-grey">
                Name
              </label>
              <input
                type="text"
                className="form-control tasKCategory1"
                placeholder="Name"
              />
            </div>
            <div className="col-lg-6 mt-4">
              <label htmlFor="" className="text-grey">
                Consultant Name
              </label>
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
              >
                <option selected></option>
                <option value="1">Consultant Name-1</option>
                <option value="2">Consultant Name-2</option>
                <option value="2">Consultant Name-3</option>
              </select>
            </div>
          </div>
          <div className="row create-workflow-topbody pb-4">
            <div className="col-lg-4  mt-4 d-flex align-items-center">
              <label htmlFor="" className="text-grey me-5">
                Is GST Applicable ?
              </label>
              <input type="checkbox" />
            </div>
            <div className="col-lg-4  mt-4 d-flex align-items-center">
              <label htmlFor="" className="text-grey me-5">
                Is TDS Applicable ?
              </label>
              <input type="checkbox" />
            </div>
            <div className="col-lg-4 position-relative mt-4">
              <label htmlFor="" className="text-grey">
                GST Rate
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
          </div>
          <div className="row create-workflow-topbody pb-4 ">
            <div className="col-lg-4">
              <label htmlFor="" className="text-grey">
                TDS Section
              </label>
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
              >
                <option selected></option>
                <option value="1">TDS Section-1</option>
                <option value="2">TDS Section-2</option>
                <option value="2">TDS Section-3</option>
              </select>
            </div>
            <div className="col-lg-4 ">
              <label htmlFor="" className="text-grey">
                TDS Amount
              </label>
              <input
                type="text"
                className="form-control tasKCategory1"
                placeholder="07:00 pm"
              />
            </div>
            <div className="col-lg-4 ">
              <label htmlFor="" className="text-grey">
                TDS Percentage
              </label>
              <input
                type="text"
                className="form-control tasKCategory1"
                placeholder="07:00 pm"
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12 text-end">
              <button
                className="modal-create-btn mb-2"
                onClick={() => navigate("")}
              >
                Add Subscription
              </button>
            </div>
          </div>
          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                Subscription 1
              </label>
            </Modal.Title>
            <img src={close} alt="" onClick={handleClose} />
          </Modal.Header>
          <div className="row create-workflow-topbody pb-4 ">
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Service ID
              </label>
              {/* <input type="text" className="form-control tasKCategory1" placeholder="07:00 pm" /> */}
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
              >
                <option selected></option>
                <option value="1">Service ID-1</option>
                <option value="2">Service ID-2</option>
                <option value="2">Service ID-3</option>
              </select>
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Financial Year
              </label>
              {/* <input type="text" className="form-control tasKCategory1" placeholder="07:00 pm" /> */}
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
              >
                <option selected></option>
                <option value="1">Financial Year-1</option>
                <option value="2">Financial Year-2</option>
                <option value="2">Financial Year-3</option>
              </select>
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Subscription Name
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Consultant ID
              </label>
              {/* <input type="text" className="form-control tasKCategory1" placeholder="07:00 pm" /> */}
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
              >
                <option selected></option>
                <option value="1">Consultant ID-1</option>
                <option value="2">Consultant ID-2</option>
                <option value="2">Consultant ID-3</option>
              </select>
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Branch ID
              </label>
              {/* <input type="text" className="form-control tasKCategory1" placeholder="07:00 pm" /> */}
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
              >
                <option selected></option>
                <option value="1">Branch ID-1</option>
                <option value="2">Branch ID-2</option>
                <option value="2">Branch ID-3</option>
              </select>
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Period's
              </label>
              {/* <input type="text" className="form-control tasKCategory1" placeholder="07:00 pm" /> */}
              <select
                class="form-select tasKCategory1"
                aria-label="Default select example"
              >
                <option selected></option>
                <option value="1">Period's-1</option>
                <option value="2">Period's-2</option>
                <option value="2">Period's-3</option>
              </select>
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Chart of Fees ₹
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Latest Avialiable Invoice ₹
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Latest Avialiable Quotation ₹
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Negotiated Amount ₹
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
            <div className="col-lg-4 mb-3">
              <label htmlFor="" className="text-grey">
                Advance Amount
              </label>
              <input type="text" className="form-control tasKCategory1" />
            </div>
            <Modal.Footer>
              <div>
                <button className="modal-cancel-btn" onClick={handleClose}>
                  Close
                </button>
                <button className="modal-create-btn ">Submit</button>
              </div>
            </Modal.Footer>
          </div>
        </Modal.Body>
      </Modal>

      {/* <<<<<<...................modal view starts here..........................>>>> */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={priviwe}
        className="create-workflow-main modal-lg "
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
          <img src={close} alt="" onClick={priveclose} />
        </Modal.Header>

        <div>
          <div className="row pb-3">
            <div className="d-flex justify-content-center">
              <div className="col-lg-10 qutation-card ">
                <div className="text-center">
                  <p style={{ fontSize: "25px", fontWeight: "600" }}>
                    Quotation
                  </p>
                  <p>Company Name (Pin Code)</p>
                </div>

                <div className="quote-headings mt-5">
                  <p>To,</p>
                  <p>Robert Jr</p>
                  <p>Date :10-1-2022</p>
                  <p>Subject : XYZ</p>
                </div>
                <div className="mt-4">
                  <p>
                    we are pleased to quote fees as below for the service
                    require by you
                  </p>
                </div>
                <div className="quote-table table-bordered">
                  <table>
                    <tr>
                      <th>Sr. Number</th>
                      <th>Description Of Services</th>
                      <th>Amount (Rs)</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>
                        <p>Three Period Service</p>
                        <p>1. Four Period Service(From Service Description)</p>
                      </td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className="text-end">Gross Amount</td>
                      <td>500</td>
                    </tr>
                  </table>
                </div>
                <div className="mt-4">
                  <p>50% of fee needs to pay in Advance.</p>
                  <p>
                    If you require further information,please feel free to
                    contact us
                  </p>
                </div>
                <div className="mt-4">
                  <h5>THANKING YOU AND ASSURING YOU OF OUR BEST SERVICES!</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Quotation;
