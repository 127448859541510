import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import edit from "../../assets/images/bx_edit.svg";
import delete_ic from "../../assets/images/DelectAcBlc.svg";
import xlicon from "../../assets/images/xls.svg";
import imgicon from "../../assets/images/jpg.svg";
import wordIcon from "../../assets/images/word.svg";
import pdficon from "../../assets/images/pdf.svg.svg";

// import view_blue from "../../assets/images/view_blue.svg";
import letter from "../../assets/images/letter.svg";
import download_icon from "../../assets/images/download_icon.svg";
import download_icon2 from "../../assets/images/salary_download.svg";
import download_icon_blue from "../../assets/images/salary_download_blue.svg";
import view_salary_grey from "../../assets/images/view_salary_grey.svg";
import view_blue from "../../assets/images/view_salary_blue.svg";
import project_document from "../../assets/images/project_document.svg";

import ic_bars_dot from "../../assets/images/ic_bars_dot.svg";
import Logo from "../../assets/images/Logo.svg";
import img_logo_bottom from "../../assets/images/img_logo_bottom.png";
import img_logo_left from "../../assets/images/img_logo_left.png";
import img_logo from "../../assets/images/img_logo.svg";
import img_logo_top from "../../assets/images/img_logo_top.png";
import img_custom from "../../assets/images/img_custom.png";
import ic_zoom from "../../assets/images/ic_zoom.svg";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import ic_green_pen from "../../assets/images/ic_green_pen.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Up_arrow_white from "../../assets/images/Up_arrow_white.svg";
import { Button, Modal } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { AppContext } from "../../context/AppContext";

import { multipartPostCallWithErrorResponse, PostCallWithErrorResponse, simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig, { BASE_URL } from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import FileSaver from "file-saver";

const Personal = () => {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const [isHovered, setIsHovered] = useState(false);
  const [getSalarySleep, setGetSalarySleep] = useState({
    month: "",
    year: ""
  })
  const onMouseEnter = () => {
    setIsHovered(true);
  };
  const onMouseLeave = () => {
    setIsHovered(false);
  };

  console.log("get sallery selip", getSalarySleep)
  const [isDocumentOpen, setIsDocumentOpen] = useState(false)
  const [deleteModel, setDeleteModel] = useState(false)

  const [editId, setEditId] = useState('')
  const showFileImg = useRef()
  const [isHovered1, setIsHovered1] = useState(false);
  const onMouseEnter1 = () => {
    setIsHovered1(true);
  };
  const onMouseLeave1 = () => {
    setIsHovered1(false);
  };

  let [pageLoad, setPageLoad] = useState(true);

  // state of View Button
  //   let [isView, setIsView] = useState(false);
  //   let [isView1, setIsView1] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleDeleteClose = () => setDeleteModel(false)
  const handleShow = (item) => {
    // console.log("document im",item.FileName)
    setShow(true)
    showFileImg.current = item
  }




  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);

  const [noOfRows, setNoOfRows] = useState(1);
  const {
    sidebar,
    setSidebar,
    backdrop,
    setbackdrop,
    profile,
    setprofile,
    temp,
    settemp,
    Dark,
    deleteDocument,
    downloadDocument,
  } = useContext(AppContext);
  const [Print, setPrint] = useState(false);
  const initialEmployeDoc = {
    // EmployeeId: "",
    DocumentHeading: '',
    File: ''
  }

  const [mydocumentList, setMyDocumentList] = useState([])
  const [documentDetails, setDocementDetails] = useState(initialEmployeDoc)
  const [isShowValidation, setIsShowValidation] = useState(false)
  const [isUpload, setIsUpload] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const employeeId = useRef()


  const employeeIds = localStorage.getItem("EmpId")

  const handleChange = (key, val) => {

    setDocementDetails(prev => ({
      ...prev,
      [key]: val
    }))

  }

  const handleCloseDocument = () => {

    setEditId('')

    setIsDocumentOpen(false)
    setDocementDetails("")


  }

  const handleUpdate = (item) => {
    setDocementDetails({
      ...item,
      DocumentHeading: item.DocumentHeading,
      File: item.FileName
    })
  }

  const getMyDocumentList = () => {
    simpleGetCallWithToken(ApiConfig.GET_EMPLOYEE_DOCUMENT)
      .then((res) => {
        console.log("myDocumentData>>>>", res.Data)
        if (res.Success === true) {
          setMyDocumentList(res.Data)
          // setIsLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getMyDocumentList()

  }, [])

  const genretSalarySlip = () => {
    setIsLoading(true)
    const body = {
      "Month": getSalarySleep.month,
      "Year": getSalarySleep.year
    }
    PostCallWithErrorResponse(ApiConfig.GET_GENERATE_MY_SALARY_SLIP, JSON.stringify(body))
      .then((res) => {
        console.log("salarySlip>>>>", res.Data)
        if (res.json.Success === true) {
          setIsLoading(false);
          ToastMsg('success', res?.json?.Message)
          let data = res.json.Data;
          FileSaver.saveAs(
            "data:" + data.ContentType + ";base64," + data.FileContents,
            data.FileDownloadName
          )
          handleClose1()
        } else {
          ToastMsg('error', res?.json?.Message)

          setIsLoading(false);
          handleClose1()

        }
      })
      .catch((error) => {
        ToastMsg('error', error)

        console.log(error)
      })

  }

  const handleSubmit = () => {
    setIsLoading(true)
    console.log(documentDetails);
    if (!documentDetails.File || !documentDetails.DocumentHeading) {
      console.log(documentDetails);
      setIsShowValidation(true)
      return
    }
    setIsUpload(true)
    let url;
    if (editId) {
      const body = {
        "DocumentHeading": documentDetails.DocumentHeading,
      }
      url = PostCallWithErrorResponse(ApiConfig.UPDATGE_MY_DOCUMENT + editId, JSON.stringify(body))
    } else {
      const formData = new FormData()
      formData.append('DocumentHeading', documentDetails.DocumentHeading)
      formData.append('File', documentDetails.File)
      url = multipartPostCallWithErrorResponse(ApiConfig.UPLOAD_MY_DOCUMENT, formData)
    }
    url.then((res) => {

      if (res?.json?.Success === true) {

        ToastMsg('success', res?.json?.Message)
        handleCloseDocument()

        setIsUpload(false)
        setIsLoading(false)
        setDocementDetails('')
        getMyDocumentList()

      } else {

        setIsUpload(false)
        setIsLoading(false)
        ToastMsg('error', res?.json?.Message)

      }

    })

  }

  const deleteProjectDocument = (id) => {
    // setDeleteModel(true)
    employeeId.current = id
  }

  const onDeleteProjectDocument = () => {
    const url = ApiConfig.DELETE_MY_DOCUMENT
    deleteDocument(employeeId.current, url)
    handleDeleteClose()
    // setIsLoading(true)
    setTimeout(() => { getMyDocumentList() }, 1000)
  }

  const downlodPDF = (id) => {
    const url = ApiConfig.DOWNLOD_MY_DOCUMENT
    downloadDocument(id, url)
  }

  return (
    <>
      {/* <Header />
      <Sidebar /> */}

      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">My Documents </h6>
            <div>
              <button className="create-btn-sub-header"
                onClick={() => handleShow1()}
              >Salary Slip</button>
              <button className="create-btn-sub-header"
                onClick={() => setIsDocumentOpen(true)}
              >Upload Document</button>
            </div>
          </div>
          <section className="cx-content-section">
            {
              isLoading ? <Loader /> :
                mydocumentList && mydocumentList?.length > 0 ? (
                  <div className="row main-row">
                    <div className="personal-section">
                      <section className="personal-section-wrapper">
                        <div>
                          <div className="templates_body-main row">
                            {
                              mydocumentList.map((document) => {
                                return <div className="col-lg-3 col-md-4 personal_card">
                                  <div className="training-letter">

                                    <label
                                      htmlFor="temp1"
                                      className="label-main-out d-block"
                                    >
                                      <div className="text-logo ">
                                      <label className="text-dark" style={{ maxWidth: "220px", maxHeight: "30px", overflow: "hidden" }} htmlFor="">{document?.DocumentHeading.length > 30 ? document?.DocumentHeading.slice(0,30)+"..." : document?.DocumentHeading}</label>

                                        <div className="d-flex ">
                                          <img src={edit} alt=""
                                            onClick={() => {
                                              setEditId(document.DocumentId)
                                              handleUpdate(document)
                                              setIsDocumentOpen(true)
                                              handleChange('DocumentHeading', document.DocumentHeading)
                                            }}
                                          />{" "}
                                          <img src={delete_ic} alt=""
                                            onClick={() => {
                                              deleteProjectDocument(document.DocumentId)
                                            }}
                                          />{" "}
                                          <img src={download_icon} alt=""
                                            onClick={() => downlodPDF(document.DocumentId)}
                                          />{" "}
                                        </div>
                                      </div>
                                      <div className="right-logo logo-back">
                                        <div className="img-right img" style={{
                                          display: "flex",  
                                          justifyContent: "center", 
                                          alignItems: "center"

                                        }}>
                                          <img
                                            src={
                                              document?.Extension === ".png" ||  document?.Extension  === ".jpg" ? imgicon
                                                : document?.Extension === ".pdf" ? pdficon
                                                  : document?.Extension === ".docx" ? wordIcon :
                                                    document?.Extension === ".xlsx" ? xlicon : img_logo
                                            }
                                            alt=""
                                            className="folder-img"
                                            onClick={() => handleShow(document)}
                                          />
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              })
                            }
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>) : (
                  <>
                    <NoData />
                  </>
                )
            }
          </section>
        </div>
      </div>


      <div
        onClick={() => {
          setbackdrop(false);
          settemp(false);
        }}
      >
        <Modal
          style={{ zIndex: "2200" }}
          show={show}
          onHide={handleClose}
          className="modal-templates"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="document-popup-modal">

      
         {       showFileImg.current?.Extension === ".png" || showFileImg.current?.Extension === ".jpg" ? (
                  <img
                    src={`${BASE_URL}uploads/${employeeIds}/${showFileImg.current.FileName}`}
                    alt="Preview"
                    className="img-fluid"
                  />
                ) : showFileImg.current?.Extension === ".pdf" ? (
                  <embed
                    src={`${BASE_URL}uploads/${employeeIds}/${showFileImg.current.FileName}`}
                    type="application/pdf"
                    width="100%"
                    height="500px"
                  />
                ) : showFileImg.current?.Extension === ".docx" || showFileImg.current?.Extension === ".xlsx" ? (
                  <p>Preview not available for this document type. You can download it to view.</p>
                ) : (
                  <p>No preview available.</p>
                )}
          
          </Modal.Body>
        </Modal>
      </div>
      <div
        onClick={() => {
          setbackdrop(false);
          settemp(false);
        }}
      >
        <Modal
          size="lg"
          show={show1}
          onHide={handleClose1}
          aria-labelledby="contained-modal-title-vcenter"
          className="salary-slip-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Salary Slip
            </Modal.Title>
          </Modal.Header>
          <div className="lineSeperate"></div>
          <Modal.Body>
            <div className="salary-slip-modal-wrapper">
              <div className="row">
                <div className="col-lg-6">
                  <div className="year-selector">
                    <label htmlFor="" className="mb-2 text-grey">
                      Year
                    </label>
                    <select
                      className="form-select tasKCategory1"
                      aria-label="Default select example"
                      onChange={(e) => setGetSalarySleep({
                        ...getSalarySleep,
                        year: e.target.value
                      })}
                    >
                      <option selected>Select Year</option>
                      <option value="2024">2024</option>
                      <option value="2023">2023</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2011">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="month-selector">
                    <label htmlFor="" className="mb-2 text-grey">
                      Month
                    </label>
                    <select
                      className="form-select tasKCategory1"
                      aria-label="Default select example"
                      onChange={(e) => setGetSalarySleep({
                        ...getSalarySleep,
                        month: e.target.value
                      })}
                    >
                      <option selected disabled>Select Month</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>

                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="buttons-wrapper mt-2">
                    <Modal.Footer>
                      <Button
                        onClick={handleClose1}
                        className="btn-cancel mx-3"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-generate"
                        onClick={genretSalarySlip}
                      >
                        Generate
                      </Button>
                    </Modal.Footer>
                  </div>
                </div>

                {show2 ? (
                  <div className="col-lg-12">
                    <div class="lineSeperate2"></div>
                    <div className="generate-test mt-3">
                      <span className="text-grey">
                        Genrated Salary Slip
                      </span>
                    </div>
                    <div className="download-option mt-2">
                      <div className="date-wrapper">
                        <span>Aug 17, 2022</span>
                      </div>
                      <div className="action-wrapper">
                        <Link
                          to="#"
                          onMouseEnter={onMouseEnter}
                          onMouseLeave={onMouseLeave}
                          onClick={() => {
                            handleShow();
                            setShow1(false);
                          }}
                          className="mx-2"
                        >
                          <span>View</span>
                          {isHovered ? (
                            <img src={view_blue} alt="" />
                          ) : (
                            <img src={view_salary_grey} alt="" />
                          )}
                        </Link>
                        <Link
                          to="#"
                          onMouseEnter={onMouseEnter1}
                          onMouseLeave={onMouseLeave1}
                          className="mx-2"
                        >
                          <span>Download</span>
                          {isHovered1 ? (
                            <img
                              src={download_icon_blue}
                              alt=""
                            />
                          ) : (
                            <img src={download_icon2} alt="" />
                          )}
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <Modal
        className="delete-news-modal"
        show={isDocumentOpen}
        onHide={handleCloseDocument}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">{editId ? "Update" : "Upload"} Document</Modal.Title>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <div className="p-3">
          {!editId && (



            <Form.Group className="mb-3">
              <Form.Label>File</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf, .jpg, .jpeg, .png, .xlsx, .xls"
                onChange={(e) => handleChange('File', e.target.files[0])}
              />
              {!editId && documentDetails.File && (
                typeof documentDetails.File === 'string'
                  ? documentDetails.File.slice(0, 40)
                  : documentDetails.File.name
              )}
              {
                isShowValidation && !documentDetails.File
                && <div className="text-danger" >Please Select Document </div>
              }
            </Form.Group>


          )

          }

          <Form.Group className="mb-3">
            <Form.Label>Document Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Display Name"
              autoFocus
              value={documentDetails.DocumentHeading}
              onChange={(e) => { handleChange('DocumentHeading', e.target.value) }}
            />
              {
                isShowValidation && !documentDetails.DocumentHeading
                && <div className="text-danger" >Please Enter Document Name </div>
              }
          </Form.Group>




        </div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button
            className="btn-width cancelBtn mx-3"
            onClick={handleCloseDocument}
          >
            Cancel
          </button>

          <button className="btn-width saveBtn" onClick={handleSubmit}>
            {editId ? "Update" : "Upload"}
          </button>
        </div>
      </Modal>

      <Modal
        className="delete-news-modal"
        show={deleteModel}
        onHide={handleDeleteClose}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          {/* <p>Are you sure, You want to delete? 
            {/* {"( "+deleteProjectName + " )"} */}
          <p> Are you sure, You want to delete this Document </p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleDeleteClose}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              //deleteProject(useDeleteTaskList)
              // deleteDesignation(DeleteTaskList);
              // handleClose4();
              onDeleteProjectDocument()
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Personal;
