import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import BarChart from "../../component/Barchart.js";
import BookIcon from "../../assets/images/ic_excel.svg";
import Modal from "react-bootstrap/Modal";
import Pagination from "../../sharedComponent/Pagination";
import React, { useContext, useEffect, useState } from "react";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Table } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { simpleGetCallWithToken } from "../../api/ApiServices.js";
import ApiConfig from "../../api/ApiConfig.js";
import Loader from "../Loader.js";
import NoData from "../../sharedComponent/NoData.js";

const SalesInternationalcall = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
  
    const handleShow = () => setShow(true);
  
    const [selectedRows, setSelectedRows] = useState(10);
  const [detailsdata, setDetailsData] = useState([])
  const [loading, setLoding] = useState(false)
  const [searchValue, setSearchValue] = useState("");
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [filterSerchName, setFilterSerchName] = useState([])
  const [currentPage, setCurrentPage] = useState(0);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
  };


  const getFeacthData = () => {
    setLoding(true)
    simpleGetCallWithToken(ApiConfig.INTERNATIONAL_CALL_ASSING)
      .then((response) => {
        console.log("useDetails Data>>>>", response?.users)
        setDetailsData(response?.users)
        setLoding(false)
      })
      .catch((err) => {
        console.log("adent Details Error>>>", err)
        setLoding(false)
      })
  }

  useEffect(() => {
    getFeacthData()
  }, [])

  useEffect(() => {
    // Otherwise, use location_list

    const filteredData = detailsdata?.filter((item) =>
      String(item?.number).toLowerCase().includes(searchValue.toLowerCase())
    );

    console.log("Filtered Data>>", filteredData);
    setFilterSerchName(filteredData);


  }, [detailsdata, searchValue,]);




 




 


  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role mb-4" >
            <h6 className="mt-2">Call Assigning International</h6>
            <button className="custoBtnForCrmCall" onClick={handleShow}>
              <a className=" textcolor">Assign</a>
            </button>
          </div>

           <div className="d-flex justify-content-between mainHeader custom-mb">
                    <div className="role-table-header-main">
                      <div className="search-input-wrapper mt-0 cus-mb">
                        <input type="text"
                          placeholder="Search here"
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                            setCurrentPage(0);
                          }}
                        />
          
                        {Dark === "lightMode" ? (
                          <img src={searchicon} alt="" />
                        ) : (
                          <img src={ic_search_dark} alt="" />
                        )}
                      </div>
                      <div className="entries-wrapper mt-0 cus-mb">
                        <p>Entries per page</p>
                        <select
                          className="form-select select-drop-icon"
                          aria-label="Default select example"
                          // value={selectedRows}
                          onChange={(e) => {
                            setUsersPerPage(e.target.value);
                            setCurrentPage(0);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value={25} selected>25</option>
                          {/* <option value={50}>50</option>
                          <option value={100}>100</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mainIcons">
                      <div className="booksIcon" style={{ textAlign: "center" }}>
                        <img src={BookIcon} className="innerIconsBtn" alt="" />
                      </div>
                    </div>
                  </div>

          <div
            className="  d-flex align-items-center justify-content-center "
            style={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
          ></div>
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table>
              <thead className="firstTabelRow">
                <tr classname="row-hover modal-footer-table-body-tr">
                  <th></th>
                  <th>SR.NO</th>
                  <th>MOBILE NUMBER</th>
                  <th>CALL ADDED</th>
                </tr>
              </thead>
              <tbody>
              {
                loading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  filterSerchName?.length>0?(
                    filterSerchName.slice(startIndex, endIndex).map((item, index) => (
                  <tr key={item.id} className="table-row-custom">
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>{index + 1}</td>
                    <td>{item.number}</td>
                    <td>{item.calldate}</td>
                  </tr>
                ))):(
                  <tr className="ErrorDataNotFound">
                  <td colSpan={9}>
                    <NoData  />
                  </td>
                </tr>
                )
              )}
              </tbody>
            </table>
          </div>
          <Pagination
            data={detailsdata}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
            searchValue={searchValue}
            searchedDataPageCounts={filterSerchName}
          />
        </div>
      </div>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Call Assigning International
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="modalProp mb-3">
            <label htmlFor="" className="enter-role-text my-2">
              Client Name
            </label>
            <select
              className="tasKshowusser"
            // onChange={(e) => setService(e.target.value)}
            >
              <option>Agent List</option>
              <option>Thushara</option>
              <option>sobhisha</option>
            </select>
          </div>
        </Modal.Body>

        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div>
            <button className="custoBtnForCrmCall" onClick={handleClose}>
              Close
            </button>
            <button className=" ml-5 custoBtnForCrmCall" onClick={handleClose}>
              Save Changes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SalesInternationalcall;
