import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/Role.scss";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import editicon from "../../assets/images/bx_edit.svg";
import eye_icon from "../../assets/images/eye_icon.svg";
import rates from "../../assets/images/rates.svg";
import deleteicon from "../../assets/images/delete.svg";
import Modal from "react-bootstrap/Modal";
import close from "../../assets/images/close.svg";
import { AppContext } from "../../context/AppContext";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../ChatAdmin/Pagination";
import CommonButton from "../ChatAdmin/button";

const Fees = () => {
  const { sidebar } = useContext(AppContext);


  const initialState = {
    "Id": 0,
    "ServiceId": "",
    "EffectiveDate": "",
    "BaseFee": "",
    "IncreaseBasis": "",
    "BaseThreshold": "",
    "Step": "",
    "IncreasePerStep": ""
  }

  const [state, setState] = useState(initialState)
  const [services, setServices] = useState([])
  const [ratesList, setRateList] = useState([])
  const [isValiteded, setIsValiteded] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [perPageDate, setPerPageData] = useState(25)
  const [pageCount, setPageCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentpage] = useState(1)
  const [search, setSearch] = useState('')
  const [isApiLoad, setIsApiLoad] = useState(false)


  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [conomation, setConomation] = useState(false);

  const handleClose = () => {
    setShow(false)
    setIsValiteded(false)
    setState(initialState)

  }
  const handleClosed = () => setEdit(false);
  const handeldeleteclose = () => setConomation(false);

  const getRateTrable = () => {

    setIsLoading(true)

    simpleGetCallWithErrorResponse(ApiConfig.GET_RATE_RATE_TABLE + `?currentPage=${currentPage}&pageSize=${perPageDate}`).then((res) => {


      if (res?.json?.Success) {

        setPageCount(res?.json?.PageCount)

        setRateList(res?.json?.Data)

        setIsLoading(false)

      }

    })

  }


  useEffect(() => {
    getRateTrable()
  }, [perPageDate, currentPage])

  const handleShow = () => {
    getServices()
    setShow(true)

  }

  const getServices = () => {

    if (services.length) return

    simpleGetCallWithErrorResponse(ApiConfig.GET_SERVICES).then((res) => {

      if (res?.json?.success) {

        setServices(res?.json?.data)


      }

    })

  }

  const handleChange = (key, val) => {

    setState(prev => ({
      ...prev,
      [key]: val
    }))

  }

  const handleSubmit = () => {

    let keys = Object.keys(state)

    let isValid = true

    keys.forEach((key) => {

      if (key != 'Id' && !state[key]) {

        isValid = false

        setIsValiteded(true)

      }

    })

    if (isValid) {

      setIsApiLoad(true)

      let api = state.Id == 0 ? ApiConfig.CREATE_RATE_TABLE : ApiConfig.UPDATE_RATE_TABLE

      PostCallWithErrorResponse(api, JSON.stringify(state)).then((res) => {

        if (res?.json?.Success) {

          ToastMsg('success', res?.json?.Message)

          handleClose()

          getRateTrable()

        } else {

          ToastMsg('error', res?.json?.Message)

        }

        setIsApiLoad(false)

      })

    }

  }

  const getSingleRate = (id) => {

    simpleGetCallWithErrorResponse(ApiConfig.GET_SINGLE_RATE + id).then((res) => {

      if (res?.json?.Success) {

        setState(res?.json?.Data)

        handleShow()

      } else {

        ToastMsg('error', 'something went wrong try again')

      }

    })

  }




  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div
      >
        <div className="role-content">
          <div className="sub-header-role">
            <h6 className="mt-2 role-text">Rate Table</h6>
            <button className="create-btn-sub-header" onClick={handleShow}>
              Create
            </button>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder">
            <div className="role-table-header reponsivesrc">
              <div className="search-input-wrapper">
                <input type="text" placeholder="Search By Service Name"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <img src={searchicon} alt="" />
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  class="form-select select-drop-icon remove-border"
                  aria-label="Default select example"
                  value={perPageDate}
                  onChange={(e) => {
                    setCurrentpage(1)
                    setPerPageData(e.target.value)
                  }}
                >
                  <option selected value='25'>25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="role-btns-wrapper">
              <button>
                <img src={importicon} alt="" />
              </button>
              <button>
                {" "}
                <img src={exporticon} alt="" />
              </button>
            </div>
          </div>
          <div className="table-wrapper">
            {
              isLoading ? <Loader /> :
                ratesList.length === 0 ? <NoData /> :
                  <table>
                    <tr>
                      <th>Sr. no</th>
                      <th>Service Name</th>
                      <th>Effective date</th>
                      <th>Base Fee</th>
                      <th>Basis for Increase</th>
                      <th>Base Threshold</th>
                      <th>Step</th>
                      <th>Increase per Step</th>
                      <th>Action</th>
                    </tr>
                    {
                      ratesList.filter((rate) => {
                        return search?.length ? rate?.ServiceName.toLowerCase().includes(search.toLowerCase()) : rate
                      }).map((rate, index) => {

                        return <tr className="table-row-custom">
                          <td>{(currentPage - 1) * perPageDate + index + 1}</td>
                          <td>{rate?.ServiceName}</td>
                          <td>{rate?.EffectiveDate}</td>
                          <td>{rate?.BaseFee}</td>
                          <td>{rate?.IncreaseBasis} </td>
                          <td>{rate?.BaseThreshold}</td>
                          <td>{rate?.Step}</td>
                          <td>{rate?.IncreasePerStep}</td>
                          <td>
                            <img
                              src={editicon}
                              alt=""
                              onClick={() => getSingleRate(rate?.Id)}
                              className="me-2"
                            />

                          </td>
                        </tr>

                      })
                    }

                  </table>
            }
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12">
            <Pagination
              totalPage={pageCount}
              currentPage={currentPage}
              pageChangeHandler={(page) => setCurrentpage(page + 1)}
            />
          </div>
        </div>
      </div>



      {/* edit model */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        className=" modal-lg"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              {state?.id == 0 ? "Add" : "Update"} Rates
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClose} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="row  pb-4">

            <div className="col-lg-3 mt-4">
              <label htmlFor="" className="text-grey">
                Service
              </label>
              <select
                class="form-select tasKCategory1 mb-2"
                aria-label="Default select example"
                value={state.ServiceId}
                onChange={(e) => handleChange('ServiceId', Number(e.target.value))}
              >
                <option disabled value={''}>Select Service</option>
                {

                  services?.map((service) => {
                    return <option value={service.id}>{service.name}</option>
                  })

                }
              </select>
              {
                isValiteded && !state.ServiceId && <p className="text-danger">Select Service</p>
              }
            </div>
            <div className="col-lg-3 mt-4">
              <label htmlFor="" className="text-grey">
                Effective From
              </label>
              <input type="date" className="form-control tasKCategory1 mb-2"
                value={state.EffectiveDate}
                onChange={(e) => handleChange('EffectiveDate', e.target.value)}
              />
              {
                isValiteded && !state.EffectiveDate && <p className="text-danger">Select Date</p>
              }
            </div>
            <div className="col-lg-3 mt-4">
              <label htmlFor="" className="text-grey">
                Base fee
              </label>
              <input type="number" className="form-control tasKCategory1 mb-2"
                value={state.BaseFee}
                onChange={(e) => handleChange('BaseFee', e.target.value)} />
              {
                isValiteded && !state.BaseFee && <p className="text-danger">Enter Base fee</p>
              }
            </div>
            <div className="col-lg-3 mt-4">
              <label htmlFor="" className="text-grey">
                Basis for Increase
              </label>
              <select
                class="form-select tasKCategory1 mb-2"
                aria-label="Default select example"
                value={state.IncreaseBasis}
                onChange={(e) => handleChange('IncreaseBasis', e.target.value)}
              >
                <option disabled value={''}>Select Basis For Increases</option>
                <option value={'Business Turnover'}>Business Turnover </option>
                <option value="No. of Transaction Entries">No. of Transaction Entries </option>
                <option value="Increase Not Applicable">Increase Not Applicable </option>
              </select>
              {
                isValiteded && !state.IncreaseBasis && <p className="text-danger">Select Basis For Increases</p>
              }
            </div>
            <div className="col-lg-3 mt-4">
              <label htmlFor="" className="text-grey">
                Base Threshold
              </label>
              <input type="text" className="form-control tasKCategory1 mb-2"
                value={state.BaseThreshold}
                onChange={(e) => handleChange('BaseThreshold', e.target.value)} />
              {
                isValiteded && !state.BaseThreshold && <p className="text-danger">Enter base Threshold</p>
              }
            </div>
            <div className="col-lg-3 mt-4">
              <label htmlFor="" className="text-grey">
                Step
              </label>
              <input type="number" className="form-control tasKCategory1 mb-2"
                value={state.Step}
                onChange={(e) => handleChange('Step', e.target.value)} />
              {
                isValiteded && !state.Step && <p className="text-danger">Enter Step</p>
              }
            </div>

            <div className="col-lg-3 mt-4">
              <label htmlFor="" className="text-grey">
                Increase per Step
              </label>
              <input type="number" className="form-control tasKCategory1 mb-2"

                value={state.IncreasePerStep}
                onChange={(e) => handleChange('IncreasePerStep', e.target.value)} />
              {
                isValiteded && !state.IncreasePerStep && <p className="text-danger">Enter IncreasePerStep</p>
              }
            </div>
          </div>
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div>
            <button className="modal-cancel-btn" onClick={handleClose}>Cancel</button>

            <CommonButton
              title={state?.Id == 0 ? 'Save' : 'Update'}
              isLoading={isApiLoad}
              handlerFunction={handleSubmit}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Fees;
