import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import printicon from "../../assets/images/print.svg";
import eyeicon from "../../assets/images/eye.svg";
import file from "../../assets/images/file_icon.svg";
import paint from "../../assets/images/paint.svg";
import seprater from "../../assets/images/seprate.svg";
import avatar from "../../assets/images/avatar.svg";
import uploadicon from "../../assets/images/cloud-icon.svg";
import pen from "../../assets/images/penicon.svg";

function AccountsGenerateInvoice() {
  const { sidebar } = useContext(AppContext);

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"}  id="cx-main">

    <div
      
    >
      <div className="sub-header-role">
        <h6 className="mt-2 role-text">Generate Invoice</h6>
      </div>
      <div className="row mt-4">
        <div className="">
          <div className="col-lg-12 invoice-head-content">
            <div className="invoice-header">
              <div className="small-invoicesize22">
                <label htmlFor="">INVOICE :</label>
                <label> #AS0001</label>
              </div>
              <div className="right-side-menus">
                <div>
                  <img src={printicon} alt="" />
                </div>
                <div>
                  <img src={eyeicon} alt="" className="ms-2" />
                </div>
                <div>
                  <img src={file} alt="" className="ms-2" />
                </div>
                <div>
                  <img src={paint} alt="" className="ms-2" />
                </div>
                <div>
                  <img src={seprater} alt="" className="ms-2" />
                </div>
                <div>
                  <img src={avatar} alt="" className="ms-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex justify-content-center">
          <div className="col-lg-12 tax-invoice-content content-padding22" style={{paddingTop:"40px"}}>
            <div className="taxinvoice-section-1">
              <div className="section1_Accounting">
                <div>
                  <label for="uploadcloud" id="upload-btn-cloud" className="CIUplodeSection">
                    <div className="text-center align-self-center uplodeAccount">
                      <img src={uploadicon} alt="" className="" />
                      <p>Upload Files</p>
                    </div>
                    <input type="file" name="uploadcloud" id="uploadcloud" />
                  </label>
                </div>
                <div className="maindivSection-2">
                  <label htmlFor="" className="tax-invoice">
                    Tax Invoice
                  </label>
                  <div>
                    <label htmlFor="" className="invoice-numbers">
                      Invoice No.
                    </label>
                    <label htmlFor="" className="invoice-number-color">
                      XXXXXX
                    </label>
                  </div>
                  <div className="minboxwrapper">
                    <div>
                      <label htmlFor="" className="invoice-numbers">
                        Date :
                      </label>
                    </div>
                    <div>
                      <input type="date" className="minInputBox CIminInputBox" />
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                  </div>
                  <div className="minboxwrapper">
                    <div>
                      <label htmlFor="" className="invoice-numbers">
                        Due Date :
                      </label>
                    </div>
                    <div>
                      <input type="date" className="minInputBox CIminInputBox" />
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="taxinvoice-section-1">
              <div className="section1_Accounting">
                <div>
                  <div className="mt-2">
                    <input
                      type="text"
                      placeholder="Owner Name"
                      className="owner-name-input leftinput CIleftinput"
                    />

                  </div>
                  <div className="mt-2">
                    <textarea
                      placeholder="Address"
                      name=""
                      id=""
                      className="cloud-text-area"
                    ></textarea>
                  </div>
                  <div className="minboxwrapperLeft">
                    <div>
                      <label htmlFor="" className="invoice-numbers">
                        Date :
                      </label>
                    </div>
                    <div>
                      <input type="date" className="minInputBoxLeft" />
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                  </div>
                  <div className="minboxwrapperLeft">
                    <div>
                      <label htmlFor="" className="invoice-numbers">
                        GST No.  :
                      </label>
                    </div>
                    <div>
                      <input type="date" className="minInputBoxLeft" />
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                  </div>
                  <div className="minboxwrapperLeft">
                    <div>
                      <label htmlFor="" className="invoice-numbers">
                        PAN No. :
                      </label>
                    </div>
                    <div>
                      <input type="date" className="minInputBoxLeft" />
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                  </div>
                </div>
                <div className="maindivSection-2">
                  <div className="mb-3">
                    <p>Place of Supply:</p>
                    <div className="d-flex selectborderremove">
                      <select
                        class="form-select  select-drop-icon-cloud"
                        aria-label="Default select example"
                      >
                        <option selected>Maharashtra-27</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                  </div>
                  <div>
                    <p>Mode of Payment::</p>
                    <div className="d-flex mb-2 selectborderremove">
                      <select
                        class="form-select  select-drop-icon-cloud"
                        aria-label="Default select example"
                      >
                        <option selected>Maharashtra-27</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                    <div className="d-flex mb-2">
                      <input type="text" className="owner-name-input" placeholder="Cheque No. XXX06542" />
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                    <div className="d-flex">
                      <input type="text" className="owner-name-input" placeholder="Name of Bank" />
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="taxinvoice-section-1 lastsection">
              <div className="section1_Accounting">
                <div>
                  <div className=" maindivSection-2">
                    <p>Bill to</p>
                    <input
                      type="text"
                      placeholder="Owner Name"
                      className="owner-name-input leftinput"
                    />
                  </div>
                  <div className="mt-2">
                    <textarea
                      placeholder="Address"
                      name=""
                      id=""
                      className="cloud-text-area"
                    ></textarea>
                  </div>
                  <div className="minboxwrapperLeft">
                    <div>
                      <label htmlFor="" className="invoice-numbers">
                        Date :
                      </label>
                    </div>
                    <div>
                      <input type="date" className="minInputBoxLeft" />
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                  </div>
                  <div className="minboxwrapperLeft">
                    <div>
                      <label htmlFor="" className="invoice-numbers">
                        GST No.  :
                      </label>
                    </div>
                    <div>
                      <input type="date" className="minInputBoxLeft" />
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                  </div>
                  <div className="minboxwrapperLeft">
                    <div>
                      <label htmlFor="" className="invoice-numbers">
                        PAN No. :
                      </label>
                    </div>
                    <div>
                      <input type="date" className="minInputBoxLeft" />
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                  </div>
                </div>
                <div className="parparapar maindivSection-2">
                  <p>Ship to</p>
                  <div className=" d-flex">
                    <input
                      type="text"
                      placeholder="Owner Name"
                      className="owner-name-input"
                    />
                    <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                  </div>
                  <div className="mt-2 d-flex">
                    <textarea
                      placeholder="Address"
                      name=""
                      id=""
                      className="cloud-text-area"
                    ></textarea>
                    <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                  </div>
                  <div className="minboxwrapper">
                    <div>
                      <label htmlFor="" className="invoice-numbers">
                        Date : 
                      </label>
                    </div>
                    <div>
                      <input type="date" className="minInputBox CIminInputBox" />
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                  </div>
                  <div className="minboxwrapper">
                    <div>
                      <label htmlFor="" className="invoice-numbers">
                        GST No.  :
                      </label>
                    </div>
                    <div>
                      <input type="date" className="minInputBox CIminInputBox" />
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                  </div>
                  <div className="minboxwrapper">
                    <div>
                      <label htmlFor="" className="invoice-numbers">
                        PAN No. :
                      </label>
                    </div>
                    <div>
                      <input type="date" className="minInputBox CIminInputBox" />
                      <img src={pen} alt="" style={{ marginLeft: "8px" }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="accountsectiontabelrecipt CITabelMain">
                <table border="1">
                  <tr>
                    <th>#</th>
                    <th>Product Name</th>
                    <th>HSN/SAC</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th colSpan="2">CGST</th>
                    <th colSpan="2">SGST</th>
                    <th>Amount</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td><input type="text" placeholder="Enter Name of Product" /></td>
                    <td><input type="number" placeholder="Code" /></td>
                    <td><input type="number" placeholder="0" /></td>
                    <td><input type="number" placeholder="0.0" /></td>
                    <td><input type="number" placeholder="0%" /></td>
                    <td><input type="number" placeholder="0.0" /></td>
                    <td><input type="number" placeholder="0%" /></td>
                    <td><input type="number" placeholder="000" /></td>
                    <td><input type="number" placeholder="00" /></td>
                  </tr>
                </table>
              </div>
              <div className="col-lg-12 mt-4 ">
                <button className="modal-create-btn pe-4 ps-4">Add Row</button>
              </div>
              <div className="row text-end accountsectiontotal">
                <div className="col-lg-4"></div>
                <div className="col-lg-4"></div>
                <div className="col-lg-4 maintabel">
                  <div>
                    <label>Sub Total</label>
                    <p>$0.0</p>
                  </div>
                  <div>
                    <label>CGST@ 0%</label>
                    <p>$0.0</p>
                  </div>
                  <div>
                    <label>SGST@ 0%</label>
                    <p>$0.0</p>
                  </div>
                </div>
              </div>
              <div className="row text-end accountsectiontotal-2">
                <div className="col-lg-3"></div>
                <div className="col-lg-4"></div>
                <div className="col-lg-5 maintabel-2">
                  <div>
                    <label>Total Amount</label>
                    <p>$0.0</p>
                  </div>
                  <div>
                    <label></label>
                    <p>Amount in words</p>
                  </div>
                </div>
              </div>
              <div className="row bottonsectionaccounyting CIresposivebotom small-acoountingsection22">
                <div className="col-lg-7 textareabottom">
                  <textarea name="" id="" placeholder="Declaration"></textarea>
                </div>
                <div className="col-lg-5 uplodebottomsection">
                  <label for="uploadcloud" >
                    <div className="text-center align-self-center">
                      <img src={uploadicon} alt="" className="" />
                      <p>Authorized Signature</p>
                    </div>
                    <input type="file" name="uploadcloud" id="uploadcloud" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

  );
}

export default AccountsGenerateInvoice;
