import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import close from "../../assets/images/close.svg";
import righticon from "../../assets/images/right.svg";
import Select from "react-select";

import Pagination from "../../../src/sharedComponent/Pagination";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { Modal } from "react-bootstrap";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";

function ServiceMapping() {

  const { branchId } = useParams()
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {

    setShow(false)

  }
  const handleShow = () => setShow(true);


  const { sidebar } = useContext(AppContext);
  const [serviceMappingList, setServiceMappingList] = useState([]);
  useEffect(() => {
    getServiceMappingList();
    getServices();
  }, []);
  const [services, setServices] = useState([]);
  const getServices = () => {
    simpleGetCallWithErrorResponse(ApiConfig.GET_SERVICES)
      .then((res) => {
        if (res.json && res.json?.success) {
          setServices(res?.json?.data);
          console.log(services);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getServiceMappingList = () => {

    setIsLoading(true)
    simpleGetCallWithErrorResponse(ApiConfig?.GET_SERVICE_MAPPING_LIST + branchId)
      .then((res) => {
        if (res.json?.success) {
          setServiceMappingList(res?.json?.data);


        }

        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSumit = () => {

    const payload = {
      BranchId: Number(branchId),
      Service: selectedServices.map((service) => service.value),
    };

    PostCallWithErrorResponse(ApiConfig?.CREATE_MAPPING, JSON.stringify(payload)).then((res) => {

      if (res?.json?.success) {

        ToastMsg('success', res?.json?.message)

        getServiceMappingList()

        handleClose()

      } else {

        ToastMsg('error', res?.json?.message)

      }

    })

  }




  const [selectedServices, setSelectedServices] = useState([]);

  const handleRemoveMapping = async (id) => {
    try {
      const res = await simpleGetCallWithErrorResponse(`${ApiConfig?.REMOVE_MAPPING_REC}/${id}`);
      if (res?.json?.success) {
        ToastMsg('success', res?.json?.message)
        getServiceMappingList()

      } else {
        ToastMsg('error', res?.json?.message)
      }
    } catch (error) {
      console.error("Error removing user:", error);
      // alert("An error occurred. Please try again.");
    }
  };
  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role">
          <h6 className="mt-2 role-text">Service Mapping</h6>
          <div>
            <button
              className="create-btn-sub-header"
              style={{ marginRight: "0" }}
              onClick={handleShow}
            >
              Create
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder servicemappingheader">
          <div className="role-table-header reponsivesrc">
            {/* <div className="search-input-wrapper">
              <input type="text" placeholder="Search" />
              <img src={searchicon} alt="" />
            </div>
            <div className="entries-wrapper">
              <p>Entries per page</p>
              <select
                class="form-select select-drop-icon remove-border responsive-arbaz"
                aria-label="Default select example"
              >
                <option selected>10</option>
                <option value="1">20</option>
                <option value="2">10</option>
                <option value="3">5</option>
              </select>
            </div>
            <div className="client-wrapper">
              <p>Client Name</p>
              <select
                class="form-select select-drop-icon remove-border"
                aria-label="Default select example"
              >
                <option selected>John Wick</option>
                <option value="1">John Carter</option>
                <option value="2">Ethan Hunt</option>
                <option value="3">Brad Pitt</option>
              </select>
            </div>

            <div className="client-wrapper">
              <p>Service</p>
              <select
                class="form-select select-drop-icon remove-border"
                aria-label="Default select example"
              >
                <option selected>ITR TEST 1507 FY2021-2022</option>
                <option value="1">ITR TEST 1507 FY2021-2022</option>
                <option value="2">ITR TEST 1507 FY2021-2022</option>
                <option value="3">ITR TEST 1507 FY2021-2022</option>
              </select>
            </div> */}
          </div>
          <div className="role-btns-wrapper servicemappingbtnexport">
            <button className="import-icon-img">
              <img src={importicon} alt="" />
            </button>
            <button>
              {" "}
              <img src={exporticon} alt="" />
            </button>
          </div>
        </div>
        <div className="table-wrapper">
          <table>
            <tr>
              <th>Sr. no</th>
              <th>Service Name</th>
              <th>Branch Name</th>
              {/* <th>Branch</th>
              <th>Services</th>
              <th>Start Date</th>
              <th>End Date</th> */}
              <th>Action</th>
            </tr>
            {isLoading ? <div className="d-flex justify-content-center "> <Loader /></div> : serviceMappingList?.length === 0 ?
              <div className="d-flex justify-content-center">
                <NoData />
              </div>
              : serviceMappingList.map((srnumber, index) => {
                return (
                  <tr key={"srnumber" + index} className="table-row-custom">
                    <td>{index + 1}</td>
                    <td>{srnumber?.serviceName}</td>
                    <td>{srnumber?.branchName}</td>
                    {/* <td>{ srnumber?.branchName}</td> */}
                    <td onClick={() => handleRemoveMapping(srnumber?.id)}>Remove</td>

                  </tr>
                );
              })}
          </table>
        </div>
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        className="modal-md"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Create Client Profile
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClose} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="row">
            <label htmlFor="" className="label-text">
              Services
            </label>
            <Select
              isMulti
              options={services.map((service) => ({
                value: service.id,
                label: service.name,
              }))}
              onChange={(selectedOptions) => setSelectedServices(selectedOptions)}
            />

            {/* <div className="col-lg-12 mt-4">
                  <label htmlFor="" className="label-text">
                    Services
                  </label>
                  <select
                    class="form-select select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option selected>select service</option>
                    <option value="1">service1</option>
                    <option value="2">service2</option>
                    <option value="3">service3</option>
                  </select>
                </div> */}
          </div>
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            {/* <button className="modal-cancel-btn" onClick={handleClose}>
                  Cancel
                </button> */}
            <button className="modal-create-btn" onClick={handleSumit}>Submit</button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* <div className="row mt-4">
        <div className="col-lg-12">
          <Pagination />
        </div>
      </div> */}
    </div>
  );
}

export default ServiceMapping;
