import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

import ic_view from "../../assets/images/ic_view.svg";
import image_prf from "../../assets/images/image_prf.svg";
import ic_search from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import edit from "../../assets/images/bx_edit.svg";
import delete_ic from "../../assets/images/delete_icon_gray.svg";
// import view_blue from "../../assets/images/view_blue.svg";
import letter from "../../assets/images/letter.svg";
import download_icon from "../../assets/images/download_icon.svg";
import download_icon2 from "../../assets/images/salary_download.svg";
import download_icon_blue from "../../assets/images/salary_download_blue.svg";
import view_salary_grey from "../../assets/images/view_salary_grey.svg";
import view_blue from "../../assets/images/view_salary_blue.svg";
import project_document from "../../assets/images/project_document.svg";

import ic_bars_dot from "../../assets/images/ic_bars_dot.svg";
import Logo from "../../assets/images/Logo.svg";
import img_logo_bottom from "../../assets/images/img_logo_bottom.png";
import img_logo_left from "../../assets/images/img_logo_left.png";
import img_logo from "../../assets/images/img_logo.svg";
import img_logo_top from "../../assets/images/img_logo_top.png";
import img_custom from "../../assets/images/img_custom.png";
import ic_zoom from "../../assets/images/ic_zoom.svg";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import ic_green_pen from "../../assets/images/ic_green_pen.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Up_arrow_white from "../../assets/images/Up_arrow_white.svg";
import { Button, Modal } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { AppContext } from "../../context/AppContext";
import Axe from "../../assets/images/x.svg";
import close_modal from "../../assets/images/close_modal.svg";
import close_modal_dark from "../../assets/images/close_modal_dark.svg";
import { multipartPostCallWithErrorResponse, PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import ButtonLoader from "../../sharedComponent/ButtonLoader";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import FileSaver from "file-saver";
import Pagination from "../../sharedComponent/Pagination";

const ProjectDetails = () => {

  const {
    sidebar,
    setSidebar,
    backdrop,
    setbackdrop,
    profile,
    setprofile,
    temp,
    settemp,
    Dark,
    handleEditDocument,
    deleteDocument,
    downloadDocument,
    permissions
  } = useContext(AppContext);

  const initialEmployeDoc = {
    EmployeeId: "",
    DocumentHeading: '',
    DocumentLink: ''
  }

  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const [documentList, setDocumentList] = useState([])

  const [documentDetails, setDocementDetails] = useState(initialEmployeDoc)

  const [isDocumentOpen, setIsDocumentOpen] = useState(false)

  const [editId, setEditId] = useState(0)

  const [isShowValidation, setIsShowValidation] = useState(false)

  const [isUpload, setIsUpload] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [selectProject, setSelectProject] = useState('')


  const [show, setShow] = useState(false);

  const projectID = useRef()

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false)


  const [employeeList, setEmployeeList] = useState([])

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  // const searchedDataPageCounts =
  // documentList &&
  // documentList.length &&
  // documentList.filter((item) =>
  //   item.DocumentHeading?.toLowerCase().includes(searchValue)
  // );

  const handleChange = (key, val) => {

    setDocementDetails(prev => ({
      ...prev,
      [key]: val
    }))

  }

  const handleUpdate = (item) => {
    setDocementDetails({
      ...item,
      EmployeeId: item.ProjectId,
      DocumentHeading: item.DocumentHeading,
      DocumentLink: item.DocumentLink
    })
  }

  const getDocumentList = (id) => {

    let url;
    if (id) {
      url = ApiConfig.PROJECT_LINK_BYiD + `${id}`
    } else {
      url = ApiConfig.PROJECT_LINK_LIST
    }

    setIsLoading(true)
    simpleGetCallWithErrorResponse(url).then(res => {

      if (res?.json?.Success === true) {
        setIsLoading(false)
        setDocumentList(res?.json?.Data)

      } else {
        setIsLoading(false)
      }
    })

  }

  const getEmployeeList = () => {

    simpleGetCallWithErrorResponse(ApiConfig.PROJECT_LIST_DROPDOWN).then((res) => {

      if (res?.json?.Success) {

        setEmployeeList(res?.json?.Data)

      }

    })

  }

  useEffect(() => {
    getDocumentList(selectProject)
  }, [selectProject])

  useEffect(() => {

    // getDocumentList()
    getEmployeeList()

  }, [])






  const handleCloseDocument = () => {

    setIsDocumentOpen(false)

    setEditId('')



    setIsShowValidation(false)

    setDocementDetails(initialEmployeDoc)

  }


  const handleSubmit = () => {

    console.log(documentDetails);


    if (!documentDetails.DocumentLink || !documentDetails.DocumentHeading || !documentDetails.EmployeeId) {
      console.log(documentDetails);
      setIsShowValidation(true)

      return

    }

    setIsUpload(true)


    const body = {
      ProjectId: Number(documentDetails.EmployeeId),
      DocumentHeading: documentDetails.DocumentHeading,
      DocumentLink: documentDetails.DocumentLink,
      DocumentId: editId
    }


    PostCallWithErrorResponse(ApiConfig.PROJECT_LINK_CREATE, JSON.stringify(body)).then((res) => {

      if (res?.json?.Success) {

        ToastMsg('success', res?.json?.Message)
        handleCloseDocument()

        setIsUpload(false)
        setIsLoading(true)

        getDocumentList()

      } else {

        setIsUpload(false)

        ToastMsg('error', res?.json?.Message)

      }

    })

  }

  const downlodPDF = (id) => {
    const url = ApiConfig.PROJECT_DOCUMENT_DOWNLOAD
    downloadDocument(id, url)
  }

  const deleteProjectDocument = (id) => {
    handleShow()
    projectID.current = id
  }

  const onDeleteProjectDocument = () => {
    const url = ApiConfig.PROJECT_LINK_DELETE
    deleteDocument(projectID.current, url)
    handleClose()
    setIsLoading(true)
    setTimeout(() => { getDocumentList() }, 1000)
  }


  return (
    <>
      {/* <Header />
      <Sidebar /> */}

      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Project Links </h6>
            <div className="entries-wrapper ">

              {
                permissions && permissions['Create Link'] &&
                <button className="create-btn-sub-header "
                  onClick={() => setIsDocumentOpen(true)}
                >Create Link</button>
              }

            </div>

          </div>

          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header accoridance">

              <div className="entries-wrapper myAccordiance">
                {
                  permissions && permissions['View Project Documents'] &&
                  <Link to="/projectdocumentation">
                    <button className="my-anc-btn-sub-header">
                      Project Document
                    </button>
                  </Link>
                }
              </div>
              <div className="search-input-wrapper allAcordiance">
                {
                  permissions && permissions['View Project Links'] &&
                  <Link to="/projectdetails">
                    <button className=" active all-anc-btn-sub-header ">
                      Project  Links
                    </button>
                  </Link>
                }
                {/* )} */}
              </div>
              <div className="mt-4">
                <select
                  className="form-select select-drop-icon mr-2"
                  aria-label="Default select example"
                  style={{ border: " 1px solid #53b7e8" }}
                  onChange={(e) => {
                    setSelectProject(e.target.value);
                    // setCurrentPage(0);
                  }}
                >
                  <option value={""}>Select Project</option>
                  {
                    employeeList.map((emp) => {

                      return <option value={emp.ProjectId}>{emp.ProjectName}</option>

                    })
                  }
                </select>
              </div>
              <div className="role-table-header">
                <div className="entries-wrapper">
                  <p>Entries per page</p>
                  <select
                    style={{ width: "200px" }}
                    className="form-select select-drop-icon "
                    aria-label="Default select example"
                    onChange={(e) => {
                      setUsersPerPage(e.target.value);
                      setCurrentPage(0);
                    }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25" selected>
                      25
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>


          <section className="cx-content-section">

            {
              isLoading ? <Loader /> :
                documentList && documentList?.length > 0 ? (

                  <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                    <table className="table table-striped">
                      <thead>
                        <tr >
                          <th className="ml-2">Project Name</th>
                          <th>Project Links</th>
                          {
                            permissions && (permissions['Delete Link'] || permissions['Edit Link']) &&

                            <th> Actions</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {documentList.slice(startIndex, endIndex).map((project, index) => (
                          <tr key={index} className="table-row-custom">
                            <td>
                              <span className="project-name">{project.DocumentHeading}</span>
                            </td>
                            <td>
                              <Link to={project.DocumentLink} target="_blank" className="project-links">
                                {project.DocumentLink}
                              </Link>
                            </td>
                            {
                              permissions && (permissions['Delete Link'] || permissions['Edit Link']) &&
                              <td>


                                {

                                  permissions && permissions['Edit Link'] &&

                                  <img src={edit} alt=""
                                    onClick={() => {
                                      setEditId(project.DocumentId)
                                      handleUpdate(project)
                                      setIsDocumentOpen(true)
                                      handleChange('DocumentHeading', project.DocumentHeading)
                                    }}
                                  />
                                }
                                {

                                  permissions && permissions['Delete Link'] &&
                                  <img src={delete_ic} alt=""
                                    onClick={() => {
                                      deleteProjectDocument(project.DocumentId)



                                    }}
                                  />
                                }
                                {/* <img src={download_icon} alt=""
                                            onClick={() => downlodPDF(document.DocumentId)}
                                          /> */}
                                {/* </div> */}
                              </td>
                            }
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                ) : (
                  <>
                    <NoData />
                  </>
                )
            }
          </section>
          <Pagination
            data={documentList}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
          // searchValue={searchValue}
          // searchedDataPageCounts={searchedDataPageCounts}
          />
        </div >
      </div >

      <Modal
        className="delete-news-modal"
        show={isDocumentOpen}
        onHide={handleCloseDocument}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">{editId ? "Update" : "Upload"} Document</Modal.Title>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <div className="p-3">
          {/* {
            !editId && */}

          {/* } */}

          <Form.Group className="mb-3">
            <Form.Label>Link Heading</Form.Label>
            <Form.Control
              type="text"
              placeholder="Display Name"
              value={documentDetails.DocumentHeading}
              onChange={(e) => { handleChange('DocumentHeading', e.target.value) }}
            />
            {
              isShowValidation && !documentDetails.DocumentHeading && <div className="text-danger" >Please Enter Link Heading </div>
            }
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Document Link</Form.Label>

            <Form.Control
              type="text"
              placeholder="Link Heading"
              value={documentDetails.DocumentLink}
              onChange={(e) => { handleChange('DocumentLink', e.target.value) }}
            />
            {
              isShowValidation && !documentDetails.DocumentLink && <div className="text-danger" >Please Enter Document Link </div>
            }
          </Form.Group>
          {/* {
            !editId && */}
          <Form.Group>
            <Form.Label>Select Project</Form.Label>
            <Form.Select className="mb-3"
              value={documentDetails.EmployeeId}
              onChange={(e) => handleChange('EmployeeId', e.target.value)}
            >
              <option>Select Project</option>
              {
                employeeList.map((emp) => {

                  return <option value={emp.ProjectId}>{emp.ProjectName}</option>

                })
              }
            </Form.Select>
            {
              isShowValidation && !documentDetails.EmployeeId && <div className="text-danger" >Please Select Project </div>
            }
          </Form.Group>
          {/* } */}




        </div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button
            className="btn-width cancelBtn mx-3"
            onClick={handleCloseDocument}
          >
            Cancel
          </button>

          <button className="btn-width saveBtn"
            onClick={handleSubmit}
            disabled={isUpload}
          >
            {
              isUpload ? <ButtonLoader /> : editId ? "Update" : "Upload"
            }
          </button>
        </div>
      </Modal>

      <Modal
        className="delete-news-modal"
        show={show}
        onHide={handleClose}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          {/* <p>Are you sure, You want to delete? 
            {/* {"( "+deleteProjectName + " )"} */}
          <p> Are you sure, You want to delete this Project Document Link </p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              //deleteProject(useDeleteTaskList)
              // deleteDesignation(DeleteTaskList);
              // handleClose4();
              onDeleteProjectDocument()
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ProjectDetails;
